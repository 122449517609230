import { defineMessages } from 'react-intl';

const messages = defineMessages({
    promoCode: {
        id: 'InputPromoCode.promoCode',
        defaultMessage: 'Promo Code',
    },
    promoCodeRequired: {
        id: 'InputPromoCode.promoCodeRequired',
        defaultMessage: 'Promo Code required',
    },
    promoCodeInvalid: {
        id: 'InputPromoCode.promoCodeInvalid',
        defaultMessage: 'Promo Code invalid',
    },
    promoCodeInvalidLength: {
        id: 'InputPromoCode.promoCodeInvalidLength',
        defaultMessage: 'Promo Code must have 8 to 20 characters',
    },
    promoCodeDuplicate: {
        id: 'InputPromoCode.promoCodeDuplicate',
        defaultMessage: 'Promo Code duplicate',
    },
});

export default messages;
