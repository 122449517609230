import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
} from '@material-ui/core';
import {
    ChatBubble as ChatBubbleIcon,
    Lens as LensIcon,
} from '@material-ui/icons';

import MultilineText from 'components/MultilineText';

import messages from './messages';

import './styles.scss';

function CancelationReasons({ reasons, comment }) {
    let listItems = null;
    let commentItem = null;

    if ((!reasons || !reasons.length) && !comment) {
        return null;
    }

    if (reasons) {
        listItems = reasons.map(r => (
            <ListItem key={`reason-${r}`} style={{ paddingLeft: '30px' }}>
                <ListItemIcon className="list-bullet-icon">
                    <LensIcon />
                </ListItemIcon>
                <ListItemText
                    className="list-item-cancelation-reason"
                    primary={<FormattedMessage {...messages[r]} />}
                    primaryTypographyProps={{ variant: 'body1' }}
                />
            </ListItem>
        ));
    }

    if (comment) {
        commentItem = (
            <ListItem style={{ paddingLeft: '30px' }}>
                <ListItemIcon className="list-bullet-icon">
                    <LensIcon />
                </ListItemIcon>
                <ListItemText
                    className="list-item-cancelation-reason"
                    primary={<MultilineText text={comment} />}
                />
            </ListItem>
        );
    }

    return (
        <Grid container spacing={0} className="page">
            <Grid item xs={12} className="icon-label-wrapper">
                <ChatBubbleIcon className="text-hint" />
                <Typography variant="body1" className="text-hint">
                    <FormattedMessage {...messages.orderCanceledPretext} />
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <List dense>
                    {listItems}
                    {commentItem}
                </List>
            </Grid>
        </Grid>
    );
}

CancelationReasons.propTypes = {
    reasons: PropTypes.arrayOf(String),
    comment: PropTypes.string,
};

export default CancelationReasons;
