import { push } from 'connected-react-router';

import Storage from 'services/Storage';
import { API_URI } from 'config';
import { sanitizePhoneNumber } from 'utils';

import { showSnackbar } from 'containers/Snackbar/actions';

import {
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,
    CLEAR_REGISTRATION,
} from './constants';
import messages from './messages';

/*
 * ACTION CREATORS
 */

function registrationRequest(areaCode, phoneNumber, firstName, lastName) {
    return {
        type: REGISTRATION_REQUEST,
        areaCode,
        phoneNumber,
        firstName,
        lastName,
    };
}

function registrationSuccess(result) {
    return {
        type: REGISTRATION_SUCCESS,
        result,
    };
}

function registrationError(error) {
    return {
        type: REGISTRATION_ERROR,
        error,
    };
}

export function clearRegistration() {
    return {
        type: CLEAR_REGISTRATION,
    };
}

/*
 * DISPATCHERS
 */

// eslint-disable-next-line max-params
export function register(areaCode, phoneNumber, firstName, lastName, password) {
    return dispatch => {
        dispatch(registrationRequest(areaCode, phoneNumber, firstName, lastName));

        const phoneNumberWithAreaCode = `${areaCode}${sanitizePhoneNumber(phoneNumber)}`;
        const url = new URL('/api/public/customer/register', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            firstName,
            lastName,
            password,
            locale: Storage.getLocale(),
        };

        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(() => {
                dispatch(registrationSuccess());
                // navigate to activation page
                dispatch(push('/activation'));
            })
            .catch(error => {
                dispatch(registrationError(error));
                dispatch(showSnackbar(messages.registerError));
            });
    };
}
