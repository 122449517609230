import { defineMessages } from 'react-intl';

const messages = defineMessages({
    general: {
        id: 'About.general',
        defaultMessage: 'Info',
    },
    imprint: {
        id: 'About.imprint',
        defaultMessage: 'Imprint',
    },
    version: {
        id: 'About.version',
        defaultMessage: 'App version',
    },
    considerTermsOfService: {
        id: 'About.usageNotice',
        defaultMessage: 'To use hol\'s app please consider the {termsOfService}',
    },
    contactInfo: {
        id: 'About.contactInfo',
        defaultMessage: 'If you have any questions or suggestions please contact us via e-mail',
    },
});

export default messages;
