/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Button,
    Typography,
    CircularProgress,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import AuthFormFields from 'components/AuthFormFields';
import StorageDialog from 'components/StorageDialog';

import { setPhoneNumber as setPhoneNumberAction } from 'containers/PasswordReset/actions';

import Storage from 'services/Storage';

import {
    login as loginAction,
    onRedirect as onRedirectAction,
} from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

class Login extends React.Component {

    static getFrom(props) {
        const { from } = props.location.state || { from: { pathname: props.path } };
        return from;
    }

    constructor(props) {
        const secureStorageDialogOpen = (Storage.isMobile() && !Storage.hasSecureStorage())
            || (Storage.isBrowser() && !Storage.hasLocalStorage());

        super(props);

        this.state = {
            areaCode: '43',
            phoneNumber: '',
            password: '',
            secureStorageDialogOpen,
            secureStorageDialogBrowser: Storage.isBrowser(),
        };

        this.handleDialogCloseClick = this.handleDialogCloseClick.bind(this);
        this.doLogin = this.doLogin.bind(this);

        // save redirect path in redux store
        props.onRedirect(Login.getFrom(props).pathname);
        props.setPhoneNumber('', '');
    }

    onBlurPhoneNumber(phoneNumber) {
        this.setState({ phoneNumber }, () => {
            this.props.setPhoneNumber(this.state.areaCode, this.state.phoneNumber);
        });
    }

    onChangePhoneNumber(phoneNumber) {
        this.setState({ phoneNumber });
    }

    onChangePassword(password) {
        this.setState({ password });
    }

    doLogin(event) {
        const valid = this.form.validate();
        if (valid) {
            this.props.login(this.state.areaCode, this.state.phoneNumber, this.state.password);
        }
        event.preventDefault();
    }

    handleDialogCloseClick() {
        this.setState({ secureStorageDialogOpen: false });
    }

    render() {
        const {
            loggedIn,
            location,
        } = this.props;
        const {
            secureStorageDialogOpen,
            secureStorageDialogBrowser,
        } = this.state;

        if (loggedIn) {
            const { from } = location.state || { from: Login.getFrom(this.props) };

            return <Redirect to={from} />;
        }

        return (
            <div className="login-page logistration-page">
                <MenuBar titleMessage={globalMessages.login} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={6}>
                            <form
                                onSubmit={this.doLogin}
                                noValidate
                                autoComplete="off"
                                className="login-card logistration-card"
                            >
                                <div className="logistration-form">
                                    <AuthFormFields
                                        ref={form => { this.form = form; }}
                                        phoneNumber=""
                                        onBlurPhoneNumber={p => this.onBlurPhoneNumber(p)}
                                        onChangePhoneNumber={p => this.onChangePhoneNumber(p)}
                                        onChangePassword={p => this.onChangePassword(p)}
                                    />
                                    <Button
                                        component={Link}
                                        to="/password/reset"
                                        color="primary"
                                        className="default-link-style"
                                    >
                                        <FormattedMessage {...messages.resetPassword} />
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="submit"
                                        data-testid="login-button"
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.loading}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...globalMessages.login} />
                                    </Button>
                                </div>
                            </form>
                            <div className="login-register-now">
                                <Typography variant="subtitle1" className="text-hint">
                                    <FormattedMessage {...messages.registerNow} />
                                </Typography>
                                <Button component={Link} to="/registration" color="primary">
                                    <FormattedMessage {...messages.register} />
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <StorageDialog
                    open={secureStorageDialogOpen}
                    browser={secureStorageDialogBrowser}
                    onClose={this.handleDialogCloseClick}
                    onAccept={this.handleDialogCloseClick}
                />
            </div>
        );
    }

}

Login.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    path: PropTypes.string.isRequired,
    location: PropTypes.object,
    loading: PropTypes.bool,
    login: PropTypes.func.isRequired,
    onRedirect: PropTypes.func.isRequired,
    setPhoneNumber: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.loginReducer.loggedIn,
        path: state.loginReducer.path,
        loading: state.loginReducer.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        login: (areaCode, phoneNumber, password) => dispatch(loginAction(areaCode, phoneNumber, password)),
        onRedirect: path => dispatch(onRedirectAction(path)),
        setPhoneNumber: (areaCode, phoneNumber) => dispatch(setPhoneNumberAction(areaCode, phoneNumber)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
