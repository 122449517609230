import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import './styles.scss';

function MerchantQueue({ merchantQueue, id, dataTestid }) {
    let length = null;
    if (merchantQueue && merchantQueue[id]) {
        length = merchantQueue[id].count;
    }

    return (
        <div
            className={`merchant-queue ${length === null ? 'empty' : ''}`}
            data-testid={dataTestid}
        >
            <Typography variant="body2">{length !== null ? length : '0'}</Typography>
        </div>
    );
}

MerchantQueue.propTypes = {
    merchantQueue: PropTypes.object,
    id: PropTypes.string.isRequired,
    dataTestid: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        merchantQueue: state.global.merchantQueue,
    };
}

export default connect(mapStateToProps)(MerchantQueue);
