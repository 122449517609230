import React from 'react';
import PropTypes from 'prop-types';

function PaymentMethods(props) {
    return (
        <span>{props.values.join(', ')}</span>
    );
}

PaymentMethods.propTypes = {
    values: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PaymentMethods;
