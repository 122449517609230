import { push } from 'connected-react-router';

import { API_URI } from 'config';
import { sanitizePhoneNumber } from 'utils';
import Storage from 'services/Storage';
import Device from 'services/Device';

import { showSnackbar } from 'containers/Snackbar/actions';
import {
    loadOrderQueue,
    unsetCustomerData,
    fetchCustomerData,
    checkTermsOfService,
    loadMerchants,
} from 'containers/App/actions';

import { clearCart } from 'containers/ShoppingCart/actions';
import { getDeviceWithPushToken } from '../../device';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_REDIRECT,
    LOGOUT_REQUEST,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR,
} from './constants';
import messages from './messages';

/*
 * ACTION CREATORS
 */

function loginRequest() {
    return {
        type: LOGIN_REQUEST,
    };
}

export function loginSuccess(result) {
    return {
        type: LOGIN_SUCCESS,
        result,
    };
}

function loginError(error) {
    return {
        type: LOGIN_ERROR,
        error,
    };
}

function loginRedirect(path) {
    return {
        type: LOGIN_REDIRECT,
        path,
    };
}

function logoutRequest() {
    return {
        type: LOGOUT_REQUEST,
    };
}

export function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

function logoutError(error) {
    return {
        type: LOGOUT_ERROR,
        error,
    };
}

/*
 * DISPATCHERS
 */

/**
 * Set the login state.
 * If the JWT token is given, the token is set in the storage.
 * @param {string|null} token JWT token
 * @returns {function(*)}
 */
export function setLogin(token = null) {
    return (dispatch, state) => {
        if (token) {
            Storage.setToken(token)
                .catch(() => console.log('Set token failed: storage unavailable'));
        }
        const result = Storage.getLogin();
        if (result) {
            dispatch(loginSuccess(result));

            dispatch(checkTermsOfService());

            // load the actual order queue after login
            dispatch(loadOrderQueue());

            dispatch(fetchCustomerData());

            dispatch(loadMerchants(true, state.global.coordinates));
        }
    };
}

export function login(areaCode, phoneNumber, password) {
    return async dispatch => {
        dispatch(loginRequest());

        const device = await getDeviceWithPushToken();
        const phoneNumberWithAreaCode = `${areaCode}${sanitizePhoneNumber(phoneNumber)}`;
        const url = new URL('/api/login/customer', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            password,
            device,
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(setLogin(json.token));
                dispatch(showSnackbar(messages.loginSuccess));
            })
            .catch(error => {
                dispatch(loginError(error));
                dispatch(showSnackbar(messages.loginError));
            });
    };
}

export function logout() {
    return (dispatch, state) => {
        dispatch(logoutRequest);

        const url = new URL('/api/logout/customer', API_URI).toString();
        const payload = {};

        if (Device.isDevice()) {
            payload.device = {
                id: Device.getDeviceInfo().id,
            };
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Storage.getToken()}`,
            },
            body: JSON.stringify(payload),
        })
            .finally(() => Storage.unsetToken())
            .then(() => {
                dispatch(logoutSuccess());
                dispatch(showSnackbar(messages.logoutSuccess));
                dispatch(clearCart());
                dispatch(unsetCustomerData());
                dispatch(loadMerchants(false, state.global.coordinates));
                dispatch(push('/login'));
            })
            .catch(error => {
                dispatch(logoutError(error));
            });
    };
}

/**
 * This action saves the path from the page where the login has been triggered.
 * @param path
 */
export function onRedirect(path) {
    return dispatch => {
        dispatch(loginRedirect(path));
    };
}
