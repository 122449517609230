/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function PreOrderIcon({
    size = '24px',
    className,
    style,
    ...rest
}) {
    return (
        <svg
            className={`app-icon ${className}`}
            viewBox="0 0 24 24"
            style={{
                fontSize: size,
                ...style,
            }}
            {...rest}
        >
            <g>
                <path d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M16.24,7.76C15.07,6.58 13.53,6 12,6V12L7.76,16.24C10.1,18.58 13.9,18.58 16.24,16.24C18.59,13.9 18.59,10.1 16.24,7.76Z" />
            </g>
        </svg>
    );
}

PreOrderIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default PreOrderIcon;
