/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import InputFirstName from '../InputFirstName';
import InputLastName from '../InputLastName';
import InputPhoneNumber from '../InputPhoneNumber';

class ProfileForm extends React.PureComponent {

    validate() {
        const firstNameValid = this.inputFirstName.validate();
        const lastNameValid = this.inputLastName.validate();
        return firstNameValid && lastNameValid;
    }

    render() {
        return (
            <form noValidate autoComplete="off">
                <InputFirstName
                    ref={input => { this.inputFirstName = input; }}
                    firstName={this.props.firstName}
                    onChange={val => this.props.onChangeFirstName(val)}
                />
                <InputLastName
                    ref={input => { this.inputLastName = input; }}
                    lastName={this.props.lastName}
                    onChange={val => this.props.onChangeLastName(val)}
                />
                <InputPhoneNumber
                    disabled
                    ref={input => { this.inputPhoneNumber = input; }}
                    phoneNumber={this.props.phoneNumber}
                />
            </form>
        );
    }

}

ProfileForm.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    // triggered on change
    onChangeFirstName: PropTypes.func.isRequired,
    onChangeLastName: PropTypes.func.isRequired,
};

export default ProfileForm;
