import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from '@rise/react-swipeable-views';
import {
    AppBar,
    Grid,
    Tab,
    Tabs,
    Button,
    List,
    ListItem,
    Typography,
} from '@material-ui/core';
import {
    Add as AddIcon,
    PlaylistAdd as AddWithOptionsIcon,
} from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import FormattedAmount from 'components/FormattedAmount';
import MultilineText from 'components/MultilineText';

import messages from './messages';

import './styles.scss';

class CategoryTabContainer extends React.PureComponent {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
    }

    handleChange(event, value) {
        this.props.onChangeTab(value);
    }

    handleChangeIndex(index) {
        this.props.onChangeTab(index);
    }

    render() {
        const { open } = this.props;
        // mapping of categoryId to <Tab> element
        const categoryTabs = {};
        // mapping of categoryId to Array<ListItem> elements
        const productsPerCategory = {};

        this.props.products.forEach(p => {
            const categoryId = p.category;
            const nameStyles = p.available ? { color: '#000' } : { color: 'rgba(0, 0, 0, 0.54)' };

            if (!categoryTabs[categoryId]) {
                const category = this.props.categories.find(c => c._id === categoryId);
                if (!category) {
                    // category for product not found ?!
                    return;
                }

                categoryTabs[categoryId] = (
                    <Tab
                        key={category._id}
                        label={category.name}
                        data-testid={`category-${category.name.replace(' ', '-')}`}
                        classes={{
                            root: 'vw-scroll-tab',
                        }}
                    />
                );
                productsPerCategory[categoryId] = [];
            }

            productsPerCategory[categoryId].push((
                <ListItem key={p._id} className="product-list-item">
                    {/* <ListItemText primary={p.name} secondary={p.description} /> */}
                    <Grid container spacing={0} justify="space-between">
                        <Grid item xs={12}>
                            <Typography variant="body2" style={nameStyles} className="product-title">
                                {p.name}
                                {!p.available &&
                                    <span className="product-list-tag-unavailable">
                                        &nbsp;
                                        <FormattedMessage {...messages.unavailable} />
                                    </span>
                                }
                            </Typography>
                            <Typography variant="caption" display="block" className="text-faded product-description">
                                {p.description}
                            </Typography>
                            <Typography variant="caption" display="block" className="text-faded">
                                {p.allergens ? p.allergens.sort().join(', ') : ''}
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className="product-list-button-wrapper">
                        {open && p.available ?
                            <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                className="product-list-button"
                                onClick={() => this.props.onClick(p)}
                                data-testid={`button-price-${p.name.replace(' ', '-')}`}
                            >
                                {this.props.hasOptions(p)
                                    ? <AddWithOptionsIcon />
                                    : <AddIcon />
                                }&nbsp;
                                <FormattedAmount
                                    value={p.price}
                                    locale={this.props.locale}
                                />&nbsp;€
                            </Button>
                            :
                            <Typography variant="body1">
                                <FormattedAmount
                                    value={p.price}
                                    locale={this.props.locale}
                                />&nbsp;€
                            </Typography>
                        }
                    </div>
                </ListItem>
            ));
        });

        const categoryDescription = c => {
            if (c.description && c.description.length > 0) {
                return (
                    <ListItem className="product-list-category-description">
                        <Grid container spacing={0} justify="space-between">
                            <Grid item xs={12}>
                                <Typography variant="body2" align="left">
                                    <MultilineText text={c.description} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItem>
                );
            } else {
                return null;
            }

        };

        // we need at least one category and one product for the tab container
        if (this.props.categories.length > 0 && this.props.products.length > 0) {
            return (
                <div>
                    <AppBar position="sticky" className="sticky-app-bar">
                        <Tabs
                            value={this.props.selectedTab}
                            onChange={this.handleChange}
                            scrollButtons="auto"
                            variant="scrollable"
                            className="full-width-scrollable-tabs"
                        >
                            {this.props.categories.map(c => categoryTabs[c._id])}
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={this.props.selectedTab}
                        onChangeIndex={this.handleChangeIndex}
                        adjustHeight
                    >
                        {this.props.categories.reduce((result, category) => {
                            const products = productsPerCategory[category._id];
                            // check if the category has any products
                            if (products) {
                                result.push((
                                    <div key={category._id}>
                                        <List>
                                            {categoryDescription(category)}
                                            {products}
                                        </List>
                                    </div>
                                ));
                            }
                            return result;
                        }, [])}
                    </SwipeableViews>
                </div>
            );
        } else {
            return (
                <Typography variant="subtitle1" align="center" style={{ marginTop: 24 }}>
                    <FormattedMessage {...messages.noMenuCard} />
                </Typography>
            );
        }
    }

}

CategoryTabContainer.propTypes = {
    categories: PropTypes.array.isRequired,
    products: PropTypes.array.isRequired,
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    onChangeTab: PropTypes.func.isRequired,
    selectedTab: PropTypes.number.isRequired,
    locale: PropTypes.string.isRequired,
    hasOptions: PropTypes.func,
};

export default CategoryTabContainer;
