/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Fab,
    Typography,
} from '@material-ui/core';
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons';
import {
    fetchCustomerData as fetchCustomerDataAction,
    updateCustomerData as updateCustomerDataAction,
} from 'containers/App/actions';
import MenuBar from 'containers/MenuBar';
import InputPromoCode from 'components/InputPromoCode';
import FormDialog from 'components/FormDialog';
import ConfirmDialog from 'components/ConfirmDialog';

import messages from './messages';
import './styles.scss';

class PromoCodes extends React.Component {

    constructor(props) {
        super(props);

        const paramPromoCode = PromoCodes.getPromoCodeParam(props);
        this.state = {
            // list of promo codes of the user profile
            promoCodes: props.promoCodes || [],
            // promo code string of the modal input that will be added
            addPromoCode: paramPromoCode,
            // promo code string that will be deleted
            deletePromoCode: '',
            // promo code add dialog open state
            openAddDialog: !!paramPromoCode,
            // promo code confirm dialog open state
            openDeleteDialog: false,
            // the promo code URL parameter
            paramPromoCode,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // list of promo codes has changed
        if (nextProps.promoCodes && prevState.promoCodes !== nextProps.promoCodes) {
            return {
                ...prevState,
                promoCodes: nextProps.promoCodes,
            };
        }

        // URL parameter has changed
        const paramPromoCode = PromoCodes.getPromoCodeParam(nextProps);
        if (paramPromoCode && prevState.paramPromoCode !== paramPromoCode) {
            return {
                ...prevState,
                addPromoCode: paramPromoCode,
                openAddDialog: true,
                paramPromoCode,
            };
        }

        return null;
    }

    static getPromoCodeParam(props) {
        const params = new URLSearchParams(props.location.search);
        return params.get('code');
    }

    componentDidMount() {
        if (!this.props.customer) {
            this.loadData();
        }

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    onChangePromoCode(addPromoCode) {
        this.setState({ addPromoCode });
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    loadData() {
        this.props.fetchCustomerData();
    }

    handleBack() {
        this.props.dispatch(push('/profile'));
    }

    handleSubmit() {
        const { promoCodes, addPromoCode } = this.state;

        if (this.inputPromoCode.validate()) {
            this.updatePromoCodes([...promoCodes, addPromoCode]);
        }
    }

    handleClose() {
        this.props.dispatch(push('/promo-codes'));
        this.setState({
            addPromoCode: '',
            openAddDialog: false,
            paramPromoCode: '',
        });
    }

    handleDelete() {
        const { promoCodes, deletePromoCode } = this.state;

        const filteredPromoCodes = promoCodes.filter(p => p !== deletePromoCode);
        this.updatePromoCodes(filteredPromoCodes);
    }

    updatePromoCodes(promoCodes) {
        const { _version } = this.props.customer;
        this.props.updateCustomerData({
            promoCodes: promoCodes && promoCodes.length ? promoCodes : null,
            _version,
        }).then(response => {
            if (!response.error) {
                this.props.dispatch(push('/promo-codes'));
                this.setState({
                    promoCodes,
                    addPromoCode: '',
                    openAddDialog: false,
                    openDeleteDialog: false,
                    paramPromoCode: '',
                });
            }
        });
    }

    render() {
        const { cordova } = window;
        let floatingActionButton = <div />;
        let dialogFormContent = <div />;
        let promoCodesList = (
            <div className="empty-promo-codes">
                <Typography variant="h5" paragraph>
                    <FormattedMessage {...messages.emptyHeader} />
                </Typography>
                <Typography variant="body1" paragraph>
                    <FormattedMessage {...messages.emptyBody} />
                </Typography>
            </div>
        );
        if (this.props.customer) {
            dialogFormContent = (
                <InputPromoCode
                    ref={input => { this.inputPromoCode = input; }}
                    promoCode={this.state.addPromoCode}
                    promoCodes={this.state.promoCodes}
                    onChange={val => this.onChangePromoCode(val)}
                    // autofocus not working on iOS
                    // WKWebView will only show the keyboard if the focus is initiated by a user.
                    // https://bugs.webkit.org/show_bug.cgi?id=142757
                    autoFocus={cordova && cordova.platformId !== 'ios'}
                />
            );
            if (this.state.promoCodes.length < 10) {
                floatingActionButton = (
                    <Fab
                        disabled={this.state.promoCodes.length >= 10}
                        color="primary"
                        aria-label="add"
                        className="fab-add-promo-codes"
                        onClick={() => this.setState({ openAddDialog: true })}
                    >
                        <AddIcon />
                    </Fab>
                );
            }
            if (this.state.promoCodes.length > 0) {
                promoCodesList = (
                    <List>
                        {this.state.promoCodes.map(promoCode => (
                            <ListItem key={promoCode}>
                                <ListItemText primary={promoCode} />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="delete"
                                        onClick={() => this.setState({
                                            deletePromoCode: promoCode,
                                            openDeleteDialog: true,
                                        })}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                );
            }
        }

        return (
            <div className="promo-codes-page">
                <MenuBar
                    titleMessage={messages.title}
                    onBackClick={() => this.handleBack()}
                    onRefreshClick={e => this.handleRefresh(e)}
                />
                <FormDialog
                    open={this.state.openAddDialog}
                    titleMessage={messages.addDialogTitle}
                    confirmMessage={messages.add}
                    contentForm={dialogFormContent}
                    onClose={() => this.handleClose()}
                    onConfirmClick={() => this.handleSubmit()}
                    onCancelClick={() => this.handleClose()}
                />
                <ConfirmDialog
                    open={this.state.openDeleteDialog}
                    titleMessage={messages.deleteDialogTitle}
                    contentMessage={messages.deleteDialogMessage}
                    confirmMessage={messages.delete}
                    onClose={() => this.setState({ openDeleteDialog: false })}
                    onConfirmClick={() => this.handleDelete()}
                    onCancelClick={() => this.setState({ openDeleteDialog: false })}
                />
                <div className="page-content">
                    {floatingActionButton}
                    {promoCodesList}
                </div>
            </div>
        );
    }

}

PromoCodes.propTypes = {
    dispatch: PropTypes.func.isRequired,
    promoCodes: PropTypes.array,
    customer: PropTypes.object,
    location: PropTypes.object,
    fetchCustomerData: PropTypes.func,
    updateCustomerData: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        promoCodes: state.global.customer && state.global.customer.promoCodes,
        customer: state.global.customer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fetchCustomerData: () => dispatch(fetchCustomerDataAction()),
        updateCustomerData: customer => dispatch(updateCustomerDataAction(customer)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PromoCodes);
