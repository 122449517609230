import {
    SET_PRODUCT,
    CLEAR_CART,
    SET_COMMENT,
    SUBMIT_ORDER_REQUEST,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_ERROR,
    SET_PRODUCT_VALIDITY,

    PICK_UP_TYPE,
    SET_PICK_UP,

    PAYMENT_TYPE,
    SET_PAYMENT_TYPE,
} from './constants';

const initialState = {
    processing: false,
    hover: false,
    merchantId: '',
    products: {},
    comment: '',
    submitError: null,
    tooManyProducts: false,
    totalCount: 0,
    pickUpType: PICK_UP_TYPE.now,
    pickUpCustomerAt: null,
    paymentType: PAYMENT_TYPE.onSite,
    isSubmitting: false,
};

function countProducts(products) {
    return Object.keys(products).reduce((total, p) => total + products[p].quantity, 0);
}

function shoppingCartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT: {
            let id = `${action.product._id}`;
            if (action.product.options) {
                id = `${id}/${action.product.options.map(option => option._id).join('/')}`;
            }
            const totalCount = state.totalCount
                + action.quantity
                - (action.replaceId ? state.products[action.replaceId].quantity : 0);
            const currentQuantity = state.products[id]
                ? state.products[id].quantity
                : 0;
            const newQuantity = action.replaceId && action.replaceId === id
                ? action.quantity
                : currentQuantity + action.quantity;
            let newProducts = {};
            const newProduct = {
                product: action.product,
                quantity: newQuantity,
                isValid: true,
            };
            if (action.replaceId) {
                Object.keys(state.products).forEach(i => {
                    if (i === action.replaceId) {
                        newProducts[id] = newProduct;
                    } else if (i !== id) {
                        newProducts[i] = state.products[i];
                    }
                });
            } else {
                // Object rest/spread changes position of products in object (Safari, see #314)
                newProducts = { ...state.products };
                newProducts[id] = newProduct;
            }

            if (newQuantity <= 0) {
                delete newProducts[id];
            }

            if (Object.keys(newProducts).length <= 0) {
                // all products have bee removed from the shopping cart
                return {
                    ...state,
                    hover: false,
                    merchantId: '',
                    products: {},
                    comment: '',
                    totalCount,
                };
            } else {
                let tooMany = false;
                if (action.max > 0 && countProducts(newProducts) > action.max) {
                    tooMany = true;
                }
                return {
                    ...state,
                    hover: true,
                    merchantId: action.merchantId,
                    products: newProducts,
                    tooManyProducts: tooMany,
                    totalCount,
                };
            }
        }
        case SET_PRODUCT_VALIDITY: {
            const newProducts = { ...state.products };
            newProducts[action.productId].isValid = action.isValid;
            return {
                ...state,
                products: newProducts,
            };
        }
        case SUBMIT_ORDER_SUCCESS:
        case CLEAR_CART:
            return {
                ...state,
                hover: false,
                products: {},
                comment: '',
                merchantId: '',
                tooManyProducts: false,
                totalCount: 0,
                pickUpType: PICK_UP_TYPE.now,
                pickUpCustomerAt: null,
                isSubmitting: false,
                processing: false,
                paymentType: PAYMENT_TYPE.onSite,
            };
        case SET_COMMENT:
            return {
                ...state,
                comment: action.text,
            };
        case SET_PICK_UP:
            return {
                ...state,
                pickUpType: action.pickUpType,
                pickUpCustomerAt: action.pickUpCustomerAt,
            };
        case SET_PAYMENT_TYPE:
            return {
                ...state,
                paymentType: action.paymentType,
            };
        case SUBMIT_ORDER_REQUEST:
            return {
                ...state,
                isSubmitting: true,
                processing: true,
            };
        case SUBMIT_ORDER_ERROR:
            return {
                ...state,
                submitError: action.error,
                isSubmitting: false,
                processing: false,
            };
        default:
            return state;
    }
}

export default shoppingCartReducer;
