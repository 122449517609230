import { defineMessages } from 'react-intl';

const messages = defineMessages({
    termsOfServiceVersionIncompatibleHeader: {
        id: 'TermsOfServiceDialog.termsOfServiceVersionIncompatibleHeader',
        defaultMessage: 'General terms of service and privacy policy changed',
    },
    termsOfServiceVersionIncompatibleBody: {
        id: 'TermsOfServiceDialog.termsOfServiceVersionIncompatibleBody',
        // eslint-disable-next-line max-len
        defaultMessage: 'The general terms of service and privacy policy have changed. To use hol\'s app please agree to the updated version.',
    },
    termsOfServiceAccept: {
        id: 'TermsOfServiceDialog.termsOfServiceAccept',
        defaultMessage: 'Accept',
    },
    termsOfServiceDecline: {
        id: 'TermsOfServiceDialog.termsOfServiceDecline',
        defaultMessage: 'Decline',
    },
});

export default messages;
