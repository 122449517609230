import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuBar from 'containers/MenuBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from '@rise/react-swipeable-views';

import globalMessages from 'containers/App/messages';
import TermsOfServiceRef from 'components/TermsOfServiceRef';

import Device from '../../services/Device';
import Push from '../../services/Push';

import messages from './messages';
import './styles.scss';
import { API_URI } from '../../config';
import { getDeviceWithPushToken } from '../../device';

/*
 * webpack.DefinePlugin will inject the variable '__VERSION__' and '__BUILD_TARGET__'.
 * To change it, the app needs to be recompiled.
 */
const injectedVersion = __VERSION__; // eslint-disable-line no-undef
const buildTarget = __BUILD_TARGET__; // eslint-disable-line no-undef

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            pushToken: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeIndex = this.handleChangeIndex.bind(this);
    }

    componentDidMount() {
        if (Device.isDevice()) {
            // show the Push token only if we have already the permission of the operating system
            Push.hasPermission()
                .then(data => {
                    if (data.isEnabled) {
                        return getDeviceWithPushToken();
                    } else {
                        return Promise.reject();
                    }
                })
                .then(device => this.setState({ pushToken: device ? device.token : '' }))
                .catch(() => console.error('Error fetching Push token -- Push token not available'));
        }
    }

    handleChange(event, selectedTab) {
        this.setState({ selectedTab });
    }

    handleChangeIndex(index) {
        this.setState({ selectedTab: index });
    }

    render() {
        const { selectedTab, pushToken } = this.state;

        return (
            <div className="about-page">
                <MenuBar
                    titleMessage={globalMessages.about}
                />
                <div className="page-content">
                    <AppBar position="sticky" className="sticky-app-bar">
                        <Tabs
                            value={selectedTab}
                            onChange={this.handleChange}
                            variant="fullWidth"
                        >
                            <Tab label={<FormattedMessage {...messages.general} />} />
                            <Tab label={<FormattedMessage {...messages.imprint} />} />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={selectedTab}
                        onChangeIndex={this.handleChangeIndex}
                        adjustHeight
                    >
                        <VersionContainer pushToken={pushToken} />
                        <ImpressumContainer />
                    </SwipeableViews>
                </div>
            </div>
        );
    }

}

function VersionContainer({ pushToken }) {
    const { id: deviceId } = Device.getDeviceInfo();

    return (
        <div className="about-tab-container">
            <Typography variant="body2" paragraph>
                <FormattedMessage
                    {...messages.considerTermsOfService}
                    values={{
                        termsOfService: <TermsOfServiceRef />,
                    }}
                />.
            </Typography>

            <Typography variant="body2" paragraph>
                <FormattedMessage {...messages.contactInfo} />:&nbsp;
                <a
                    href="mailto:contact@hols-app.at"
                    className="external-link"
                >
                    contact@hols-app.at
                </a>
            </Typography>

            <Typography variant="body2" paragraph>
                <strong><FormattedMessage {...messages.version} />:</strong> {injectedVersion}
            </Typography>

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>API URI:</strong> {API_URI}
            </Typography>}

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>Device Identifier:</strong> {deviceId || '-'}
            </Typography>}

            { buildTarget === 'debug' &&
            <Typography variant="body2" paragraph>
                <strong>Push Token:</strong> {pushToken ? `${pushToken.substring(0, 15)}…` : '-'}
            </Typography>}

            <Typography variant="body2" paragraph>
                Powered by&nbsp;
                <a
                    href="https://www.rise-world.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="external-link"
                >
                    RISE
                </a>
            </Typography>
        </div>
    );
}

VersionContainer.propTypes = {
    pushToken: PropTypes.string,
};

function ImpressumContainer() {
    return (
        <div className="about-tab-container">
            <Typography variant="body2" paragraph>
                <strong>Medieninhaber:</strong> LIMA ZULU GmbH
            </Typography>
            <Typography variant="body2" paragraph>
                Argentinierstraße 21<br />
                1040 Wien<br />
                Austria (Europe)
            </Typography>
            <Typography variant="body2" paragraph>
                <strong>Tel:</strong> +43 1 9049007-0<br />
                <strong>Fax:</strong> +43 1 5057473<br />
                <strong>Email:</strong>&nbsp;
                <a
                    href="mailto:contact@lima.or.at"
                    className="external-link"
                >
                    contact@lima.or.at
                </a>
            </Typography>
            <Typography variant="body2" paragraph>
                <strong>Firmenbuch:</strong> FN 441222g (HG Wien)<br />
                <strong>UID:</strong> AT U71443208
            </Typography>
            <Typography variant="body2" paragraph>
                <strong>Unternehmensgegenstand:</strong><br />
                Erforschung, Entwurf, Entwicklung, Erprobung, Betrieb, Verkauf und Handel mit mobilen Applikationen
            </Typography>
            <Typography variant="body2" paragraph>
                <strong>Gewerbe- und Aufsichtsbehörde:</strong><br />
                Magistrat Wien, Magistratisches Bezirksamt für den 4 und 5. Bezirk
            </Typography>
            <hr />
            <Typography variant="body2" paragraph>
                Mitglied der Wirtschaftskammer Wien Fachgruppe UBIT
            </Typography>
            <Typography variant="body2" paragraph>
                <strong>Anwendbare Rechtsvorschrift:</strong><br />
                Gewerbeordnung 1994 (GewO) abrufbar unter&nbsp;
                <a
                    href="https://www.ris.bka.gv.at/Bundesrecht/"
                    target="_new"
                >
                    https://www.ris.bka.gv.at/Bundesrecht/
                </a>
            </Typography>
            <hr />

            <Typography variant="h6" gutterBottom>
                Urheberrecht
            </Typography>
            <Typography variant="body2" paragraph>
                Der Inhalt, einschließlich Bilder, und die Gestaltung der Webseiten von LIMA ZULU unterliegen dem
                Schutz des Urheberrechts und anderer Gesetze zum Schutz geistigen Eigentums, unabhängig von einer
                gesonderten Kennzeichnung.
            </Typography>
            <Typography variant="body2" paragraph>
                Die Speicherung und Vervielfältigung der Dokumente ist nur für den persönlichen und informativen
                Gebrauch gestattet. Jede sonstige Vervielfältigung, Verbreitung, Wieder- und Weitergabe und sonstige
                Nutzung, insbesondere die Verwendung von Texten, Textteilen, Bildmaterial oder Software, die direkt
                oder indirekt von der Webseite von LIMA ZULU abgerufen oder verwendet werden kann, ist ohne
                vorangehende schriftliche Zustimmung von LIMA ZULU nicht gestattet. Die Veränderung des Inhalts dieser
                Seiten einschließlich des sogenannten Framings und ähnlicher Maßnahmen ist nicht gestattet. Durch diese
                Webseite wird keine Lizenz zur Nutzung des geistigen Eigentums von LIMA ZULU oder von verbundenen
                Unternehmen gewährt. Soweit nicht anders angegeben, sind sämtliche auf den Webseiten von LIMA ZULU
                verwendeten Marken markenrechtlich geschützt. Dies gilt insbesondere für Unternehmenslogos und
                Kennzeichen.
            </Typography>

            <Typography variant="body2" paragraph>
                Diese Webseiten enthalten auch Links oder Verweise auf Websites Dritter. Dies Verwendung dieser Links
                auf den Webseiten von LIMA ZULU zu Webseiten Dritter stellt keine Zustimmung zu den dort verfügbaren
                Inhalten durch LIMA ZULU dar. LIMA ZULU übernimmt keine Verantwortung für die Verfügbarkeit oder den
                Inhalt solcher Webseiten und keine Haftung für Schäden oder Verletzungen, die aus der Nutzung - gleich
                welcher Art - solcher Inhalte entstehen. Links zu anderen Webseiten werden Nutzern der Webseiten von
                LIMA ZULU lediglich als Annehmlichkeit zur Verfügung gestellt. Das Herstellen einer Verbindung zu
                diesen Websites geschieht auf eigene Gefahr und Verantwortung des Nutzers.
            </Typography>

            <Typography variant="body2" paragraph>
                Copyright © {new Date().getFullYear()} LIMA ZULU GmbH
            </Typography>
        </div>
    );
}

export default About;
