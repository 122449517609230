import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'Activation.title',
        defaultMessage: 'Registration',
    },
    subtitle: {
        id: 'Activation.subtitle',
        defaultMessage: 'Confirmation code sent to:',
    },
    changePhoneNumber: {
        id: 'Activation.changePhoneNumber',
        defaultMessage: 'Change phone number',
    },
    activationSucess: {
        id: 'Activation.activationSuccess',
        defaultMessage: 'Your account is now activated',
    },
    activationError: {
        id: 'Activation.activationError',
        defaultMessage: 'Error during account activation',
    },
});

export default messages;
