export const SET_PRODUCT = 'ShoppingCart/ADD_PRODUCT';
export const SET_PRODUCT_VALIDITY = 'ShoppingCart/SET_PRODUCT_VALIDITY';
export const CLEAR_CART = 'ShoppingCart/CLEAR_CART';
export const SET_COMMENT = 'ShoppingCart/SET_COMMENT';
export const SET_PICK_UP = 'ShoppingCart/SET_PICK_UP';
export const SET_PAYMENT_TYPE = 'ShoppingCart/SET_PAYMENT_TYPE';

export const OPEN_HOVERCART = 'ShoppingCart/OPEN_HOVERCART';
export const CLOSE_HOVERCART = 'ShoppingCart/CLOSE_HOVERCART';

export const SUBMIT_ORDER_REQUEST = 'ShoppingCart/SUBMIT_ORDER_REQUEST';
export const SUBMIT_ORDER_SUCCESS = 'ShoppingCart/SUBMIT_ORDER_SUCCESS';
export const SUBMIT_ORDER_ERROR = 'ShoppingCart/SUBMIT_ORDER_ERROR';

export const PICK_UP_TYPE = Object.freeze({
    now: 'NOW',
    preOrder: 'PRE_ORDER',
});

export const PAYMENT_TYPE = Object.freeze({
    onSite: 'ON_SITE',
    stripe: 'STRIPE',
});
