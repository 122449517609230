/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import MenuBar from 'containers/MenuBar';
import ProfileForm from 'components/ProfileForm';
import {
    fetchCustomerData as fetchCustomerDataAction,
    updateCustomerData as updateCustomerDataAction,
} from 'containers/App/actions';
import messages from './messages';
import './styles.scss';

class Profile extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            firstName: props.firstName,
            lastName: props.lastName,
            pristine: true,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.pristine && nextProps.customer) {
            return {
                ...prevState,
                firstName: nextProps.firstName,
                lastName: nextProps.lastName,
            };
        }
        return null;
    }

    componentDidMount() {
        if (!this.props.customer) {
            this.loadData();
        }

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    onChangeFirstName(firstName) {
        this.setState({ firstName, pristine: false });
    }

    onChangeLastName(lastName) {
        this.setState({ lastName, pristine: false });
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    loadData() {
        this.props.fetchCustomerData().then(() => {
            this.setState({
                pristine: true,
            });
            this.form.validate();
        });
    }

    handleSubmit() {
        const {
            firstName,
            lastName,
        } = this.state;
        const { _version } = this.props.customer;
        const validForm = this.form.validate();
        if (validForm) {
            this.props.updateCustomerData({
                firstName,
                lastName,
                _version,
            }).then(response => {
                if (!response.error) {
                    this.setState({
                        pristine: true,
                    });
                }
            });
        }
    }

    formatPhoneNumber(value) {
        try {
            return `${value.substr(2, 3)} ${value.substr(5)}`;
        } catch (e) {
            return value;
        }
    }

    renderProfileForm() {
        return (
            <div className="page-content">
                <Grid container justify="center" spacing={0} className="page">
                    <Grid item xs={12} className="align-right">
                        <Button
                            component={Link}
                            to={{ pathname: '/promo-codes', state: { from: this.props.location } }}
                            color="primary"
                            className="link-plain"
                        >
                            <FormattedMessage {...messages.promoCodes} />
                        </Button>
                    </Grid>
                    <Grid item sm={8}>
                        <div className="profile-card">
                            <div className="profile-form">
                                <ProfileForm
                                    ref={form => { this.form = form; }}
                                    firstName={this.state.firstName}
                                    lastName={this.state.lastName}
                                    phoneNumber={this.formatPhoneNumber(this.props.phoneNumber)}
                                    onChangeFirstName={p => this.onChangeFirstName(p)}
                                    onChangeLastName={p => this.onChangeLastName(p)}
                                />
                            </div>
                            <div>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.pristine}
                                    onClick={() => this.handleSubmit()}
                                >
                                    <FormattedMessage {...messages.save} />
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }

    render() {
        let content = <div />;
        if (this.props.customer) {
            content = this.renderProfileForm();
        }

        return (
            <div className="profile-page">
                <MenuBar
                    titleMessage={messages.title}
                    onRefreshClick={e => this.handleRefresh(e)}
                />
                {content}
            </div>
        );
    }

}

Profile.propTypes = {
    dispatch: PropTypes.func.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    customer: PropTypes.object,
    fetchCustomerData: PropTypes.func,
    updateCustomerData: PropTypes.func,
    location: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        firstName: state.global.customer && state.global.customer.firstName,
        lastName: state.global.customer && state.global.customer.lastName,
        phoneNumber: state.global.customer && state.global.customer.phoneNumber,
        customer: state.global.customer,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        fetchCustomerData: () => dispatch(fetchCustomerDataAction()),
        updateCustomerData: customer => dispatch(updateCustomerDataAction(customer)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
