
class Device {

    constructor() {
        this.model = undefined;
        this.platform = undefined;
        this.uuid = undefined;
        this.version = undefined;
        this.manufacturer = undefined;
        this.device = false;
    }

    /**
     * Initializes the Push Notification service. Can be called multiple times, but
     * returns always the same pending or resolved promise.
     */
    init() {
        document.addEventListener('deviceready', () => {
            try {
                const device = Device.getPlugin();

                this.device = true;
                this.model = device.model;
                this.platform = device.platform;
                this.uuid = device.uuid;
                this.version = device.version;
                this.manufacturer = device.manufacturer;

                console.log('Device: service initialized');

            } catch (err) {
                console.log(`Device: Error initializing service: ${err}`);
            }
        });
    }

    /**
     * Get the Cordova Device plugin instance.
     * @returns {null|*}
     */
    static getPlugin() {
        if (window.cordova
            && (window.cordova.platformId === 'ios' || window.cordova.platformId === 'android')
            && window.device) {
            return window.device;
        } else {
            throw Error(`Device: Plugin for platform ${window.cordova.platformId} not available`);
        }
    }

    /**
     * Tests if Device plugin is available.
     * @returns {boolean} True if available, otherwise false.
     */
    static hasDevice() {
        try {
            Device.getPlugin();
            return true;
        } catch (err) {
            return false;
        }
    }

    /**
     * Checks if the device is a mobile device.
     * @returns {boolean} True if a mobile device.
     */
    isDevice() {
        return this.device;
    }

    getDeviceInfo() {
        return {
            model: this.model,
            platform: this.platform ? this.platform.toUpperCase() : undefined,
            id: this.uuid,
            version: this.version,
            manufacturer: this.manufacturer,
        };
    }

}

export default new Device();
