import { push } from 'connected-react-router';
import Storage from 'services/Storage';
import Push from 'services/Push';
import Device from 'services/Device';
import Network from 'services/Network';
import Geolocation from 'services/Geolocation';
import Deeplink from 'services/Deeplink';
import Statusbar from 'services/Statusbar';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import { setLogin, logoutSuccess } from 'containers/Login/actions';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { normalize } from '../../utils';
import {
    CORDOVA_INIT_DONE,
    REQUEST_LOCATION_INFO,
    UPDATE_LOCATION_INFO,
    UNSET_LOCATION_INFO,
    UPDATE_CONNECTION_INFO,
    PUSH_NOTIFICATION_RECEIVE,
    PUSH_NOTIFICATION_CLEAR,
    LOAD_MERCHANTS_REQUEST,
    LOAD_MERCHANTS_SUCCESS,
    LOAD_MERCHANTS_ERROR,
    UPDATE_MERCHANT,
    LOAD_MERCHANT_QUEUE_REQUEST,
    LOAD_MERCHANT_QUEUE_SUCCESS,
    LOAD_MERCHANT_QUEUE_ERROR,
    LOAD_ORDER_QUEUE_REQUEST,
    LOAD_ORDER_QUEUE_SUCCESS,
    LOAD_ORDER_QUEUE_ERROR,
    VERIFY_TOKEN_REQUEST,
    VERIFY_TOKEN_SUCCESS,
    VERIFY_TOKEN_ERROR,
    LOAD_API_VERSION_REQUEST,
    LOAD_API_VERSION_SUCCESS,
    LOAD_API_VERSION_ERROR,
    UNSET_CUSTOMER_DATA,
    FETCH_CUSTOMER_DATA_REQUEST,
    FETCH_CUSTOMER_DATA_SUCCESS,
    FETCH_CUSTOMER_DATA_ERROR,
    UPDATE_CUSTOMER_DATA_REQUEST,
    UPDATE_CUSTOMER_DATA_SUCCESS,
    UPDATE_CUSTOMER_DATA_ERROR,
    SET_TERMS_OF_SERVICE_COMPATIBLE,
    CHECK_TERMS_OF_SERVICE_REQUEST,
    CHECK_TERMS_OF_SERVICE_SUCCESS,
    CHECK_TERMS_OF_SERVICE_ERROR,
    AGREE_TERMS_OF_SERVICE_REQUEST,
    AGREE_TERMS_OF_SERVICE_SUCCESS,
    AGREE_TERMS_OF_SERVICE_ERROR,
    UPDATE_DEVICE_INFORMATION_REQUEST,
    UPDATE_DEVICE_INFORMATION_SUCCESS,
    UPDATE_DEVICE_INFORMATION_ERROR,
} from './constants';
import messages from './messages';
import { API_URI } from '../../config';
import { getDeviceWithPushToken } from '../../device';

export function cordovaInitDone() {
    return {
        type: CORDOVA_INIT_DONE,
    };
}

export function requestLocationInfo() {
    return {
        type: REQUEST_LOCATION_INFO,
    };
}

export function updateLocationInfo(coordinates) {
    return {
        type: UPDATE_LOCATION_INFO,
        coordinates,
    };
}

export function unsetLocationInfo() {
    return {
        type: UNSET_LOCATION_INFO,
    };
}

export function updateConnectionInfo(isConnected) {
    return {
        type: UPDATE_CONNECTION_INFO,
        isConnected,
    };
}

export function pushNotificationReceive(pushNotification) {
    return {
        type: PUSH_NOTIFICATION_RECEIVE,
        pushNotification,
    };
}

export function pushNotificationClear() {
    return {
        type: PUSH_NOTIFICATION_CLEAR,
    };
}

/**
 * Runs the initialization of the Cordova plugins.
 * Once all plugins have been initialized, the cordovaInitDone
 * action is dispatched.
 * @return {function(*)}
 */
export function initCordova(history) {
    return dispatch => {
        Promise.all([
            Storage.init(),
            Push.init(dispatch),
            Device.init(),
            Network.init(dispatch),
            Geolocation.init(),
            Deeplink.init(history),
            Statusbar.init(),
        ]).then(() => {
            console.log('All services initialized, fetching API version');
            dispatch(loadApiVersion());
            dispatch(changeLocale(Storage.getLocale()));
            if (Storage.isLoggedIn()) {
                dispatch(setLogin());
                console.log('Token available, verifying if token is valid');
                dispatch(verifyToken());
                console.log('Send device (including Push token) to backend');
                dispatch(updateDeviceInformation());
            }
            dispatch(cordovaInitDone());
        }).catch(e => {
            console.log(`Error during initialization: ${e}`);
            console.log(e);
            dispatch(cordovaInitDone());
        });
    };
}

export function loadMerchantsRequest() {
    return {
        type: LOAD_MERCHANTS_REQUEST,
    };
}

export function loadMerchantsSuccess(result) {
    return {
        type: LOAD_MERCHANTS_SUCCESS,
        result,
    };
}

export function loadMerchantsError(error) {
    return {
        type: LOAD_MERCHANTS_ERROR,
        error,
    };
}

export function updateMerchant(merchant) {
    return {
        type: UPDATE_MERCHANT,
        merchant,
    };
}

export function clearPushNotifications() {
    return dispatch => dispatch(pushNotificationClear());
}

export function loadMerchants(loggedIn, coordinates) {
    return async dispatch => {

        dispatch(loadMerchantsRequest());
        dispatch(openLoadingOverlay());

        const params = new URLSearchParams();

        if (coordinates) {
            params.append('lng', coordinates.lng);
            params.append('lat', coordinates.lat);
        }

        const urlOrigin = loggedIn ? 'customer' : 'public';
        const url = new URL(`/api/${urlOrigin}/merchant?${params.toString()}`, API_URI).toString();
        const optionHeaders = {
            'Content-Type': 'application/json',
            ...(loggedIn && { Authorization: `Bearer ${Storage.getToken()}` }),
        };

        fetch(url, {
            method: 'GET',
            headers: optionHeaders,
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadMerchantsSuccess(normalize(json, '_id')));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadMerchantsError(error));
                dispatch(showSnackbar(messages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}

export function loadMerchantQueueRequest() {
    return {
        type: LOAD_MERCHANT_QUEUE_REQUEST,
    };
}

export function loadMerchantQueueSuccess(result) {
    return {
        type: LOAD_MERCHANT_QUEUE_SUCCESS,
        result,
    };
}

export function loadMerchantQueueError(error) {
    return {
        type: LOAD_MERCHANT_QUEUE_ERROR,
        error,
    };
}

export function loadMerchantQueue(forceUpdate = false) {
    return dispatch => {
        dispatch(loadMerchantQueueRequest);

        const url = new URL('/api/public/queue/merchant', API_URI).toString();
        const options = {};

        if (forceUpdate) {
            options.headers = { 'Cache-Control': 'no-cache, no-store, must-revalidate' };
        }

        fetch(url, options)
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadMerchantQueueSuccess(normalize(json, '_id')));
            })
            .catch(error => {
                dispatch(loadMerchantQueueError(error));
            });
    };
}

export function loadOrderQueueRequest() {
    return {
        type: LOAD_ORDER_QUEUE_REQUEST,
    };
}

export function loadOrderQueueSuccess(result) {
    return {
        type: LOAD_ORDER_QUEUE_SUCCESS,
        result,
    };
}

export function loadOrderQueueError(error) {
    return {
        type: LOAD_ORDER_QUEUE_ERROR,
        error,
    };
}

export function loadOrderQueue() {
    return dispatch => {
        dispatch(loadOrderQueueRequest);

        const url = new URL('/api/customer/queue/order', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadOrderQueueSuccess(normalize(json, '_id')));
            })
            .catch(error => {
                dispatch(loadOrderQueueError(error));
            });
    };
}

export function verifyTokenRequest() {
    return {
        type: VERIFY_TOKEN_REQUEST,
    };
}

export function verifyTokenSuccess() {
    return {
        type: VERIFY_TOKEN_SUCCESS,
    };
}

export function verifyTokenError(error) {
    return {
        type: VERIFY_TOKEN_ERROR,
        error,
    };
}

export function verifyToken() {
    return dispatch => {
        dispatch(verifyTokenRequest());

        const url = new URL('/api/verify', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.ok) {
                dispatch(verifyTokenSuccess());
                return Promise.resolve();
            } else if (response.status === 401) {
                // Unauthorized -- token is invalid
                return Storage.unsetToken()
                    .then(() => {
                        dispatch(showSnackbar(messages.invalidToken));
                        dispatch(logoutSuccess());
                        dispatch(push('/login'));
                    });
            } else {
                return response.json()
                    .then(json => Promise.reject(json));
            }
        }).catch(error => {
            dispatch(verifyTokenError(error));
        });
    };
}

export function loadApiVersionRequest() {
    return {
        type: LOAD_API_VERSION_REQUEST,
    };
}

export function loadApiVersionSuccess(apiVersion) {
    return {
        type: LOAD_API_VERSION_SUCCESS,
        apiVersion,
    };
}

export function loadApiVersionError(error) {
    return {
        type: LOAD_API_VERSION_ERROR,
        error,
    };
}

export function loadApiVersion() {
    return dispatch => {
        dispatch(loadApiVersionRequest());

        const url = new URL('/api/public/version', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => {
            if (response.ok) {
                return response.json();
            } else {
                return response.json()
                    .then(json => Promise.reject(json));
            }
        }).then(result => {
            dispatch(loadApiVersionSuccess(result.customer));
        }).catch(error => {
            dispatch(loadApiVersionError(error));
        });
    };
}

export function unsetCustomerData() {
    return {
        type: UNSET_CUSTOMER_DATA,
    };
}

export function fetchCustomerDataRequest() {
    return {
        type: FETCH_CUSTOMER_DATA_REQUEST,
    };
}

export function fetchCustomerDataSuccess(data) {
    return {
        type: FETCH_CUSTOMER_DATA_SUCCESS,
        data,
    };
}

export function fetchCustomerDataError(error) {
    return {
        type: FETCH_CUSTOMER_DATA_ERROR,
        error,
    };
}

export function fetchCustomerData() {
    return dispatch => {
        dispatch(fetchCustomerDataRequest());

        const url = new URL('/api/customer', API_URI).toString();

        return fetch(url, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(fetchCustomerDataSuccess(json));
                return Promise.resolve(json);
            })
            .catch(err => {
                dispatch(fetchCustomerDataError(err));
                return Promise.reject(err);
            });
    };
}

const updateCustomerDataRequest = () => ({
    type: UPDATE_CUSTOMER_DATA_REQUEST,
});


const updateCustomerDataSuccess = data => ({
    type: UPDATE_CUSTOMER_DATA_SUCCESS,
    data,
});


const updateCustomerDataError = error => ({
    type: UPDATE_CUSTOMER_DATA_ERROR,
    error,
});


export function updateCustomerData(customer) {
    return (dispatch, state) => {
        dispatch(updateCustomerDataRequest());

        const url = new URL('/api/customer', API_URI).toString();

        return fetch(url, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(customer),
        }).then(response => {
            if (response.ok) {
                const customerUpdate = {
                    ...customer,
                    _version: customer._version + 1,
                };
                if (customer.firstName) {
                    dispatch(showSnackbar(messages.profileUpdateSuccess));
                }
                dispatch(loadMerchants(true, state.global.coordinates));
                return dispatch(updateCustomerDataSuccess(customerUpdate));
            } else {
                return response.json()
                    .then(json => Promise.reject(json));
            }
        }).catch(err => {
            if (customer.promoCodes) {
                if (err.message.toLowerCase() === 'promo code is invalid') {
                    dispatch(showSnackbar(messages.promoCodesUpdateErrorInvalid));
                } else {
                    dispatch(showSnackbar(messages.promoCodesUpdateError));
                }

            } else {
                dispatch(showSnackbar(messages.profileUpdateError));
            }
            return dispatch(updateCustomerDataError(err));
        });
    };
}

export function setTermsOfServiceCompatible(isCompatible) {
    return {
        type: SET_TERMS_OF_SERVICE_COMPATIBLE,
        isCompatible,
    };
}

export function checkTermsOfServiceRequest() {
    return {
        type: CHECK_TERMS_OF_SERVICE_REQUEST,
    };
}

/**
 * The check for the terms of service succeeded.
 * @param {Boolean} compatible True if the terms of service must not be updated and no action is required,
 *                             or false if the terms of service have changed and must be updated.
 * @return {{type: string, compatible: boolean}}
 */
export function checkTermsOfServiceSuccess(compatible) {
    return {
        type: CHECK_TERMS_OF_SERVICE_SUCCESS,
        compatible,
    };
}

export function checkTermsOfServiceError(error) {
    return {
        type: CHECK_TERMS_OF_SERVICE_ERROR,
        error,
    };
}

export function checkTermsOfService() {
    return dispatch => {
        dispatch(checkTermsOfServiceRequest());

        const url = new URL('/api/customer/terms-of-service', API_URI).toString();

        fetch(url, {
            method: 'HEAD',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    // terms of service have not changed, no update required
                    return Promise.resolve(true);
                } else if (response.status === 409) {
                    // terms of service have changed and must be updated
                    return Promise.resolve(false);
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(compatible => {
                dispatch(checkTermsOfServiceSuccess(compatible));
            })
            .catch(error => {
                dispatch(checkTermsOfServiceError(error));
            });
    };
}

export function agreeTermsOfServiceRequest() {
    return {
        type: AGREE_TERMS_OF_SERVICE_REQUEST,
    };
}

export function agreeTermsOfServiceSuccess() {
    return {
        type: AGREE_TERMS_OF_SERVICE_SUCCESS,
    };
}

export function agreeTermsOfServiceError(error) {
    return {
        type: AGREE_TERMS_OF_SERVICE_ERROR,
        error,
    };
}

export function agreeTermsOfService() {
    return dispatch => {
        dispatch(agreeTermsOfServiceRequest());

        const url = new URL('/api/customer/terms-of-service', API_URI).toString();

        fetch(url, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                if (response.ok) {
                    return Promise.resolve(true);
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(compatible => {
                dispatch(agreeTermsOfServiceSuccess(compatible));
            })
            .catch(error => {
                dispatch(agreeTermsOfServiceError(error));
            });
    };
}

export function updateDeviceInformationRequest() {
    return {
        type: UPDATE_DEVICE_INFORMATION_REQUEST,
    };
}

export function updateDeviceInformationSuccess() {
    return {
        type: UPDATE_DEVICE_INFORMATION_SUCCESS,
    };
}

export function updateDeviceInformationError(error) {
    return {
        type: UPDATE_DEVICE_INFORMATION_ERROR,
        error,
    };
}

export function updateDeviceInformation() {
    return async dispatch => {
        dispatch(updateDeviceInformationRequest());

        const device = await getDeviceWithPushToken();
        if (!device) {
            console.info('Updating of device information skipped');
            return;
        }

        const url = new URL('/api/customer/device', API_URI).toString();

        fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${Storage.getToken()}`,
            },
            body: JSON.stringify(device),
        })
            .then(response => {
                if (response.ok) {
                    dispatch(updateDeviceInformationSuccess());
                } else {
                    dispatch(updateDeviceInformationError(`${response.status} ${response.statusText}`));
                }
            })
            .catch(error => dispatch(updateDeviceInformationError(error)));
    };
}
