import { defineMessages } from 'react-intl';

const messages = defineMessages({
    ordersAhead: {
        id: 'OrderState.ordersAhead',
        defaultMessage: 'Orders ahead of you',
    },
    orderInPreparation: {
        id: 'OrderState.orderInPreparation',
        defaultMessage: 'Order in preparation',
    },
    orderReady: {
        id: 'OrderState.orderReady',
        defaultMessage: 'Order ready for pickup',
    },
    pickUpNumber: {
        id: 'OrderState.pickUpNumber',
        defaultMessage: 'Pickup number',
    },
    orderCanceled: {
        id: 'OrderState.orderCanceled',
        defaultMessage: 'Order has been canceled',
    },
    orderPickedUp: {
        id: 'OrderState.orderPickedUp',
        defaultMessage: 'Order has been picked up',
    },
    orderPickUpAt: {
        id: 'OrderState.orderPickUpAt',
        defaultMessage: 'Pick up at',
    },
    orderAborted: {
        id: 'OrderState.aborted',
        defaultMessage: 'Order has been aborted',
    },
    payment: {
        id: 'OrderState.payment',
        defaultMessage: 'Payment',
    },
    pending: {
        id: 'OrderState.pending',
        defaultMessage: 'Pending',
    },
});

export default messages;
