import { defineMessages } from 'react-intl';

const messages = defineMessages({
    cancel: {
        id: 'FormDialog.cancel',
        defaultMessage: 'Cancel',
    },
});

export default messages;
