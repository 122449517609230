import {
    REGISTRATION_REQUEST,
    REGISTRATION_SUCCESS,
    REGISTRATION_ERROR,
    CLEAR_REGISTRATION,
} from './constants';

const initialState = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    areaCode: '',
    loading: false,
    result: null,
    error: null,
};

/*
 * REDUCERS
 */

function registrationReducer(state = initialState, action) {
    switch (action.type) {
        case REGISTRATION_REQUEST:
            return {
                ...state,
                firstName: action.firstName,
                lastName: action.lastName,
                areaCode: action.areaCode,
                phoneNumber: action.phoneNumber,
                loading: true,
                result: null,
                error: null,
            };
        case REGISTRATION_SUCCESS:
            return {
                ...state,
                loading: false,
                result: action.result,
                error: null,
            };
        case REGISTRATION_ERROR:
            return {
                ...state,
                loading: false,
                result: null,
                error: action.error,
            };
        case CLEAR_REGISTRATION:
            return {
                ...state,
                firstName: '',
                lastName: '',
                areaCode: '',
                phoneNumber: '',
            };
        default:
            return state;
    }
}

export default registrationReducer;
