import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import FormattedAmount from 'components/FormattedAmount';
import globalMessages from 'containers/App/messages';

function ProductTable({ products, note, locale }) {
    let total = 0;
    const rows = products.map(p => {
        total += p.quantity * p.priceTotal;
        let id = `${p._id}`;
        if (p.optionGroups) {
            id = `${id}/${p.optionGroups.map(pg => pg.options.map(o => o._id).join('/')).join('/')}`;
        }
        return (
            <div key={`p-${id}`} className="cart-list-item">
                <div className="cart-list-product-line ordered">
                    <div className="cart-list-quantity">
                        <Typography variant="body2">
                            {p.quantity}
                        </Typography>
                    </div>
                    <div className="cart-list-product">
                        <Typography variant="body2">
                            {p.name}
                        </Typography>
                        {p.optionGroups ?
                            p.optionGroups.map(og => (
                                og.options ?
                                    og.options.map(o => (
                                        <div key={`o-${o._id}`} className="cart-list-option-line">
                                            <Typography variant="caption" display="block" className="text-faded">
                                                {o.name}
                                            </Typography>
                                        </div>
                                    )) : ''
                            )) : ''
                        }
                    </div>
                    <div className="cart-list-price">
                        <Typography variant="body2">
                            <FormattedAmount
                                value={p.priceTotal * p.quantity}
                                locale={locale}
                            />&nbsp;€
                        </Typography>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div style={{ marginTop: '1rem', marginBottom: '1rem' }}>
            <div className="section">
                {rows}
                {note &&
                <div key="note" className="cart-list-note">
                    <div className="cart-list-note-item">
                        <Typography variant="caption">
                            <FormattedMessage {...globalMessages.comments} />
                        </Typography>
                        <Typography variant="body2">
                            {note}
                        </Typography>
                    </div>
                </div>
                }
                <div key="p-total" className="cart-total">
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="body2" className="cart-total-label">
                                <FormattedMessage {...globalMessages.totalAmount} />
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" align="right">
                                <FormattedAmount
                                    value={total}
                                    locale={locale}
                                />&nbsp;€
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    );
}

ProductTable.propTypes = {
    products: PropTypes.array.isRequired,
    note: PropTypes.string,
    locale: PropTypes.string.isRequired,
};

export default ProductTable;
