import { defineMessages } from 'react-intl';

const messages = defineMessages({
    unavailable: {
        id: 'CategoryTabContainer.unavailable',
        defaultMessage: 'sold out',
    },
    noMenuCard: {
        id: 'CategoryTabContainer.noMenuCard',
        defaultMessage: 'Currently there are no dishes available',
    },
});

export default messages;
