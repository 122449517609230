import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Redirect, Link } from 'react-router-dom';
import {
    Button,
    Grid,
    TextField,
    Typography,
    Divider,
    NativeSelect,
    Switch,
    CircularProgress,
} from '@material-ui/core';
import {
    Payment as PaymentIcon,
} from '@material-ui/icons';
import { camelCase } from 'lodash';

import { getCurrentOpeningHoursForToday, generateTimeSeries, PAYMENT_METHODS } from 'utils';

import MenuBar from 'containers/MenuBar';
import MerchantQueue from 'containers/MerchantQueue';

import ConfirmDialog from 'components/ConfirmDialog';
import MerchantSummary from 'components/MerchantSummary';
import FullScreen from 'components/FullScreen';
import PreorderIcon from 'components/PreorderIcon';
import OwnPackagingIcon from 'components/OwnPackagingIcon';
import PaymentMethods from 'components/PaymentMethods';
import CartProductsTable from 'components/CartProductsTable';

import globalMessages from 'containers/App/messages';
import { showSnackbar } from 'containers/Snackbar/actions';
import Storage from 'services/Storage';

import { PAYMENT_TYPE, PICK_UP_TYPE } from './constants';
import {
    clearCart,
    setComment,
    setProduct,
    submitOrder,
    setPickUp as setPickUpAction,
    setPaymentType as setPaymentTypeAction,
} from './actions';
import messages from './messages';

import './styles.scss';

class ShoppingCart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            comment: props.comment.length > 0 ? props.comment : '',
            redirect: false,
            redirectToLogin: false,
            openConfirmSubmit: false,
            openConfirmDelete: false,
            ownPackaging: false,
        };
    }

    componentDidMount() {
        this.checkAndWarnMaxProducts();
    }

    componentDidUpdate() {
        this.checkAndWarnMaxProducts();
    }

    checkAndWarnMaxProducts() {
        if (this.props.tooManyProducts) {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max: this.maxProducts() },
            });
        }
    }

    maxProducts() {
        const { merchants, merchantId } = this.props;

        return merchants[merchantId].maxShoppingCartProducts || Infinity;
    }

    handleBackClick() {
        this.setState(() => ({
            redirect: true,
        }));
    }

    handleDeleteClick() {
        this.setState(() => ({
            comment: '',
            openConfirmDelete: false,
            redirect: true,
        }));
        this.props.clearCart();
    }

    handleSubmitClick() {
        const {
            merchants,
            merchantId,
            products,
            loggedIn,
            pickUpType,
            paymentType,
            pickUpCustomerAt,
            isSubmitting,
        } = this.props;
        const { comment, ownPackaging } = this.state;

        const orderProducts = Object.keys(products).map(id => {
            const orderProduct = {
                _id: products[id].product._id,
                _version: products[id].product._version,
                quantity: products[id].quantity,
            };

            if (products[id].product.options) {
                orderProduct.options = products[id].product.options.map(option => ({
                    _id: option._id,
                    _version: option._version,
                }));
            }

            return orderProduct;
        });

        // store prefered payment type STRIPE
        const { paymentTypesEnabled } = merchants[merchantId];

        if (paymentTypesEnabled.includes(PAYMENT_TYPE.stripe)) {
            Storage.setPreferPaymentTypeStripe(paymentType === PAYMENT_TYPE.stripe);
        }

        this.setState({ openConfirmSubmit: false }, () => {
            if (!loggedIn) {
                this.setState(() => ({
                    redirectToLogin: true,
                }));
            } else if (!isSubmitting) {
                this.props.submitOrder({
                    merchantId,
                    merchantStripeId: merchants[merchantId].stripe ? merchants[merchantId].stripe.id : null,
                    products: orderProducts,
                    note: comment,
                    pickUpType,
                    pickUpCustomerAt,
                    ownPackagingEnabled: merchants[merchantId].ownPackagingEnabled,
                    ownPackaging,
                    paymentType,
                });
            }
        });
    }

    handleBlur(e) {
        this.props.setComment(e.target.value);
    }

    handleChangeMessage(e) {
        const val = e.target.value;
        this.setState(() => ({
            comment: val,
        }));
    }

    handleIncrement(product, e) {
        const { merchantId, totalCount } = this.props;

        if (!(totalCount >= this.maxProducts())) {
            this.props.setProduct({
                merchantId,
                product,
                quantity: 1,
                max: this.maxProducts(),
            });
            e.preventDefault();
        } else {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max: this.maxProducts() },
            });
            e.preventDefault();
        }
    }

    handleDecrement(product, e) {
        this.props.setProduct({
            merchantId: this.props.merchantId,
            product,
            quantity: -1,
            max: this.maxProducts(),
        });
        e.preventDefault();
    }

    handleChangePickUp(e) {
        if (e.target.value === 'now') {
            this.props.setPickUp(PICK_UP_TYPE.now, null);
        } else {
            this.props.setPickUp(PICK_UP_TYPE.preOrder, e.target.value);
        }
    }

    handleChangePaymentType(e) {
        this.props.setPaymentType(e.target.value);
    }

    generatePickUpOptions() {
        const {
            intl,
            merchants,
            merchantId,
            locale,
        } = this.props;
        const options = [];
        const times = getCurrentOpeningHoursForToday(merchants[merchantId].openingHours);

        options.push(<option key="pu-now" value="now">{intl.formatMessage({ ...globalMessages.asap })}</option>);

        if (times && times.from && times.to) {
            const start = new Date();
            // first entry is at least 30 mins from now
            start.setMinutes(start.getMinutes() + 30);

            const end = new Date();
            end.setHours(times.to.split(':')[0]);
            end.setMinutes(times.to.split(':')[1]);

            const series = generateTimeSeries(15, start.toISOString(), end.toISOString(), locale);

            series.map(s => (
                options.push(<option key={`pu-${s.short}`} value={s.iso}>{s.short}</option>)
            ));
        }

        return options;
    }

    generatePaymentTypeOptions() {
        const {
            intl,
            merchants,
            merchantId,
        } = this.props;

        return merchants[merchantId].paymentTypesEnabled.map(t => (
            <option key={t} value={t}>
                {intl.formatMessage({ ...globalMessages[camelCase(t)] })}
            </option>
        ));
    }

    handleChangeOwnPackaging(e) {
        this.setState({ ownPackaging: e.target.checked });
    }

    render() {
        const {
            location,
            merchants,
            products,
            merchantId,
            locale,
            pickUpCustomerAt,
            pickUpType,
            paymentType,
            processing,
            intl,
        } = this.props;
        const {
            redirect,
            redirectToLogin,
            openConfirmSubmit,
            openConfirmDelete,
            comment,
            ownPackaging,
        } = this.state;

        let menuBar;
        let content;

        if (redirectToLogin) {
            return (
                <Redirect
                    to={{ pathname: '/login', state: { from: location } }}
                />
            );
        } else if (redirect) {
            const { from } = location.state || { from: '/' };
            return <Redirect to={from} />;
        } else if (Object.keys(products).length <= 0) {
            menuBar = (
                <MenuBar
                    titleMessage={messages.shoppingCart}
                    onBackClick={() => this.handleBackClick()}
                />
            );
            content = (
                <FullScreen
                    classProp="empty-cart-screen"
                    header={() => <FormattedMessage {...messages.emptyHeader} />}
                    body={() => <FormattedMessage {...messages.emptyBody} />}
                    render={() => (
                        <Typography variant="body1">
                            <Link to="/merchants">
                                <FormattedMessage {...messages.emptyLink} />
                            </Link>
                        </Typography>
                    )}
                />
            );
        } else {
            const paymentOptions = this.generatePaymentTypeOptions();

            menuBar = (
                <MenuBar
                    titleMessage={messages.shoppingCart}
                    onBackClick={() => this.handleBackClick()}
                    onDeleteClick={() => this.setState({ openConfirmDelete: true })}
                />
            );

            content = (
                <form noValidate autoComplete="off">
                    <div className="cart-merchant-wrapper">
                        <MerchantSummary merchant={merchants[merchantId]} />
                    </div>
                    <div className="cart-products-wrapper">
                        <CartProductsTable
                            onIncrement={(p, e) => this.handleIncrement(p, e)}
                            onDecrement={(p, e) => this.handleDecrement(p, e)}
                            products={products}
                            merchantId={merchantId}
                            locale={locale}
                            location={location}
                        />
                    </div>
                    <div className="cart-queue-wrapper">
                        {merchants[merchantId].preOrderEnabled &&
                            <Grid container justify="center" spacing={0} className="page">
                                <Grid item xs={4}>
                                    <Typography
                                        variant="body2"
                                        align="left"
                                        style={{ lineHeight: '2rem' }}
                                    >
                                        {pickUpType === PICK_UP_TYPE.preOrder &&
                                            <PreorderIcon
                                                className="cart-info-icon"
                                                style={{ verticalAlign: '-0.3em' }}
                                            />
                                        }
                                        <FormattedMessage {...globalMessages.pickUpType} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <NativeSelect
                                        style={{ float: 'right' }}
                                        className="cart-pick-up-type-select"
                                        value={pickUpCustomerAt || 0}
                                        onChange={e => this.handleChangePickUp(e)}
                                        classes={{ root: 'cart-body2' }}
                                    >
                                        {this.generatePickUpOptions()}
                                    </NativeSelect>
                                </Grid>
                            </Grid>
                        }
                        {pickUpType === PICK_UP_TYPE.now &&
                            <React.Fragment>
                                <Divider />
                                <Grid container justify="center" spacing={0} className="page">
                                    <Grid item xs={8}>
                                        <Typography variant="body2" align="left">
                                            <FormattedMessage {...globalMessages.ordersQueue} />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <div style={{ float: 'right' }}>
                                            <MerchantQueue id={merchants[merchantId]._id} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        }
                    </div>
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={4}>
                            <Typography
                                variant="body2"
                                align="left"
                                style={{ lineHeight: '2rem' }}
                            >
                                <FormattedMessage {...globalMessages.paymentType} />
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            {paymentOptions.length > 1
                                ? (
                                    <NativeSelect
                                        style={{ float: 'right' }}
                                        className="cart-pick-up-type-select"
                                        value={paymentType}
                                        onChange={e => this.handleChangePaymentType(e)}
                                        data-testid="payment-type-select"
                                        classes={{ root: 'cart-body2' }}
                                    >
                                        {paymentOptions}
                                    </NativeSelect>
                                )
                                : (
                                    <Typography
                                        variant="body2"
                                        align="right"
                                        style={{ lineHeight: '2rem' }}
                                    >
                                        {intl.formatMessage({
                                            ...globalMessages[camelCase(merchants[merchantId].paymentTypesEnabled[0])],
                                        })}
                                    </Typography>
                                )
                            }
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justify="center" alignItems="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <PaymentIcon />
                            <Typography variant="body2" align="left" className="payment-methods">
                                <FormattedMessage {...globalMessages.paymentMethods} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" align="right">
                                {paymentType === 'ON_SITE'
                                    ? <PaymentMethods values={merchants[merchantId].paymentMethod} />
                                    : <PaymentMethods values={PAYMENT_METHODS[paymentType]} />
                                }

                            </Typography>
                        </Grid>
                    </Grid>
                    <div className="cart-comment-wrapper">
                        <TextField
                            id="multiline-flexible"
                            label={<FormattedMessage {...messages.comments} />}
                            fullWidth
                            multiline
                            rowsMax="4"
                            value={comment}
                            onChange={e => this.handleChangeMessage(e)}
                            InputProps={{
                                onBlur: e => this.handleBlur(e),
                                classes: { root: 'cart-body2' },
                            }}
                            InputLabelProps={{
                                classes: { root: 'cart-body2' },
                            }}
                            // This is not a duplicate! InputProps =/= inputProps
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                                maxLength: 100,
                            }}
                            margin="normal"
                        />
                    </div>
                    {merchants[merchantId].ownPackagingEnabled &&
                        <React.Fragment>
                            <Divider />
                            <Grid container justify="center" spacing={0} className="page">
                                <Grid item xs={10} className="cart-own-packaging">
                                    <Typography
                                        variant="body2"
                                        align="left"
                                    >
                                        <OwnPackagingIcon
                                            className="cart-info-icon"
                                            style={{ verticalAlign: '-0.3em' }}
                                        />
                                        <FormattedMessage {...globalMessages.bringOwnPackaging} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <div style={{ float: 'right' }}>
                                        <Switch
                                            checked={ownPackaging}
                                            onChange={e => this.handleChangeOwnPackaging(e)}
                                            value="ownPackaging"
                                            color="primary"
                                            edge="end"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    }
                    <div className="cart-button-wrapper">
                        <Button
                            fullWidth
                            variant="contained"
                            className="cart-button"
                            disabled={processing}
                            onClick={() => this.setState({ openConfirmSubmit: true })}
                        >
                            {processing && (
                                <React.Fragment>
                                    <CircularProgress color="inherit" size="1rem" />
                                    &nbsp;
                                </React.Fragment>
                            )}
                            <FormattedMessage {...messages.submitOrder} />
                        </Button>
                    </div>
                </form>
            );
        }

        return (
            <div className="shopping-cart-page">
                {menuBar}

                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmDelete: false })}
                    open={openConfirmDelete}
                    titleMessage={messages.confirmDeleteTitle}
                    contentMessage={messages.confirmDeleteContent}
                    confirmMessage={messages.delete}
                    onCancelClick={() => this.setState({ openConfirmDelete: false })}
                    onConfirmClick={() => this.handleDeleteClick()}
                />

                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmSubmit: false })}
                    open={openConfirmSubmit}
                    titleMessage={messages.confirmSubmitTitle}
                    contentMessage={messages.confirmSubmitContent}
                    confirmMessage={messages.submit}
                    onCancelClick={() => this.setState({ openConfirmSubmit: false })}
                    onConfirmClick={() => this.handleSubmitClick()}
                />

                <div className="page-content">
                    {content}
                </div>
            </div>
        );
    }

}

ShoppingCart.propTypes = {
    intl: PropTypes.object,
    location: PropTypes.object,
    clearCart: PropTypes.func,
    setComment: PropTypes.func,
    comment: PropTypes.string,
    merchants: PropTypes.object,
    products: PropTypes.object,
    merchantId: PropTypes.string,
    tooManyProducts: PropTypes.bool,
    submitOrder: PropTypes.func,
    loginResult: PropTypes.object,
    loggedIn: PropTypes.bool,
    setProduct: PropTypes.func,
    showSnackbar: PropTypes.func,
    totalCount: PropTypes.number,
    locale: PropTypes.string.isRequired,
    pickUpType: PropTypes.string,
    paymentType: PropTypes.string,
    pickUpCustomerAt: PropTypes.string,
    setPickUp: PropTypes.func,
    setPaymentType: PropTypes.func,
    isSubmitting: PropTypes.bool,
    processing: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,
        merchants: state.global.merchants,
        comment: state.shoppingCart.comment,
        products: state.shoppingCart.products,
        merchantProducts: state.productsReducer.products,
        merchantId: state.shoppingCart.merchantId,
        tooManyProducts: state.shoppingCart.tooManyProducts,
        loginResult: state.loginReducer.result,
        loggedIn: state.loginReducer.loggedIn,
        totalCount: state.shoppingCart.totalCount,
        pickUpType: state.shoppingCart.pickUpType,
        paymentType: state.shoppingCart.paymentType,
        pickUpCustomerAt: state.shoppingCart.pickUpCustomerAt,
        locale: state.languageProviderReducer.locale,
        isSubmitting: state.shoppingCart.isSubmitting,
        processing: state.shoppingCart.processing,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearCart: () => dispatch(clearCart()),
        setComment: text => dispatch(setComment(text)),
        setProduct: ({
            merchantId,
            product,
            quantity,
            max,
        }) => dispatch(setProduct({
            merchantId,
            product,
            quantity,
            max,
        })),
        submitOrder: ({
            merchantId,
            merchantStripeId,
            products,
            note,
            pickUpType,
            paymentType,
            pickUpCustomerAt,
            ownPackagingEnabled,
            ownPackaging,
        }) => dispatch(submitOrder({
            merchantId,
            merchantStripeId,
            products,
            note,
            pickUpType,
            paymentType,
            pickUpCustomerAt,
            ownPackagingEnabled,
            ownPackaging,
        })),
        showSnackbar: (msg, close) => dispatch(showSnackbar(msg, close)),
        setPickUp: (type, date) => dispatch(setPickUpAction(type, date)),
        setPaymentType: type => dispatch(setPaymentTypeAction(type)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ShoppingCart));
