import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'PromoCodes.title',
        defaultMessage: 'Promo Codes',
    },
    emptyHeader: {
        id: 'PromoCodes.emptyHeader',
        defaultMessage: 'Empty Promo Codes',
    },
    emptyBody: {
        id: 'PromoCodes.emptyBody',
        defaultMessage: 'You have currently not entered any promo codes. Use the button below to add a new promo code.',
    },
    addDialogTitle: {
        id: 'PromoCodes.addDialogTitle',
        defaultMessage: 'Add Promo Code',
    },
    add: {
        id: 'PromoCodes.add',
        defaultMessage: 'Add',
    },
    deleteDialogTitle: {
        id: 'PromoCodes.deleteDialogTitle',
        defaultMessage: 'Delete Promo Code',
    },
    deleteDialogMessage: {
        id: 'PromoCodes.deleteDialogMessage',
        defaultMessage: 'Do you really want to delete the Promo Code?',
    },
    delete: {
        id: 'PromoCodes.delete',
        defaultMessage: 'Delete',
    },
});

export default messages;
