/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    TextField,
    InputAdornment,
} from '@material-ui/core';

import { sanitizePhoneNumber } from 'utils';

import messages from './messages';

class InputPhoneNumber extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            phoneNumber: props.phoneNumber,
            phoneNumberLabel: messages.phoneNumber,
            phoneNumberError: false,
            phoneNumberDirty: false,
            phoneNumberDisabled: props.disabled,
        };
    }

    onChangePhoneNumber(event) {
        const val = event.target.value;
        this.setStatePhoneNumber(val, this.state.phoneNumberDirty, () => {
            this.props.onChange(this.state.phoneNumber);
        });
    }

    onBlurPhoneNumber(event) {
        const val = event.target.value;
        this.setStatePhoneNumber(val, true, () => {
            if (this.props.onBlur) {
                this.props.onBlur(this.state.phoneNumber);
            }
        });

    }

    /**
     * Validates the phone number, and sets the state for it.
     * @param {string} val The value of the number input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @param {function(): void} callback Function which should be called after setState.
     * @return {boolean} True if valid, otherwise false.
     */
    setStatePhoneNumber(val, dirty = false, callback) {
        const phoneNumber = sanitizePhoneNumber(val);
        let phoneNumberLabel = messages.phoneNumber;
        let phoneNumberError = false;

        if (dirty && !val) {
            phoneNumberLabel = messages.phoneNumberRequired;
            phoneNumberError = true;
        } else if (dirty && val.length < 10) {
            phoneNumberLabel = messages.phoneNumberInvalid;
            phoneNumberError = true;
        } else if (dirty && val.length > 15) {
            phoneNumberLabel = messages.phoneNumberInvalid;
            phoneNumberError = true;
        }

        this.setState({
            phoneNumber,
            phoneNumberLabel,
            phoneNumberError,
            phoneNumberDirty: dirty,
        }, callback);

        return !phoneNumberError;
    }

    validate() {
        return this.setStatePhoneNumber(this.state.phoneNumber, true);
    }

    render() {
        return (
            <TextField
                required
                disabled={this.state.phoneNumberDisabled}
                fullWidth
                autoComplete="new-phonenumber"
                id="phoneNumber"
                type="tel"
                value={this.state.phoneNumber}
                label={<FormattedMessage {...this.state.phoneNumberLabel} />}
                error={this.state.phoneNumberError}
                onChange={e => this.onChangePhoneNumber(e)}
                InputProps={{
                    onBlur: e => this.onBlurPhoneNumber(e),
                    startAdornment: <InputAdornment position="start">+43</InputAdornment>,
                }}
                margin="normal"
            />
        );
    }

}

InputPhoneNumber.propTypes = {
    // initial phone number value
    phoneNumber: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    // triggered on change
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

export default InputPhoneNumber;
