import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import {
    IconButton,
    Grid,
    Typography,
} from '@material-ui/core';
import {
    Add as AddIcon,
    Remove as RemoveIcon,
} from '@material-ui/icons';
import FormattedAmount from 'components/FormattedAmount';
import globalMessages from 'containers/App/messages';
import messages from './messages';

import './styles.scss';

function CartProductsTable({
    onIncrement,
    onDecrement,
    products,
    merchantId,
    locale,
    location,
}) {
    const productRows = [];
    let total = 0;
    let content;

    Object.keys(products).forEach(i => {
        const textStyles = [];
        if (products[i].isValid) {
            textStyles.push('cart-list-product-valid');
        } else {
            textStyles.push('cart-list-product--invalid');
        }
        textStyles.push('cart-list-product-title');
        textStyles.push('narrow-line');
        total += (products[i].quantity *
            (
                products[i].product.price +
                (products[i].product.options
                    ? products[i].product.options.reduce((a, b) => a + b.price, 0)
                    : 0)
            )
        );
        const to = {
            pathname: `/product-options/${merchantId}/${products[i].product._id}`,
            state: {
                from: {
                    ...location,
                },
                quantity: products[i].quantity,
                options: products[i].product.options,
                shoppingCartId: i,
            },
        };
        productRows.push((
            <div key={`p-${i}`}>
                <Link
                    to={products[i].product.options ? to : {}}
                    className="cart-list-item-link"
                >
                    <div className="cart-list-item">
                        <div className="cart-list-product-line">
                            <div className="cart-list-minus">
                                <IconButton color="primary" onClick={e => onDecrement(products[i].product, e)}>
                                    <RemoveIcon />
                                </IconButton>
                            </div>
                            <div className="cart-list-quantity" align="center">
                                <Typography variant="body2" className="narrow-line">
                                    {products[i].quantity}
                                </Typography>
                            </div>
                            <div className="cart-list-plus">
                                <IconButton color="primary" onClick={e => onIncrement(products[i].product, e)}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div className="cart-list-product">
                                <Typography variant="body2" className={textStyles.join(' ')}>
                                    {products[i].product.name}
                                </Typography>
                                {products[i].product.options ?
                                    products[i].product.options.map(option => (
                                        <div className="cart-list-option-line" key={`o-${i}-${option._id}`}>
                                            <Typography variant="caption" display="block" className="text-faded">
                                                {option.name}
                                            </Typography>
                                        </div>
                                    )) : ''
                                }
                            </div>
                            <div className="cart-list-price">
                                <Typography variant="body2" className="narrow-line">
                                    <FormattedAmount
                                        value={(products[i].quantity *
                                            (
                                                products[i].product.price +
                                                (products[i].product.options
                                                    ? products[i].product.options.reduce((a, b) => a + b.price, 0)
                                                    : 0)
                                            )
                                        )}
                                        locale={locale}
                                    />&nbsp;€
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        ));
    });

    productRows.push((
        <div key="p-total" className="cart-total">
            <Grid container spacing={0}>
                <Grid item xs={9}>
                    <Typography variant="body2" className="cart-total-label">
                        <FormattedMessage {...globalMessages.totalAmount} />
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" align="right" className="narrow-line">
                        <FormattedAmount
                            value={total}
                            locale={locale}
                        />&nbsp;€
                    </Typography>
                </Grid>
            </Grid>
        </div>
    ));

    if (productRows.length > 1) {
        content = (
            <div>
                {productRows}
            </div>
        );
    } else {
        content = (
            <Typography variant="body1" className="cart-empty-text">
                <FormattedMessage {...messages.cartEmpty} />
            </Typography>
        );
    }

    return (
        <div className="cart-products-table">
            {content}
        </div>
    );
}

CartProductsTable.propTypes = {
    onIncrement: PropTypes.func,
    onDecrement: PropTypes.func,
    products: PropTypes.object,
    merchantId: PropTypes.string,
    locale: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
};

export default CartProductsTable;
