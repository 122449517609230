import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Badge, Typography } from '@material-ui/core';
import {
    PinDrop as PinDropIcon,
    AccessTime as AccessTimeIcon,
    MyLocation as MyLocationIcon,
    CreditCard as CreditCardIcon,
} from '@material-ui/icons';

import MerchantLogo from 'components/MerchantLogo';
import PreorderIcon from 'components/PreorderIcon';
import OwnPackagingIcon from 'components/OwnPackagingIcon';
import MerchantQueue from 'containers/MerchantQueue';
import messages from 'containers/App/messages';

import {
    getCurrentOpeningHoursForToday,
    getNextOpeningHoursForToday,
    formatFromTo,
    formatAddress,
    isOpen,
    calculateDistance,
} from '../../utils';

import './styles.scss';

function MerchantBox({ merchant, userCoordinates }) {
    const hoursCurrent = getCurrentOpeningHoursForToday(merchant.openingHours);
    const hoursNext = getNextOpeningHoursForToday(merchant.openingHours);
    const open = isOpen(merchant);

    let distance = null;
    if (merchant.address.geolocation && merchant.address.geolocation.coordinates && userCoordinates) {
        distance = calculateDistance(
            {
                lng: merchant.address.geolocation.coordinates[0],
                lat: merchant.address.geolocation.coordinates[1],
            },
            userCoordinates
        );
    }

    return (
        <div className={`merchant-box ${open ? 'open' : 'closed'}`} data-testid="merchant-box">
            <MerchantLogo id={merchant._id} isOpen={open} hoursCurrent={hoursCurrent} />
            <div className="merchant-box-content">
                <div className="merchant-box-title-promo-wrapper">
                    <div className="merchant-box-title-wrapper">
                        <Typography variant="h6">
                            {merchant.name}
                        </Typography>
                        <div className="queue-position">
                            {/* merchant is open */}
                            {open &&
                            <MerchantQueue id={merchant._id} dataTestid={`queue-${merchant.name.replace(' ', '-')}`} />
                            }
                            {/* merchant is closed but has opening hours later */}
                            {!open && !hoursCurrent && hoursNext &&
                            <Typography variant="caption">
                                <FormattedMessage {...messages.opensAt} /> {hoursNext.from}
                            </Typography>
                            }
                        </div>
                    </div>
                    {merchant.promoCodes && merchant.promoCodes.length > 0 &&
                        merchant.promoCodes.map(code => (
                            <Badge
                                key={code}
                                // set max, otherwise content > 99 will be cut off. promo code can be max 20 chars long.
                                max={99999999999999999999}
                                badgeContent={code}
                                color="primary"
                                classes={{
                                    badge: 'merchant-box-badge',
                                    anchorOriginTopRightRectangle: 'merchant-box-badge-origin',
                                }}
                            />))
                    }
                </div>
                {distance &&
                    <div className="merchant-box-content-entry">
                        <MyLocationIcon className="merchant-box-icon secondary-icon" />
                        <Typography variant="body2">
                            {`${distance.distance} ${distance.unit}`}
                        </Typography>
                    </div>
                }
                <div className="merchant-box-content-entry">
                    <PinDropIcon className="merchant-box-icon secondary-icon" />
                    <Typography variant="body2">
                        {formatAddress(merchant.address)}
                    </Typography>
                </div>
                <div className="merchant-box-content-entry">
                    <AccessTimeIcon className="merchant-box-icon secondary-icon" />
                    <Typography variant="body2">
                        {/* merchant is open and within opening hours */}
                        {open && hoursCurrent &&
                            formatFromTo(hoursCurrent.from, hoursCurrent.to)
                        }
                        {/* merchant has been opened manually */}
                        {open && !hoursCurrent &&
                            <FormattedMessage {...messages.openNow} />
                        }
                        {/* merchant has been closed manually */}
                        {!open && hoursCurrent &&
                            <FormattedMessage {...messages.closedTemporarily} />
                        }
                        {/* merchant is closed but has opening hours later */}
                        {!open && !hoursCurrent && hoursNext &&
                            formatFromTo(hoursNext.from, hoursNext.to)
                        }
                        {/* merchant is closed and has no opening hours today */}
                        {!open && !hoursCurrent && !hoursNext &&
                            <FormattedMessage {...messages.closedToday} />
                        }
                    </Typography>
                    <div className="merchant-features">
                        {merchant.paymentTypesEnabled.includes('STRIPE') &&
                            <CreditCardIcon className="app-icon" size="24px" />
                        }
                        {merchant.preOrderEnabled &&
                            <PreorderIcon size="24px" />
                        }
                        {merchant.ownPackagingEnabled &&
                            <OwnPackagingIcon size="24px" />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
/* eslint-enable */

MerchantBox.propTypes = {
    merchant: PropTypes.object.isRequired,
    userCoordinates: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
};

export default MerchantBox;
