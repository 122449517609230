import { defineMessages } from 'react-intl';

const messages = defineMessages({
    cartEmpty: {
        id: 'CartProductsTable.cartEmpty',
        defaultMessage: 'Your order is currently empty. Add more products or clear your cart to continue.',
    },
});

export default messages;
