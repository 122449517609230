import React from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    FormLabel,
    Typography,
    Box,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import FormattedAmount from 'components/FormattedAmount';

function ProductOptionCheckboxGroup(props) {

    const {
        optionGroup,
        options,
        locale,
        selected,
        quantity,
    } = props;
    const [value, setValue] = React.useState(
        options.reduce((obj, option) => ({
            ...obj, [option._id]: selected.findIndex(selectOption => option._id === selectOption._id) !== -1,
        }), {})
    );

    const handleChange = event => {
        const newValue = { ...value, [event.target.name]: event.target.checked };
        setValue(newValue);
        const selectedOptions = options.reduce((arr, option) => {
            if (newValue[option._id]) {
                arr.push({
                    _id: option._id,
                    name: option.name,
                    price: option.priceAdditional,
                    group: option.optionGroup,
                    _version: option._version,
                });
            }
            return arr;
        }, []);
        props.onChange(
            selectedOptions,
            optionGroup._id
        );
    };

    const content = (
        <FormControl classes={{ root: 'product-option-group-form-control' }}>
            <div className="product-option-group-label">
                <FormLabel>
                    <Typography variant="body2">{optionGroup.name}</Typography>
                </FormLabel>
            </div>
            <FormGroup>
                {options.map(option => {
                    const fontWeight = value[option._id] ? 600 : '';
                    return (
                        <div className="product-option-line" key={`po-${option._id}`}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={value[option._id]}
                                        name={option._id}
                                        onChange={handleChange}
                                        color="primary"
                                        classes={{ root: 'product-option-radio-checkbox' }}
                                    />
                                }
                                label={
                                    <Typography variant="body2" component="span">
                                        <Box fontWeight={fontWeight}>{option.name}</Box>
                                    </Typography>
                                }
                            />
                            {option.priceAdditional !== 0 ?
                                <div className="product-option-price">
                                    <Typography variant="body2" component="span" className="narrow-line">
                                        <Box fontWeight={fontWeight}>
                                            +&nbsp;
                                            <FormattedAmount
                                                value={(option.priceAdditional * quantity)}
                                                locale={locale}
                                            />&nbsp;€
                                        </Box>
                                    </Typography>
                                </div> : ''
                            }
                        </div>
                    );
                })}
            </FormGroup>
        </FormControl>
    );

    return content;
}

ProductOptionCheckboxGroup.propTypes = {
    selected: PropTypes.array,
    quantity: PropTypes.number.isRequired,
    optionGroup: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    locale: PropTypes.string.isRequired,
    onChange: PropTypes.func,
};

export default ProductOptionCheckboxGroup;
