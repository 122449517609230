import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'OrderDetails.title',
        defaultMessage: 'Order Details',
    },
    info: {
        id: 'OrderDetails.info',
        defaultMessage: 'Additional info',
    },
    cancelOrder: {
        id: 'OrderDetails.cancelOrder',
        defaultMessage: 'Cancel Order',
    },
    confirmCancelTitle: {
        id: 'OrderDetails.confirmCancelTitle',
        defaultMessage: 'Cancel order?',
    },
    confirmCancelContent: {
        id: 'OrderDetails.confirmCancelContent',
        defaultMessage: 'Do you really want to cancel this order?',
    },
    orderNumber: {
        id: 'OrderDetails.orderNumber',
        defaultMessage: 'Order number',
    },
    orderDate: {
        id: 'OrderDetails.orderDate',
        defaultMessage: 'Order date',
    },
    cancelNotPossible: {
        id: 'OrderDetails.cancelNotPossible',
        defaultMessage: 'You currently cannot cancel your order. Please contact the restaurant directly.',
    },
    resumePayment: {
        id: 'OrderDetails.resumePayment',
        defaultMessage: 'Resume payment',
    },
});

export default messages;
