/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { TextField } from '@material-ui/core';

import messages from './messages';
import './styles.scss';

class InputPromoCode extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            promoCode: props.promoCode ?? '',
            promoCodeLabel: messages.promoCode,
            promoCodeError: false,
            promoCodeDirty: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.promoCode && prevState.promoCode !== nextProps.promoCode) {
            return {
                ...prevState,
                promoCode: nextProps.promoCode,
            };
        }

        return null;
    }

    componentDidMount() {
        if (this.state.promoCode) {
            this.validate();
        }
    }

    onChangePromoCode(event) {
        const val = event.target.value.toUpperCase();
        this.setStatePromoCode(val, this.state.promoCodeDirty);
        this.props.onChange(val);
    }

    onBlurPromoCode(event) {
        const val = event.target.value;
        this.setStatePromoCode(val, true);
    }

    /**
     * Validates the promo code, and sets the state for it.
     * @param {string} val The value of the promo code input.
     * @param {boolean} dirty Flag if the input has already lost the focus once.
     * @return {boolean} True if valid, otherwise false.
     */
    setStatePromoCode(val, dirty = false) {
        const promoCode = val.trim();
        let promoCodeLabel = messages.promoCode;
        let promoCodeError = false;
        const validRegex = /^[A-Z0-9]+$/;

        if (dirty && !val) {
            promoCodeLabel = messages.promoCodeRequired;
            promoCodeError = true;
        } else if (dirty && !promoCode.match(validRegex)) {
            promoCodeLabel = messages.promoCodeInvalid;
            promoCodeError = true;
        } else if (dirty && val.length < 8) {
            promoCodeLabel = messages.promoCodeInvalidLength;
            promoCodeError = true;
        } else if (dirty && val.length > 20) {
            promoCodeLabel = messages.promoCodeInvalidLength;
            promoCodeError = true;
        } else if (dirty &&  this.props.promoCodes.find(p => p === promoCode)) {
            promoCodeLabel = messages.promoCodeDuplicate;
            promoCodeError = true;
        }

        this.setState({
            promoCode,
            promoCodeLabel,
            promoCodeError,
            promoCodeDirty: dirty,
        });

        return !promoCodeError;
    }

    validate() {
        return this.setStatePromoCode(this.state.promoCode, true);
    }

    render() {
        return (
            <TextField
                required
                fullWidth
                autoFocus={this.props.autoFocus}
                autoComplete="new-promo-code"
                id="promoCode"
                type="text"
                value={this.state.promoCode}
                label={<FormattedMessage {...this.state.promoCodeLabel} />}
                error={this.state.promoCodeError}
                onChange={e => this.onChangePromoCode(e)}
                InputProps={{
                    onBlur: e => this.onBlurPromoCode(e),
                }}
                InputLabelProps={{
                    classes: {
                        shrink: 'input-promo-code-label-shrinked',
                    },
                }}
                margin="dense"
            />
        );
    }

}

InputPromoCode.propTypes = {
    promoCode: PropTypes.string,
    promoCodes: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
};

export default InputPromoCode;
