import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';


function LocaleDate({ locale, isoDate, variant }) {
    const localizedDate = moment(isoDate).locale(locale).format('LLL');

    return (
        <Typography align="right" variant={variant}>
            {localizedDate}
        </Typography>
    );
}


LocaleDate.propTypes = {
    locale: PropTypes.string,
    isoDate: PropTypes.string,
    variant: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        locale: state.languageProviderReducer.locale,
    };
}


export default connect(mapStateToProps)(LocaleDate);
