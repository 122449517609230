import _ from 'lodash';
import { LOCALES, getBrowserLocale } from '../i18n';
import { APP_CONFIG_PREFIX } from '../config';
import File from './File';

/**
 * The API token.
 * @type {string}
 */
const KEY_TOKEN = `${APP_CONFIG_PREFIX}_TOKEN`;

/**
 * The preferred language for the UI.
 * @type {string}
 */
const KEY_LOCALE = `${APP_CONFIG_PREFIX}_LOCALE`;

/**
 * If Stripe payment type should be pre-selected (if available).
 * @type {string}
 */
const KEY_PREFER_PAYMENT_TYPE_STRIPE = `${APP_CONFIG_PREFIX}_PREFER_PAYMENT_TYPE_STRIPE`;

/**
 * The name of the file created during the first launch of the app.
 * @type {string}
 */
const FILE_NAME_FIRST_LAUNCH = `.${APP_CONFIG_PREFIX}_FIRST_LAUNCH`;

class Storage {

    constructor() {
        this.secureStorage = undefined;
        this.token = undefined;
        this.locale = undefined;
        this.preferPaymentTypeStripe = undefined;
        this.localStorage = undefined;

        try {
            this.localStorage = window.localStorage;
        } catch (e) {
            console.log('Local storage is unavailable');
            this.localStorage = null;
        }
    }

    /**
     * Initializes the secure storage. Can be called multiple times, but
     * returns always the same pending or resolved promise. This function
     * also loads the token of the SecureStorage or localStorage.
     * @returns {Promise}
     */
    init() {
        return this.initSecureStorage()
            .then(() => this.unsetTokenOnFirstLaunch())
            .then(() => Promise.all([
                this.loadToken(),
                this.loadLocale(),
                this.loadPreferPaymentTypeStripe(),
            ]))
            .catch(() => Promise.resolve());
    }

    /**
     * Initializes the SecureStorage plugin.
     * @returns {Promise} Promise which always resolves
     */
    initSecureStorage() {
        return new Promise(resolve => {
            document.addEventListener('deviceready', () => {
                try {
                    const SecureStorage = this.getPlugin();

                    this.secureStorage = new SecureStorage(
                        () => {
                            console.log('Storage: Service initialized');
                            resolve();
                        },
                        err => {
                            console.log(`Storage: Error initializing service: ${err}`);
                            this.secureStorage = null;
                            resolve();
                        },
                        'HolApp' // namespace
                    );
                } catch (err) {
                    console.log(`Storage: Error initializing service: ${err}`);
                    this.secureStorage = null;
                    resolve();
                }
            });
        });
    }

    /**
     * Get the Cordova SecureStorage plugin constructor.
     * @returns {null|*}
     */
    getPlugin() {
        const { cordova } = window;
        if (this.isMobile()
            && cordova.plugins && cordova.plugins.SecureStorage) {
            return cordova.plugins.SecureStorage;
        } else {
            throw Error(`Storage: SecureStorage plugin for platform ${cordova.platformId} not available`);
        }
    }

    /**
     * Checks if we are on a mobile device.
     * @return {boolean} True if a mobile device, otherwise false.
     */
    isMobile() {
        const { cordova } = window;
        return !!cordova && (cordova.platformId === 'ios' || cordova.platformId === 'android');
    }

    /**
     * Checks if we are in a browser.
     */
    isBrowser() {
        const { cordova } = window;
        return !!cordova && cordova.platformId === 'browser';
    }

    /**
     * Checks if the secure storage is available. Can be used if we are on a mobile, and the
     * secure storage has been initialized without errors.
     * @return {boolean} True if secure storage can be used, otherwise false.
     */
    hasSecureStorage() {
        return !!this.secureStorage;
    }

    /**
     * Checks if the localStorage is available. Can be used if we are in a browser, the secure
     * storage is not available but the localStorage object is available.
     * @return {boolean} True if localStorage can be used, otherwise false.
     */
    hasLocalStorage() {
        try {
            const key = Math.random().toString();
            const val = '__storage_test__';

            this.localStorage.setItem(key, val);
            this.localStorage.removeItem(key);

            return true;
        } catch (e) {
            return false;
        }
    }

    /**
     * Set a local storage item.
     * @param key
     * @param value
     * @return {Promise}
     */
    setLocalStorageItem(key, value) {
        return new Promise(resolve => {
            this.localStorage.setItem(key, value);
            resolve();
        });
    }

    /**
     * Set a secure storage item.
     * @param key
     * @param value
     * @returns {Promise}
     */
    setSecureStorageItem(key, value) {
        return new Promise((resolve, reject) => {
            this.secureStorage.set(
                () => {
                    console.log(`Storage: Successfully set ${key} in secure storage`);
                    resolve();
                },
                err => {
                    console.log(`Storage: Failed saving ${key} in secure storage: ${err}`);
                    reject(err);
                },
                key,
                value
            );
        });
    }

    /**
     * Get the local storage item.
     * @param key
     * @returns {Promise}
     */
    getLocalStorageItem(key) {
        return new Promise(resolve => {
            resolve(this.localStorage.getItem(key));
        });
    }

    /**
     * Get a secure storage item.
     * @param key
     * @returns {Promise}
     */
    getSecureStorageItem(key) {
        return new Promise(resolve => {
            this.secureStorage.get(
                val => {
                    console.log(`Storage: Successfully read ${key} from secure storage`);
                    resolve(val);
                },
                err => {
                    console.log(`Storage: Failed reading ${key} from secure storage: ${err}`);
                    // don't fail, localStorage returns 'null' for unkown key
                    resolve(null);
                },
                key
            );
        });
    }

    removeLocalStorageItem(key) {
        return new Promise(resolve => {
            this.localStorage.removeItem(key);
            resolve();
        });
    }

    /**
     * Removes a secure storage item.
     * @param key
     * @returns {Promise}
     */
    removeSecureStorageItem(key) {
        return new Promise((resolve, reject) => {
            this.secureStorage.remove(
                () => {
                    console.log(`Storage: Successfully removed ${key} from secure storage`);
                    resolve();
                },
                err => {
                    console.log(`Storage: Failed removing ${key} from secure storage: ${err}`);
                    reject(err);
                },
                key
            );
        });
    }

    /**
     * Loads the JWS token from the SecureStorage or localStorage.
     * @returns {Promise}
     */
    loadToken() {
        let promise;

        if (this.isMobile() && this.hasSecureStorage()) {
            promise = this.getSecureStorageItem(KEY_TOKEN);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            promise = this.getLocalStorageItem(KEY_TOKEN);
        } else {
            promise = Promise.reject();
        }

        return promise.then(token => {
            if (token) {
                this.token = token;
                console.log('Storage: Loaded token from storage');
            }
        });
    }

    /**
     * Checks if this is the first launch of the app after a new install.
     * @return {Promise<Boolean>} True if first launch, otherwise false.
     */
    isFirstLaunch() {
        return new Promise(resolve => {
            File.fileExistsInDocuments(FILE_NAME_FIRST_LAUNCH)
                .then(exists => {
                    console.log(`Storage: First launch file ${exists ? '' : 'NOT '}exists`);
                    resolve(!exists);
                })
                .catch(() => resolve(false));
        });
    }

    /**
     * Removes any previous stored token if this is the first launch of the mobile app.
     * Always resolves the promise, even if the token was not found in the storage.
     * If we are not on a mobile device we skip the check for the first launch.
     * @return {Promise}
     */
    unsetTokenOnFirstLaunch() {
        if (!this.isMobile()) {
            return Promise.resolve();
        }

        return new Promise(resolve => {
            this.isFirstLaunch()
                .then(firstLaunch => {
                    if (firstLaunch) {
                        console.log('Storage: First launch of app, removing any existing tokens');

                        // set the file so we know the app has already been launched the first time
                        File.touchFileInDocuments(FILE_NAME_FIRST_LAUNCH)
                            .then(() => console.log('Storage: First launch file created'))
                            .catch(() => console.log('Storage: Unable to set first launch of app'));

                        return this.unsetToken();
                    } else {
                        console.log('Storage: Not first launch of app, use existing token');
                        return Promise.resolve();
                    }
                })
                .then(() => resolve())
                .catch(() => resolve());
        });
    }

    /**
     * Set JWT token.
     * @returns {Promise}
     */
    setToken(token) {
        this.token = token;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.setSecureStorageItem(KEY_TOKEN, token);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.setLocalStorageItem(KEY_TOKEN, token);
        } else {
            return Promise.reject();
        }
    }

    /**
     * Get the JWT.
     */
    getToken() {
        return this.token;
    }

    /**
     * Returns the current login state.
     * @returns {Object|null} The payload of the JWT token.
     */
    getLogin() {
        try {
            return JSON.parse(atob(this.token.split('.')[1]));
        } catch (e) {
            return null;
        }
    }

    /**
     * Checks if the user is logged in.
     * @returns {Boolean} True if logged in, otherwise false.
     */
    isLoggedIn() {
        const payload = this.getLogin();

        if (!payload) {
            return false;
        }

        // new Date requires milliseconds
        return new Date(payload.exp * 1000) >= new Date();
    }

    /**
     * Removes the JWT. If the token is not available in the storage, the promise is rejected.
     * @returns {Promise}
     */
    unsetToken() {
        this.token = undefined;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.removeSecureStorageItem(KEY_TOKEN);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.removeLocalStorageItem(KEY_TOKEN);
        } else {
            return Promise.reject();
        }
    }

    loadLocale() {
        let promise;

        if (this.isMobile() && this.hasSecureStorage()) {
            promise = this.getSecureStorageItem(KEY_LOCALE);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            promise = this.getLocalStorageItem(KEY_LOCALE);
        } else {
            promise = Promise.resolve(getBrowserLocale());
        }

        return promise
            .then(locale => {
                // secureStorage or localStorage may return null
                if (!_.isNil(locale) && LOCALES.includes(locale)) {
                    this.locale = locale;
                } else {
                    this.locale = 'de';
                }
                console.log(`Storage: Set locale=${this.locale}`);
            })
            .catch(() => console.log('Unable to load locale: Fallback to browser default'));
    }

    setLocale(locale) {
        this.locale = locale;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.setSecureStorageItem(KEY_LOCALE, locale);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.setLocalStorageItem(KEY_LOCALE, locale);
        } else {
            return Promise.reject();
        }
    }

    unsetLocale() {
        this.locale = undefined;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.removeSecureStorageItem(KEY_LOCALE);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.removeLocalStorageItem(KEY_LOCALE);
        } else {
            return Promise.reject();
        }
    }

    getLocale() {
        return this.locale;
    }

    loadPreferPaymentTypeStripe() {
        let promise;

        if (this.isMobile() && this.hasSecureStorage()) {
            promise = this.getSecureStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            console.log('loading from localStorage');
            promise = this.getLocalStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE);
        } else {
            promise = Promise.resolve(true);
        }

        return promise
            .then(preferPaymentTypeStripe => {
                // secureStorage or localStorage may return null
                if (!_.isNil(preferPaymentTypeStripe)) {
                    this.preferPaymentTypeStripe = preferPaymentTypeStripe === 'true';
                } else {
                    this.preferPaymentTypeStripe = true;
                }
                console.log(`Storage: Set preferPaymentTypeStripe=${this.preferPaymentTypeStripe}`);
            })
            .catch(() => console.log('Unable to load prefer payment type stripe: Fallback to false'));
    }

    setPreferPaymentTypeStripe(value) {
        this.preferPaymentTypeStripe = value;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.setSecureStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE, `${value}`); // value must be a string
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.setLocalStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE, `${value}`); // value must be a string
        } else {
            return Promise.reject();
        }
    }

    unsetPreferPaymentTypeStripe() {
        this.preferPaymentTypeStripe = undefined;
        if (this.isMobile() && this.hasSecureStorage()) {
            return this.removeSecureStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE);
        } else if (this.isBrowser() && this.hasLocalStorage()) {
            return this.removeLocalStorageItem(KEY_PREFER_PAYMENT_TYPE_STRIPE);
        } else {
            return Promise.reject();
        }
    }

    getPreferPaymentTypeStripe() {
        return this.preferPaymentTypeStripe;
    }

}

export default new Storage();
