import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Typography, Grid } from '@material-ui/core';
import { Done as DoneIcon, Timer as TimerIcon } from '@material-ui/icons';

import PreorderIcon from 'components/PreorderIcon';
import OrderQueue from 'containers/OrderQueue';

import { PICK_UP_TYPE } from 'containers/ShoppingCart/constants';
import { PAYMENT_STATE } from 'containers/Orders/constants';

import messages from './messages';

import './styles.scss';

class OrderState extends React.Component {

    static renderTextBox(message) {
        return (
            <Grid container justify="center" spacing={0} className="page">
                <Grid item xs={12}>
                    <Typography variant="h5" align="center">
                        <FormattedMessage {...message} />
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    renderGridBox() {
        const {
            id,
            state,
            paymentState,
            pickUpNumber,
            pickUpType,
            pickUpCustomerAt,
        } = this.props;

        const pickUpNowStyle = pickUpType === PICK_UP_TYPE.now ? { marginBottom: '0.25rem' } : {};

        return (
            <Grid container justify="center" spacing={0} className="order-state">
                <Grid item xs={6}>
                    <Grid container justify="center" spacing={0} direction="column">
                        <Grid item xs={12} style={pickUpNowStyle}>
                            <Typography variant="caption" align="center">
                                {paymentState === PAYMENT_STATE.pending && <FormattedMessage {...messages.payment} />}
                                {pickUpType === PICK_UP_TYPE.now && state === 'OPEN' &&
                                    <FormattedMessage {...messages.ordersAhead} />
                                }
                                {pickUpType === PICK_UP_TYPE.preOrder && state === 'OPEN' &&
                                    <FormattedMessage {...messages.orderPickUpAt} />
                                }
                                {state === 'IN_PREPARATION' && <FormattedMessage {...messages.orderInPreparation} />}
                                {state === 'PREPARED' && <FormattedMessage {...messages.orderReady} />}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                            {paymentState === PAYMENT_STATE.pending &&
                                <Typography variant="h5" align="center">
                                    <FormattedMessage {...messages.pending} />
                                </Typography>
                            }
                            {pickUpType === PICK_UP_TYPE.now && state === 'OPEN' &&
                                <OrderQueue id={id} />
                            }
                            {pickUpType === PICK_UP_TYPE.preOrder && state === 'OPEN' &&
                                <Typography variant="h5" align="center">
                                    <PreorderIcon
                                        className="text-hint"
                                        style={{ verticalAlign: '-0.15em' }}
                                    />
                                    {' '}
                                    {moment(pickUpCustomerAt).format('HH:mm')}
                                </Typography>
                            }
                            {state === 'IN_PREPARATION' &&
                                <TimerIcon className="in-preparation-color" />
                            }
                            {state === 'PREPARED' &&
                                <DoneIcon className="pickup-ready-color" />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="column">
                        <Grid item xs={12}>
                            <Typography variant="caption" align="center">
                                <FormattedMessage {...messages.pickUpNumber} />
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                {pickUpNumber}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        const { state } = this.props;

        let gridContent;

        if (state === 'PICKED_UP') {
            gridContent = OrderState.renderTextBox(messages.orderPickedUp);
        } else if (state === 'CANCELED') {
            gridContent = OrderState.renderTextBox(messages.orderCanceled);
        } else if (state === 'ABORTED') {
            gridContent = OrderState.renderTextBox(messages.orderAborted);
        } else {
            gridContent = this.renderGridBox();
        }

        return (
            <div style={{ width: '100%' }} className="section">
                {gridContent}
            </div>
        );
    }

}

OrderState.propTypes = {
    paymentState: PropTypes.string,
    pickUpType: PropTypes.string.isRequired,
    pickUpCustomerAt: PropTypes.string,
    id: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    pickUpNumber: PropTypes.string.isRequired,
};

export default OrderState;
