export const CORDOVA_INIT_DONE = 'App/CORDOVA_INIT_DONE';

export const REQUEST_LOCATION_INFO = 'App/REQUEST_LOCATION_INFO';
export const UPDATE_LOCATION_INFO = 'App/UPDATE_LOCATION_INFO';
export const UNSET_LOCATION_INFO = 'App/UNSET_LOCATION_INFO';

export const UPDATE_CONNECTION_INFO = 'App/UPDATE_CONNECTION_INFO';

export const PUSH_NOTIFICATION_RECEIVE = 'App/PUSH_NOTIFICATON_RECEIVE';
export const PUSH_NOTIFICATION_CLEAR = 'App/PUSH_NOTIFICATON_CLEAR';

export const LOAD_MERCHANTS_REQUEST = 'App/LOAD_MERCHANTS_REQUEST';
export const LOAD_MERCHANTS_SUCCESS = 'App/LOAD_MERCHANTS_SUCCESS';
export const LOAD_MERCHANTS_ERROR = 'App/LOAD_MERCHANTS_ERROR';
export const UPDATE_MERCHANT = 'App/UPDATE_MERCHANT';

export const LOAD_MERCHANT_QUEUE_REQUEST = 'App/LOAD_MERCHANT_QUEUE_REQUEST';
export const LOAD_MERCHANT_QUEUE_SUCCESS = 'App/LOAD_MERCHANT_QUEUE_SUCCESS';
export const LOAD_MERCHANT_QUEUE_ERROR = 'App/LOAD_MERCHANT_QUEUE_ERROR';

export const LOAD_ORDER_QUEUE_REQUEST = 'App/LOAD_ORDER_QUEUE_REQUEST';
export const LOAD_ORDER_QUEUE_SUCCESS = 'App/LOAD_ORDER_QUEUE_SUCCESS';
export const LOAD_ORDER_QUEUE_ERROR = 'App/LOAD_ORDER_QUEUE_ERROR';

export const VERIFY_TOKEN_REQUEST = 'App/VERIFY_TOKEN_REQUEST';
export const VERIFY_TOKEN_SUCCESS = 'App/VERIFY_TOKEN_SUCCESS';
export const VERIFY_TOKEN_ERROR = 'App/VERIFY_TOKEN_ERROR';

export const LOAD_API_VERSION_REQUEST = 'App/LOAD_API_VERSION_REQUEST';
export const LOAD_API_VERSION_SUCCESS = 'App/LOAD_API_VERSION_SUCCESS';
export const LOAD_API_VERSION_ERROR = 'App/LOAD_API_VERSION_ERROR';

export const UNSET_CUSTOMER_DATA = 'App/UNSET_CUSTOMER_DATA';
export const FETCH_CUSTOMER_DATA_REQUEST = 'App/FETCH_CUSTOMER_DATA_REQUEST';
export const FETCH_CUSTOMER_DATA_SUCCESS = 'App/FETCH_CUSTOMER_DATA_SUCCESS';
export const FETCH_CUSTOMER_DATA_ERROR = 'App/FETCH_CUSTOMER_DATA_ERROR';

export const UPDATE_CUSTOMER_DATA_REQUEST = 'App/UPDATE_CUSTOMER_DATA_REQUEST';
export const UPDATE_CUSTOMER_DATA_SUCCESS = 'App/UPDATE_CUSTOMER_DATA_SUCCESS';
export const UPDATE_CUSTOMER_DATA_ERROR = 'App/UPDATE_CUSTOMER_DATA_ERROR';

export const SET_TERMS_OF_SERVICE_COMPATIBLE = 'App/SET_TERMS_OF_SERVICE_COMPATIBLE';

export const CHECK_TERMS_OF_SERVICE_REQUEST = 'App/CHECK_TERMS_OF_SERVICE_REQUEST';
export const CHECK_TERMS_OF_SERVICE_SUCCESS = 'App/CHECK_TERMS_OF_SERVICE_SUCCESS';
export const CHECK_TERMS_OF_SERVICE_ERROR = 'App/CHECK_TERMS_OF_SERVICE_ERROR';

export const AGREE_TERMS_OF_SERVICE_REQUEST = 'App/AGREE_TERMS_OF_SERVICE_REQUEST';
export const AGREE_TERMS_OF_SERVICE_SUCCESS = 'App/AGREE_TERMS_OF_SERVICE_SUCCESS';
export const AGREE_TERMS_OF_SERVICE_ERROR = 'App/AGREE_TERMS_OF_SERVICE_ERROR';

export const UPDATE_DEVICE_INFORMATION_REQUEST = 'App/UPDATE_DEVICE_INFORMATION_REQUEST';
export const UPDATE_DEVICE_INFORMATION_SUCCESS = 'App/UPDATE_DEVICE_INFORMATION_SUCCESS';
export const UPDATE_DEVICE_INFORMATION_ERROR = 'App/UPDATE_DEVICE_INFORMATION_ERROR';
