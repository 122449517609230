import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import {
    Button,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    Receipt as ReceiptIcon,
    DateRange as CalendarIcon,
    CreditCard as CreditCardIcon,
} from '@material-ui/icons';

import { ORDER_PAYMENT_TYPES } from 'utils';

import MenuBar from 'containers/MenuBar';
import LocaleDate from 'containers/LocaleDate';

import ConfirmDialog from 'components/ConfirmDialog';
import ProductTable from 'components/ProductTable';
import OrderState from 'components/OrderState';
import MerchantSummary from 'components/MerchantSummary';
import FormattedSupportId from 'components/FormattedSupportId';
import CancelationReasons from 'components/CancelationReasons';
import OwnPackagingIcon from 'components/OwnPackagingIcon';
import OrderStateText from 'containers/OrderStateText';

import globalMessages from 'containers/App/messages';
import { loadOrderQueue } from 'containers/App/actions';
import {
    loadOrderDetails,
    cancelOrder,
} from './actions';
import messages from './messages';
import { STRIPE_API_PUBLIC_KEY } from '../../config';

import './styles.scss';

const ORDER_STATES_LOCK_CANCEL = ['IN_PREPARATION', 'PREPARED', 'PICKED_UP', 'CANCELED', 'ABORTED'];
const ORDER_STATES_HIDE_CANCEL_MESSAGE = ['CANCELED'];

class OrderDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmCancel: false,
            checkoutResult: null, // Stripe checkout result
        };
    }

    static getDerivedStateFromProps(nextProps) {
        const params = new URLSearchParams(nextProps.history.location.search);
        const checkoutResult = params.get('result');
        return {
            checkoutResult,
        };
    }

    componentDidMount() {
        this.loadData();
        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentDidUpdate(prevProps, prevState) {
        // Stripe checkout result has been set as URL paramter -> reload order
        if (prevState.checkoutResult !== this.state.checkoutResult) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        this.props.loadOrderQueue();
        this.props.loadOrderDetails(this.props.match.params.id);
    }

    handleBack() {
        const {
            dispatch,
            location,
            order,
        } = this.props;

        if (order) {
            const { _id: merchantId } = order.merchant;
            // after ordering, go back to merchant
            const { from } = location.state || { from: { pathname: `/products/${merchantId}` } };
            from.state = { from: location, ...from.state };
            dispatch(push(from));
        } else {
            // order not loaded (for example not found)
            dispatch(push('/orders'));
        }
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    handleCancel(orderId) {
        this.setState(() => ({
            openConfirmCancel: false,
        }));

        // cancel order and reload order details
        this.props.cancelOrder(orderId);
    }

    handleResumePayment() {
        const { order, merchants } = this.props;

        // eslint-disable-next-line no-undef
        const stripe = Stripe(STRIPE_API_PUBLIC_KEY, {
            stripeAccount: merchants[order.merchant._id].stripe.id,
        });

        stripe.redirectToCheckout({
            sessionId: order.stripe.sessionId,
        }).then(result => {
            console.log('Stripe payment failed:', result.error.message);
        });
    }

    renderCancelArea(lockCancel, hideCancelMessage) {
        if (lockCancel && !hideCancelMessage) {
            return (
                <div
                    className="section"
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                >
                    <div className="page">
                        <Typography variant="body1">
                            <FormattedMessage {...messages.cancelNotPossible} />
                        </Typography>
                    </div>
                </div>
            );
        } else if (!lockCancel) {
            return (
                <Button
                    fullWidth
                    color="secondary"
                    onClick={() => this.setState({ openConfirmCancel: true })}
                    className="cancel-button"
                >
                    <FormattedMessage {...messages.cancelOrder} />
                </Button>
            );
        } else {
            return null;
        }
    }

    render() {
        const {
            match,
            order,
            merchants,
            locale,
        } = this.props;
        const { openConfirmCancel } = this.state;
        const { id: orderId } = match.params;

        let content;

        if (!order) {
            content = <div />;
        } else {
            const currentMerchant = merchants ? merchants[order.merchant._id] : null;
            const lockCancel = ORDER_STATES_LOCK_CANCEL.includes(order.state);
            const hideCancelMessage = ORDER_STATES_HIDE_CANCEL_MESSAGE.includes(order.state);

            content = (
                <div>
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <ReceiptIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...messages.orderNumber} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" align="right">
                                <FormattedSupportId value={order.supportId} />
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <CalendarIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...messages.orderDate} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <LocaleDate isoDate={order.createdAt} variant="body2" />
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={6} className="icon-label-wrapper">
                            <CreditCardIcon className="text-hint" />
                            <Typography variant="body2" align="left" className="text-hint">
                                <FormattedMessage {...globalMessages.paymentType} />
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="body2" align="right">
                                        <FormattedMessage {...globalMessages[ORDER_PAYMENT_TYPES[order.paymentType]]} />
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    {order.ownPackaging &&
                        <React.Fragment>
                            <Grid container justify="center" spacing={0} className="page">
                                <Grid item xs={12} className="icon-label-wrapper">
                                    <OwnPackagingIcon className="text-hint" />
                                    <Typography variant="body2" align="left" className="text-hint">
                                        <FormattedMessage {...globalMessages.ownPackaging} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </React.Fragment>
                    }
                    <OrderState
                        paymentState={order.paymentState}
                        pickUpType={order.pickUpType}
                        pickUpCustomerAt={order.pickUpCustomerAt}
                        state={order.state}
                        id={order._id}
                        pickUpNumber={order.pickUpNumber}
                    />
                    {order.state === 'PENDING'
                        && order.paymentState === 'PENDING'
                        && order.stripe
                        && order.stripe.checkoutResult !== 'SUCCESS' &&
                        <Grid container justify="center" spacing={0}>
                            <Grid item xs={12} className="stripe-resume-button-container">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => this.handleResumePayment()}
                                >
                                    <FormattedMessage {...messages.resumePayment} />
                                </Button>
                            </Grid>
                        </Grid>
                    }
                    {order.state === 'CANCELED' &&
                        <CancelationReasons
                            reasons={order.canceledReasons}
                            comment={order.canceledComment}
                        />
                    }
                    <OrderStateText
                        id={order._id}
                        state={order.state}
                        updatedAt={order.updatedAt}
                        pickedUpAt={order.pickedUpAt}
                        canceledAt={order.canceledAt}
                    />
                    <ProductTable products={order.products} note={order.note} locale={locale} />
                    <Divider />
                    {
                        // check if the merchant data is available
                        // for a disabled or hidden merchant no merchant data is available
                        currentMerchant
                            ? <MerchantSummary merchant={currentMerchant} />
                            : (
                                <div className="merchant-summary">
                                    <Typography variant="h6" className="page">
                                        {order.merchant.name}
                                    </Typography>
                                </div>
                            )
                    }
                    <Divider />
                    {this.renderCancelArea(lockCancel, hideCancelMessage)}
                </div>
            );
        }

        return (
            <div className="order-details-page">
                <MenuBar
                    titleMessage={messages.title}
                    onBackClick={() => this.handleBack()}
                    onRefreshClick={e => this.handleRefresh(e)}
                />

                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmCancel: false })}
                    open={openConfirmCancel}
                    titleMessage={messages.confirmCancelTitle}
                    contentMessage={messages.confirmCancelContent}
                    confirmMessage={messages.cancelOrder}
                    onCancelClick={() => this.setState({ openConfirmCancel: false })}
                    onConfirmClick={() => this.handleCancel(orderId)}
                />

                <div className="page-content">
                    {content}
                </div>
            </div>
        );

    }

}

OrderDetails.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loadOrderDetails: PropTypes.func,
    loadOrderQueue: PropTypes.func,
    cancelOrder: PropTypes.func,
    order: PropTypes.object,
    merchants: PropTypes.object,
    dispatch: PropTypes.func,
    locale: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        merchants: state.global.merchants,
        order: state.orderDetails.order,
        locale: state.languageProviderReducer.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        loadOrderDetails: id => dispatch(loadOrderDetails(id)),
        loadOrderQueue: () => dispatch(loadOrderQueue()),
        cancelOrder: id => dispatch(cancelOrder(id)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrderDetails));
