/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Redirect } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    Grid,
    Button,
    Checkbox,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    CircularProgress,
} from '@material-ui/core';

import MenuBar from 'containers/MenuBar';
import RegistrationForm from 'components/RegistrationForm';
import TermsOfServiceRef from 'components/TermsOfServiceRef';
import {
    register as registerAction,
    clearRegistration as clearRegistrationAction,
} from './actions';
import messages from './messages';

class Registration extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            areaCode: '43',
            phoneNumber: props.phoneNumber,
            firstName: props.firstName,
            lastName: props.lastName,
            password: '',
            terms: false,
            termsError: false,
        };
    }

    onChangePhoneNumber(phoneNumber) {
        this.setState({ phoneNumber });
    }

    onChangeFirstName(firstName) {
        this.setState({ firstName });
    }

    onChangeLastName(lastName) {
        this.setState({ lastName });
    }

    onChangePassword(password) {
        this.setState({ password });
    }

    onChangeTerms(event, checked) {
        this.setStateTerms(checked);
    }

    onBackClick() {
        const {
            clearRegistration,
            dispatch,
        } = this.props;

        clearRegistration();
        dispatch(push('/login'));
    }

    setStateTerms(val) {
        this.setState({
            terms: val,
            termsError: !val,
        });

        return val;
    }

    doRegistration() {
        const validForm = this.form.validate();
        const validTerms = this.setStateTerms(this.state.terms);

        if (validForm && validTerms) {
            this.props.register(
                this.state.areaCode,
                this.state.phoneNumber,
                this.state.firstName,
                this.state.lastName,
                this.state.password
            );
        }
    }

    render() {
        if (this.props.loggedIn) {
            return <Redirect to="/merchants" />;
        }

        return (
            <div className="registration-page logistration-page">
                <MenuBar titleMessage={messages.title} onBackClick={() => this.onBackClick()} />
                <div className="page-content">
                    <Grid container justify="center" spacing={0} className="page">
                        <Grid item xs={12} sm={6}>
                            <div className="registration-card logistration-card">
                                <div className="logistration-form">
                                    <RegistrationForm
                                        ref={form => { this.form = form; }}
                                        firstName={this.state.firstName}
                                        lastName={this.state.lastName}
                                        phoneNumber={this.state.phoneNumber}
                                        onChangeFirstName={p => this.onChangeFirstName(p)}
                                        onChangeLastName={p => this.onChangeLastName(p)}
                                        onChangePhoneNumber={p => this.onChangePhoneNumber(p)}
                                        onChangePassword={p => this.onChangePassword(p)}
                                    />
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.terms}
                                                    onChange={(...args) => this.onChangeTerms(...args)}
                                                    value="terms"
                                                />
                                            }
                                            label={
                                                <FormattedMessage
                                                    {...messages.acceptTermsOfService}
                                                    values={{
                                                        termsOfService: <TermsOfServiceRef />,
                                                    }}
                                                />
                                            }
                                        />
                                        {this.state.termsError &&
                                            <FormHelperText error>
                                                <FormattedMessage {...messages.termsOfServiceRequired} />
                                            </FormHelperText>
                                        }
                                    </FormGroup>
                                </div>
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={this.props.loading}
                                        onClick={() => this.doRegistration()}
                                    >
                                        {this.props.loading && (
                                            <React.Fragment>
                                                <CircularProgress color="inherit" size="1rem" />
                                                &nbsp;
                                            </React.Fragment>
                                        )}
                                        <FormattedMessage {...messages.register} />
                                    </Button>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        );
    }

}

Registration.propTypes = {
    dispatch: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    loading: PropTypes.bool,
    register: PropTypes.func.isRequired,
    clearRegistration: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.loginReducer.loggedIn,
        firstName: state.registrationReducer.firstName,
        lastName: state.registrationReducer.lastName,
        phoneNumber: state.registrationReducer.phoneNumber,
        loading: state.registrationReducer.loading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        // eslint-disable-next-line max-params
        register: (areaCode, phoneNumber, firstName, lastName, password) => dispatch(
            registerAction(areaCode, phoneNumber, firstName, lastName, password)
        ),
        clearRegistration: () => dispatch(clearRegistrationAction()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
