import Storage from 'services/Storage';
import { API_URI } from 'config';

import { showSnackbar } from 'containers/Snackbar/actions';
import { loadOrderQueue } from 'containers/App/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import { loadMerchantDetails } from 'containers/Products/actions';

import messages from 'containers/App/messages';

import {
    LOAD_ORDER_DETAILS_REQUEST,
    LOAD_ORDER_DETAILS_SUCCESS,
    LOAD_ORDER_DETAILS_ERROR,
    CANCEL_ORDER_REQUEST,
    CANCEL_ORDER_SUCCESS,
    CANCEL_ORDER_ERROR,
} from './constants';

export function loadOrderDetailsRequest() {
    return {
        type: LOAD_ORDER_DETAILS_REQUEST,
    };
}

export function loadOrderDetailsSuccess(response) {
    return {
        type: LOAD_ORDER_DETAILS_SUCCESS,
        response,
    };
}

export function loadOrderDetailsError(error) {
    return {
        type: LOAD_ORDER_DETAILS_ERROR,
        error,
    };
}

export function loadOrderDetails(id) {
    return dispatch => {
        dispatch(loadOrderDetailsRequest());
        dispatch(openLoadingOverlay());

        const url = new URL(`/api/customer/order/${id}`, API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadOrderDetailsSuccess(json));
                dispatch(loadMerchantDetails(json.merchant._id, false));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadOrderDetailsError(error));
                dispatch(showSnackbar(messages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}

export function cancelOrderRequest() {
    return {
        type: CANCEL_ORDER_REQUEST,
    };
}

export function cancelOrderSuccess() {
    return {
        type: CANCEL_ORDER_SUCCESS,
    };
}

export function cancelOrderError(error) {
    return {
        type: CANCEL_ORDER_ERROR,
        error,
    };
}

export function cancelOrder(id) {
    return dispatch => {
        dispatch(cancelOrderRequest());

        const url = new URL(`/api/customer/order/${id}`, API_URI).toString();

        fetch(url, {
            method: 'PATCH',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response;
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(() => {
                dispatch(cancelOrderSuccess());
                dispatch(showSnackbar(messages.canceled));
                // reload order details after cancelation
                dispatch(loadOrderQueue());
                dispatch(loadOrderDetails(id));
            })
            .catch(error => {
                dispatch(cancelOrderError(error));
                if (error.message.toLowerCase() === 'order not cancelable') {
                    dispatch(showSnackbar(messages.errorCancel));
                } else {
                    dispatch(showSnackbar(messages.errorLoading));
                }
            });
    };
}
