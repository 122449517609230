import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    List,
    ListItem,
    Grid,
} from '@material-ui/core';
import SwipeableViews from '@rise/react-swipeable-views';

import MenuBar from 'containers/MenuBar';
import LocaleDate from 'containers/LocaleDate';

import FormattedSupportId from 'components/FormattedSupportId';
import FormattedAmount from 'components/FormattedAmount';
import OrderState from 'components/OrderState/index';
import { loadOrderQueue } from 'containers/App/actions';
import OrderStateText from 'containers/OrderStateText';
import Scroll from 'containers/Scroll';

import { PAYMENT_STATE } from 'containers/Orders/constants';
import { loadOrders } from './actions';

import globalMessages from '../App/messages';
import messages from './messages';

import './styles.scss';

const ORDER_STATES_OPEN = ['PENDING', 'OPEN', 'IN_PREPARATION', 'PREPARED'];
const ORDER_STATES_CLOSED = ['PICKED_UP', 'CANCELED', 'ABORTED'];

class Orders extends React.Component { // eslint-disable-line

    constructor(props) {
        super(props);

        const { tab } = props.location.state || { tab: 0 };
        this.state = {
            selectedTab: tab,
        };
    }

    componentDidMount() {
        this.loadData();
        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        this.props.loadOrders();
        this.props.loadOrderQueue();
    }

    handleRefresh(e) {
        this.loadData();
        e.preventDefault();
    }

    handleTabChange(e, tab) {
        this.setState(() => ({
            selectedTab: tab,
        }));
        e.preventDefault();
    }

    handleTabChangeIndex(index) {
        this.setState(() => ({
            selectedTab: index,
        }));
    }

    renderTabContent(orders, locale, renderOrderState) { // eslint-disable-line class-methods-use-this
        const isOpen = order => ORDER_STATES_OPEN.includes(order.state);
        const isCanceled = order => order.state === 'CANCELED';
        const isAborted = order => order.state === 'ABORTED';

        if (!orders.length) {
            return (
                <ListItem>
                    <Typography><FormattedMessage {...messages.noOrdersAvailable} /></Typography>
                </ListItem>
            );
        } else {
            return orders.map(order => {
                const to = {
                    pathname: `/orders/${order._id}`,
                    state: {
                        from: {
                            ...this.props.location,
                            state: { tab: this.state.selectedTab },
                        },
                    },
                };

                return (
                    <ListItem
                        key={order._id}
                        className="order-item"
                        disableGutters
                    >
                        <Link
                            to={to}
                            className="order-item-link"
                        >
                            <Grid container spacing={0} justify="space-between" className="order-item-info">
                                <Grid item xs={6}>
                                    <Typography variant="caption">
                                        <FormattedSupportId value={order.supportId} />
                                        {isCanceled(order) &&
                                            <span className="canceled">
                                                &nbsp;(<FormattedMessage {...messages.canceled} />)
                                            </span>
                                        }
                                        {isAborted(order) &&
                                            <span className="canceled">
                                                &nbsp;(<FormattedMessage {...messages.aborted} />)
                                            </span>
                                        }
                                    </Typography>
                                    <Typography variant="body2">{order.merchant.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <LocaleDate isoDate={order.createdAt} variant="caption" />
                                    <Typography variant="body2" align="right">
                                        <FormattedAmount
                                            value={order.price}
                                            locale={locale}
                                        />&nbsp;€
                                    </Typography>
                                </Grid>
                            </Grid>
                            {isOpen(order) && renderOrderState &&
                                <OrderState
                                    paymentState={order.paymentState}
                                    pickUpType={order.pickUpType}
                                    pickUpCustomerAt={order.pickUpCustomerAt}
                                    state={order.state}
                                    id={order._id}
                                    pickUpNumber={order.pickUpNumber}
                                />
                            }
                            {isOpen(order) && renderOrderState && order.paymentState !== PAYMENT_STATE.pending &&
                                <OrderStateText id={order._id} state={order.state} />
                            }
                        </Link>
                    </ListItem>
                );
            });
        }
    }

    render() {
        const { orders, locale } = this.props;
        const { selectedTab, redirectToOrder, selectedOrder } = this.state;

        let content;

        if (redirectToOrder) {
            content = <Redirect to={`/orders/${selectedOrder}`} />;
        } else if (!orders) {
            content = <div />;
        } else {
            const today = new Date();
            const isSameDay = (d1, d2) => d1.getFullYear() === d2.getFullYear()
                && d1.getMonth() === d2.getMonth()
                && d1.getDate() === d2.getDate();

            // orders with state OPEN / IN_PREPARATION / PREPARED and from today
            // sorted ascending (first order on top)
            const openOrders = orders
                .filter(order => ORDER_STATES_OPEN.includes(order.state)
                    && isSameDay(today, new Date(order.createdAt)))
                .sort((o1, o2) => new Date(o1.createdAt) - new Date(o2.createdAt));

            // orders with state PICKED_UP / CANCELD or not from today
            // sorted descending (latest order on top)
            const previousOrders = orders
                .filter(order => ORDER_STATES_CLOSED.includes(order.state)
                    || !isSameDay(today, new Date(order.createdAt)))
                .sort((o1, o2) => new Date(o2.createdAt) - new Date(o1.createdAt));

            content = (
                <div>
                    <AppBar position="sticky" className="sticky-app-bar">
                        <Tabs
                            value={selectedTab}
                            onChange={(e, tab) => this.handleTabChange(e, tab)}
                            variant="fullWidth"
                        >
                            <Tab
                                className="tab-open-orders"
                                label={<FormattedMessage {...messages.openOrders} />}
                            />
                            <Tab
                                className="tab-prev-orders"
                                label={<FormattedMessage {...messages.previousOrders} />}
                            />
                        </Tabs>
                    </AppBar>
                    <SwipeableViews
                        index={selectedTab}
                        onChangeIndex={idx => this.handleTabChangeIndex(idx)}
                        adjustHeight
                    >
                        <List>
                            {this.renderTabContent(openOrders, locale, true)}
                        </List>
                        <List>
                            {this.renderTabContent(previousOrders, locale, false)}
                        </List>
                    </SwipeableViews>
                </div>
            );
        }

        return (
            <div className="orders-page">
                <MenuBar titleMessage={globalMessages.orders} onRefreshClick={e => this.handleRefresh(e)} />
                <Scroll sid="orders" className="page-content">
                    {content}
                </Scroll>
            </div>
        );
    }

}

Orders.propTypes = {
    loadOrders: PropTypes.func,
    loadOrderQueue: PropTypes.func,
    orders: PropTypes.array,
    location: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        orders: state.orders.orders,
        locale: state.languageProviderReducer.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadOrders: () => dispatch(loadOrders()),
        loadOrderQueue: () => dispatch(loadOrderQueue()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Orders));
