/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar as MaterialSnackbar, SnackbarContent, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { hideSnackbar } from './actions';

class Snackbar extends React.Component {

    handleClose() {
        this.props.dispatch(hideSnackbar());
    }

    render() {
        return (
            <div
                onClick={() => this.props.dispatch(hideSnackbar())}
                onKeyPress={() => this.props.dispatch(hideSnackbar())}
                style={{ cursor: 'pointer' }}
                role="button"
                tabIndex={0}
            >
                <MaterialSnackbar
                    className="snackbar"
                    open={this.props.open}
                    onClose={() => this.handleClose()}
                    autoHideDuration={5000}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <SnackbarContent
                        message={
                            <span id="message-id">
                                {this.props.message ? <FormattedMessage {...this.props.message} /> : ''}
                            </span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={() => this.handleClose()}
                            >
                                <CloseIcon />
                            </IconButton>,
                        ]}
                    />
                </MaterialSnackbar>
            </div>
        );
    }

}

Snackbar.propTypes = {
    dispatch: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    message: PropTypes.object,
};

/**
 * Called every time the redux store is updated.
 * @param state
 * @return {{loading: (*|boolean), error, products: (*|boolean)}}
 */
function mapStateToProps(state) {
    return {
        open: state.snackbarReducer.open,
        message: state.snackbarReducer.message,
    };
}

/**
 * Maps the redux dispatch function to a props.
 * @param dispatch
 * @return {{dispatch: *}}
 */
function mapDispatchToProps(dispatch) {
    return {
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
