import { defineMessages } from 'react-intl';

const messages = defineMessages({
    phoneNumber: {
        id: 'InputPhoneNumber.phoneNumber',
        defaultMessage: 'Enter phone number',
    },
    phoneNumberRequired: {
        id: 'InputPhoneNumber.phoneNumberRequired',
        defaultMessage: 'Phone number required',
    },
    phoneNumberInvalid: {
        id: 'InputPhoneNumber.phoneNumberInvalid',
        defaultMessage: 'Phone number must have 10 to 15 digits',
    },
});

export default messages;
