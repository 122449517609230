import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import {
    Box,
    Button,
    IconButton,
    Typography,
} from '@material-ui/core';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { loadMerchantDetails as loadMerchantDetailsAction } from 'containers/Products/actions';
import { showSnackbar } from 'containers/Snackbar/actions';
import { setProduct } from 'containers/ShoppingCart/actions';
import MenuBar from 'containers/MenuBar';
import HoverCart from 'components/HoverCart';
import FormattedAmount from 'components/FormattedAmount';
import ProductOptionTable from 'components/ProductOptionTable';
import globalMessages from 'containers/App/messages';
import messages from './messages';

import './styles.scss';

class ProductOptions extends React.Component { // eslint-disable-line
    constructor(props) {
        super(props);

        const { quantity, options, shoppingCartId } = props.location.state
            || { quantity: 0, options: undefined, shoppingCartId: undefined };
        this.state = {
            quantity,
            options,
            shoppingCartId,
            update: !!quantity,
        };
        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentDidMount() {
        const from = this.props.location.state && this.props.location.state.from;

        if (!from || !from.pathname.includes('/products/')) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.products && !state.quantity) {
            const { product_id: productId } = props.match.params;
            const product = props.products.find(p => p._id === productId);
            const initSelectedOptions = product.optionGroups.reduce((arr, pogId) => {
                const optionGroup = props.productOptionGroups.find(pog => pog._id === pogId);
                if (!optionGroup.multipleChoice) {
                    const options = props.productOptions.filter(o => o.optionGroup === pogId);
                    if (options.length > 0) {
                        arr.push({
                            _id: options[0]._id,
                            name: options[0].name,
                            price: options[0].priceAdditional,
                            group: options[0].optionGroup,
                            _version: options[0]._version,
                        });
                    }
                }
                return arr;
            }, []);
            return { quantity: 1, options: initSelectedOptions };
        }
        return null;
    }

    loadData() {
        this.props.loadMerchantDetails(this.props.match.params.id);
    }

    isRequiredOptionsSet(product) {
        const requiredGroups = this.props.productOptionGroups.filter(
            allProductOptionGroups => product.optionGroups.includes(allProductOptionGroups._id)
                && !allProductOptionGroups.multipleChoice
        );
        const requiredGroupsNotSet = requiredGroups.filter(
            requiredGroup => !(this.state.options.findIndex(option => option.group === requiredGroup._id) !== -1)
        );
        return requiredGroupsNotSet.length > 0;
    }

    handleBackClick() {
        const {
            location,
            dispatch,
            match,
        } = this.props;

        const { id: merchantId, product_id: productId } = match.params;
        const { from } = location.state || { from: { pathname: `/products/${merchantId}` } };
        from.state = { from: { pathname: `/product-options/${merchantId}/${productId}` }, ...from.state };
        dispatch(push(from));
    }

    handleIncrementClick() {
        const {
            match,
            merchants,
            totalCount,
            shoppingCartProducts,
        } = this.props;
        const {
            quantity,
            shoppingCartId,
            update,
        } = this.state;
        const { id: merchantId } = match.params;

        const max = merchants[merchantId].maxShoppingCartProducts || Infinity;
        if (!(totalCount - (update ? shoppingCartProducts[shoppingCartId].quantity : 0) + quantity >= max)) {
            this.setState(prevState => ({ ...prevState, quantity: prevState.quantity + 1 }));
        } else {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max },
            });
        }
    }

    handleDecrementClick() {
        if (this.state.quantity > 1) {
            this.setState(prevState => ({ ...prevState, quantity: prevState.quantity - 1 }));
        }
    }

    handleProductOptionChange(options, group, optionGroupIds) {
        const selectedOptions = this.state.options.filter(option => option.group !== group);
        selectedOptions.splice(0, 0, ...options);
        selectedOptions.sort((a, b) => optionGroupIds.indexOf(a.group) - optionGroupIds.indexOf(b.group));
        this.setState(prevState => ({ ...prevState, options: selectedOptions }));
    }

    handleAddProductClick(product) {
        const {
            match,
            merchants,
            totalCount,
            shoppingCartProducts,
            location,
        } = this.props;
        const {
            options,
            quantity,
            shoppingCartId,
            update,
        } = this.state;
        const { id: merchantId, product_id: productId } = match.params;

        const max = merchants[merchantId].maxShoppingCartProducts || Infinity;
        if (!(totalCount - (update ? shoppingCartProducts[shoppingCartId].quantity : 0) + quantity > max)) {
            this.props.setProduct({
                merchantId,
                product: { ...product, options },
                quantity,
                max,
                replaceId: shoppingCartId,
            });
            const { from } = location.state || { from: { pathname: `/products/${merchantId}` } };
            from.state = { from: { pathname: `/product-options/${merchantId}/${productId}` }, ...from.state };
            this.props.dispatch(push(from));
        } else {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max },
            });
        }
    }

    render() {
        const {
            match,
            products,
            merchants,
            productOptionGroups,
            productOptions,
            hasShoppingCart,
            locale,
        } = this.props;
        const { product_id: productId } = match.params;
        const { quantity, options, update } = this.state;

        let content;

        if (!products || !merchants) {
            content = <div />;
        } else {
            const product = products.find(p => p._id === productId);
            const fontWeightBold = 600;
            content = (
                <form noValidate autoComplete="off">
                    <div className="product-option-product">
                        <div className="product-option-product-line">
                            <div className="product-option-product-minus">
                                <IconButton color="primary" onClick={() => this.handleDecrementClick()}>
                                    <RemoveIcon />
                                </IconButton>
                            </div>
                            <div className="product-option-product-quantity" align="center">
                                <Typography variant="body2" component="span" className="narrow-line">
                                    <Box fontWeight={fontWeightBold}>{quantity}</Box>
                                </Typography>
                            </div>
                            <div className="product-option-product-plus">
                                <IconButton color="primary" onClick={() => this.handleIncrementClick()}>
                                    <AddIcon />
                                </IconButton>
                            </div>
                            <div className="product-option-product-name">
                                <Typography
                                    variant="body2"
                                    component="span"
                                    className="product-option-product-title narrow-line"
                                >
                                    <Box fontWeight={fontWeightBold}>{product.name}</Box>
                                </Typography>
                            </div>
                            <div className="product-option-product-price">
                                <Typography variant="body2" component="span" className="narrow-line">
                                    <Box fontWeight={fontWeightBold}>
                                        <FormattedAmount
                                            value={(quantity * product.price)}
                                            locale={locale}
                                        />&nbsp;€
                                    </Box>
                                </Typography>
                            </div>
                        </div>
                        <div className="product-option-product-description">
                            <Typography variant="caption" display="block" className="text-faded">
                                {product.description}
                            </Typography>
                        </div>
                        <div className="product-option-product-allergens">
                            <Typography variant="caption" display="block" className="text-faded">
                                {product.allergens ? product.allergens.sort().join(', ') : ''}
                            </Typography>
                        </div>
                    </div>
                    <div className="product-option-groups">
                        <ProductOptionTable
                            allSelectedOptions={options}
                            quantity={quantity}
                            productOptionGroupIds={product.optionGroups}
                            allOptionGroups={productOptionGroups}
                            allOptions={productOptions}
                            locale={locale}
                            onChange={(o, g) => this.handleProductOptionChange(o, g, product.optionGroups)}
                        />
                    </div>
                    <div className="product-option-button-wrapper">
                        <Button
                            fullWidth
                            variant="contained"
                            className="product-option-button"
                            disabled={this.isRequiredOptionsSet(product)}
                            onClick={() => this.handleAddProductClick(product)}
                        >
                            <div>
                                {update
                                    ? <FormattedMessage {...messages.updateProduct} />
                                    : <FormattedMessage {...messages.addProduct} />
                                }
                            </div>
                            <div className="product-option-total-price">
                                <FormattedAmount
                                    value={(quantity * (product.price + options.reduce((a, b) => a + b.price, 0)))}
                                    locale={locale}
                                />&nbsp;€
                            </div>
                        </Button>
                    </div>
                </form>
            );
        }

        return (
            <div className={`product-options-page ${hasShoppingCart && !update ? 'has-shopping-cart' : ''}`}>
                <MenuBar
                    titleMessage={update ? messages.editOptions : messages.chooseOptions}
                    onBackClick={() => this.handleBackClick()}
                />
                <div className="page-content">
                    {content}
                </div>
                {update ? '' : <HoverCart />}
            </div>
        );
    }

}

ProductOptions.propTypes = {
    location: PropTypes.object.isRequired,
    products: PropTypes.array,
    productOptionGroups: PropTypes.array,
    productOptions: PropTypes.array,
    merchants: PropTypes.object,
    loadMerchantDetails: PropTypes.func,
    showSnackbar: PropTypes.func,
    match: PropTypes.object,
    setProduct: PropTypes.func,
    cartMerchantId: PropTypes.string,
    hasShoppingCart: PropTypes.bool,
    dispatch: PropTypes.func,
    totalCount: PropTypes.number,
    shoppingCartProducts: PropTypes.object,
    locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        quantity: state.quantity,
        products: state.productsReducer.products,
        productOptionGroups: state.productsReducer.productOptionGroups,
        productOptions: state.productsReducer.productOptions,
        cartMerchantId: state.shoppingCart.merchantId,
        merchants: state.global.merchants,
        hasShoppingCart: state.shoppingCart.hover,
        totalCount: state.shoppingCart.totalCount,
        shoppingCartProducts: state.shoppingCart.products,
        locale: state.languageProviderReducer.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadMerchantDetails: id => dispatch(loadMerchantDetailsAction(id)),
        showSnackbar: msg => dispatch(showSnackbar(msg)),
        setProduct: ({
            merchantId,
            product,
            quantity,
            max,
            replaceId,
        }) => dispatch(setProduct({
            merchantId,
            product,
            quantity,
            max,
            replaceId,
        })),
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductOptions);
