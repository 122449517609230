import _ from 'lodash';
import moment from 'moment';

export function normalize(array, key) {
    return Object.assign({}, ...array.map(item => ({ [item[key]]: item })));
}

export const DAY_ENUM = {
    0: 'SUN',
    1: 'MON',
    2: 'TUE',
    3: 'WED',
    4: 'THU',
    5: 'FRI',
    6: 'SAT',
};

// sorting of days should start with monday until sunday
export const DAY_ENUM_KEYS_SORTED = [1, 2, 3, 4, 5, 6, 0];

export const DAY_MESSAGE_ID = {
    0: 'sunday',
    1: 'monday',
    2: 'tuesday',
    3: 'wednesday',
    4: 'thursday',
    5: 'friday',
    6: 'saturday',
};

// Currently some payment methods are hardcoded to avoid major database and backend changes.
// The database currently only stores 'on-site' methods.
// Because the payment methods received from the backend are (mostly) in German use it here too.
export const PAYMENT_METHODS = {
    STRIPE: ['Kreditkarte'],
};

export const ORDER_PAYMENT_TYPES = {
    ON_SITE: 'onSite',
    STRIPE: 'online',
};

/*
 * HELPER FUNCTIONS
 */

/**
 * Get the minutes since midnight for the given time.
 * Examples:
 * "00:00" returns 0
 * "01:30" returns 90
 * "24:00" returns 1440
 * @param {String|Date|null} time Time string or Date object, or leave empty to use current time.
 * @returns {Number} Minutes since midnight.
 */
function getDayMinutes(time = null) {
    let d;
    if (typeof time === 'string') {
        if (time === '24:00') {
            // 24:00 -> next day 00:00
            d = new Date('01/02/70 00:00');
        } else {
            d = new Date(`01/01/70 ${time}`);
        }
    } else if (time instanceof Date) {
        d = time;
    } else {
        const now = new Date();
        d = new Date(`01/01/70 ${now.getHours()}:${now.getMinutes()}`);
    }

    return (d.getDate() === 2 ? 60 * 24 : 0) + (d.getHours() * 60) + d.getMinutes();
}

/**
 * Get the opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (= sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {Array} List of opening hours
 */
export function getOpeningHoursForDay(day, openingHours) {
    const hours = openingHours.find(e => e.day === DAY_ENUM[day]);
    if (!hours) {
        return [];
    } else {
        return hours.hours;
    }
}

/**
 * Get all opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {Array} List of opening hours
 */
export function getOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getOpeningHoursForDay(today, openingHours);
}

/**
 * Get the next opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (=sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Next opening hours, or null if no more opening hours for the day.
 */
export function getNextOpeningHoursForDay(day, openingHours) {
    const hours = getOpeningHoursForDay(day, openingHours);

    return _.chain(hours)
        .filter(fromTo => getDayMinutes(fromTo.from) > getDayMinutes())
        .sortBy('from')
        .head()
        .value();
}

/**
 * Get the next opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Next opening hours, or null if no more opening hours for today.
 */
export function getNextOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getNextOpeningHoursForDay(today, openingHours);
}

/**
 * Get the current opening hours for the given day of the week.
 * @param {Number} day Weekday, beginning with 0 (=sunday)
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Current opening hours, or null if no opening hours for the day.
 */
export function getCurrentOpeningHoursForDay(day, openingHours) {
    const hours = getOpeningHoursForDay(day, openingHours);

    return _.chain(hours)
        .filter(fromTo => getDayMinutes(fromTo.from) <= getDayMinutes())
        .filter(fromTo => getDayMinutes(fromTo.to) >= getDayMinutes())
        .head()
        .value();
}

/**
 * Get the current opening hours for today.
 * @param {Array} openingHours List of all opening hours of the merchant
 * @returns {{from: String, to: String}|null} Current opening hours, or null if no opening hours for today.
 */
export function getCurrentOpeningHoursForToday(openingHours) {
    const today = new Date().getDay();
    return getCurrentOpeningHoursForDay(today, openingHours);
}

/**
 * Generate a time series with given step interval and start/end as limits
 * Times are rounded to the nearest step.
 * @param {number} step step interval in minutes
 * @param {string} start start date
 * @param {string} end end date
 * @param {string} [locale] locale to convert times to. UTC if undefined/null
 * @returns {[{iso: string, short: string}]} All steps between start and end.
 */
export function generateTimeSeries(step, start, end, locale = null) {
    const series = [];
    const startDate = new Date(start);
    const endDate = new Date(end);

    startDate.setMilliseconds(0);
    startDate.setSeconds(0);
    startDate.setMinutes(Math.floor(startDate.getMinutes() / step) * step);

    endDate.setMilliseconds(0);
    endDate.setSeconds(0);
    endDate.setMinutes(Math.floor(endDate.getMinutes() / step) * step);

    while (startDate < endDate) {
        startDate.setMinutes(startDate.getMinutes() + step);

        let seriesEntry = startDate.toISOString();
        if (locale) {
            seriesEntry = moment(seriesEntry).locale(locale).format();
        }

        series.push({
            iso: seriesEntry,
            short: `${moment(seriesEntry).format('HH')}:${moment(seriesEntry).format('mm')}`,
        });
    }

    return series;
}

/**
 * Checks if the given merchant is now open, and allowed to send orders.
 * @param {object} merchant The merchant object
 * @return {boolean} True if open, otherwise false.
 */
export function isOpen(merchant) {
    const { available, openingHours } = merchant;

    if (available === 'CLOSED') {
        return false;
    } else if (available === 'MANUAL') {
        return true;
    } else {
        // availabel === 'OPENING_HOURS'
        const hours = getOpeningHoursForToday(openingHours);

        const actual = _.chain(hours)
            .filter(fromTo => getDayMinutes(fromTo.from) <= getDayMinutes())
            .filter(fromTo => getDayMinutes(fromTo.to) > getDayMinutes())
            .head()
            .value();

        return !_.isNil(actual);
    }
}

export function formatAddress(address) {
    return `
        ${address.street},
        ${address.postcode}
        ${address.city}`;
}

export function formatFromTo(from, to) {
    return `${from} - ${to}`;
}

export function formatHours(hours) {
    return hours.map(h => formatFromTo(h.from, h.to)).join(', ');
}

export function debounce(func, wait, immediate, ...args) {
    let timeout;
    return () => {
        const context = this;
        const later = () => {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

export function calculateDistance(coords1, coords2) {
    // Haversine formula
    const earthRadius = 6371e3; // meter
    const lat1 = degreesToRadians(coords1.lat);
    const lat2 = degreesToRadians(coords2.lat);
    const deltaLng = degreesToRadians(coords1.lng - coords2.lng);
    const deltaLat = degreesToRadians(coords1.lat - coords2.lat);

    const a = (Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2)) +
        (Math.cos(lat1) * Math.cos(lat2) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2));
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = earthRadius * c;
    if (distance > 999) {
        return {
            distance: (distance / 1000).toFixed(2),
            unit: 'km',
        };
    } else {
        return {
            distance: Math.round(distance),
            unit: 'm',
        };
    }
}

/**
 * Removes leading zero, non-numbers and white spaces
 * @param {string} phoneNumber Phone number
 */
export function sanitizePhoneNumber(phoneNumber) {
    return phoneNumber
        .replace(/\s/g, '')
        .replace(/\D/g, '')
        .replace(/^0+/, '');
}
