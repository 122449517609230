
/*
 * ACTIONS
 */

export const PASSWORD_RESET_REQUEST = 'PasswordReset/PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PasswordReset/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PasswordReset/PASSWORD_RESET_ERROR';

export const SET_PHONE_NUMBER = 'PasswordReset/SET_PHONE_NUMBER';
