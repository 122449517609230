/* eslint-disable max-len */
import { defineMessages } from 'react-intl';

const messages = defineMessages({
    shoppingCart: {
        id: 'ShoppingCart.shoppingCart',
        defaultMessage: 'Shopping Cart',
    },
    delete: {
        id: 'ShoppingCart.delete',
        defaultMessage: 'Delete',
    },
    confirmDeleteTitle: {
        id: 'ShoppingCart.confirmDeleteTitle',
        defaultMessage: 'Delete Shopping Cart?',
    },
    confirmDeleteContent: {
        id: 'ShoppingCart.confirmDeleteContent',
        defaultMessage: 'Do you really want to delete the shopping cart?',
    },
    submit: {
        id: 'ShoppingCart.submit',
        defaultMessage: 'Submit',
    },
    submitOrder: {
        id: 'ShoppingCart.submitOrder',
        defaultMessage: 'Submit Order',
    },
    confirmSubmitTitle: {
        id: 'ShoppingCart.confirmSubmitTitle',
        defaultMessage: 'Submit Shopping Cart?',
    },
    confirmSubmitContent: {
        id: 'ShoppingCart.confirmSubmitContent',
        defaultMessage: 'Do you really want to submit your current shopping cart?',
    },
    orderError: {
        id: 'ShoppingCart.orderError',
        defaultMessage: 'Error sending order',
    },
    orderErrorMerchantInvalid: {
        id: 'ShoppingCart.orderErrorMerchantInvalid',
        defaultMessage: 'Order can not be submitted because merchant is not available anymore',
    },
    orderErrorMerchantClosed: {
        id: 'ShoppingCart.orderErrorMerchantClosed',
        defaultMessage: 'Order can not be submitted because merchant is closed',
    },
    orderErrorCustomerInvalid: {
        id: 'ShoppingCart.orderErrorCustomerInvalid',
        defaultMessage: 'Order can not be submitted because your account is disabled',
    },
    orderErrorCustomerBlocked: {
        id: 'ShoppingCart.orderErrorCustomerBlocked',
        defaultMessage: 'Order can not be submitted because your account is blocked by the restaurant',
    },
    orderErrorProductConflict: {
        id: 'ShoppingCart.orderErrorProductConflict',
        defaultMessage: 'Order can not be submitted because at least one product in your shopping cart has ' +
            'been updated',
    },
    orderErrorProductNotAvailable: {
        id: 'ShoppingCart.orderErrorProductNotAvailable',
        defaultMessage: 'Order can not be submitted because at least one product in your shopping cart is ' +
            'currently not available',
    },
    orderErrorMaxOrdersPerDay: {
        id: 'ShoppingCart.orderErrorMaxOrdersPerDay',
        defaultMessage: 'Order can not be submitted because you have reached your maximum amount of orders per day',
    },
    orderErrorMaxShoppingCartProducts: {
        id: 'ShoppingCart.orderErrorMaxShoppingCartProducts',
        defaultMessage: 'Order can not be submitted because you have too many products in your shopping cart',
    },
    orderErrorTermsOfServiceOutdated: {
        id: 'ShoppingCart.orderErrorTermsOfServiceOutdated',
        defaultMessage: 'Order can not be submitted because the general terms of service and privacy policy have changed',
    },
    orderErrorPendingOrders: {
        id: 'ShoppingCart.orderErrorPendingOrders',
        defaultMessage: 'Order can not be submitted because payments are pending',
    },
    orderErrorTotalAmountTooSmall: {
        id: 'ShoppingCart.orderErrorTotalAmountTooSmall',
        defaultMessage: 'The total amount is too small for payment type on site',
    },
    orderErrorStripeCheckoutRedirect: {
        id: 'ShoppingCart.orderErrorStripeCheckoutRedirect',
        defaultMessage: 'Can not redirect to Stripe checkout',
    },
    orderErrorProductOptionsInvalid: {
        id: 'ShoppingCart.orderErrorProductOptionsInvalid',
        defaultMessage: 'The currently selected product options are invalid',
    },
    emptyHeader: {
        id: 'ShoppingCart.emptyHeader',
        defaultMessage: 'Empty shopping cart',
    },
    emptyBody: {
        id: 'ShoppingCart.emptyBody',
        defaultMessage: 'You haven\'t added anything to your shopping cart. Discover more meals in our restaurants.',
    },
    emptyLink: {
        id: 'ShoppingCart.emptyLink',
        defaultMessage: 'Go to restaurants',
    },
    comments: {
        id: 'ShoppingCart.comments',
        defaultMessage: 'Special requests and comments',
    },

});

export default messages;
