import { defineMessages } from 'react-intl';

const messages = defineMessages({
    openOrders: {
        id: 'Orders.openOrders',
        defaultMessage: 'Open orders',
    },
    previousOrders: {
        id: 'Orders.previousOrders',
        defaultMessage: 'Previous orders',
    },
    noOrdersAvailable: {
        id: 'Orders.noOrdersAvailable',
        defaultMessage: 'No orders available',
    },
    canceled: {
        id: 'Orders.canceled',
        defaultMessage: 'Canceled',
    },
    aborted: {
        id: 'Orders.aborted',
        defaultMessage: 'Aborted',
    },
});

export default messages;
