import { push } from 'connected-react-router';

import { API_URI } from 'config';
import { sanitizePhoneNumber } from 'utils';

import { showSnackbar } from 'containers/Snackbar/actions';
import { setLogin } from 'containers/Login/actions';
import { clearRegistration } from 'containers/Registration/actions';

import {
    ACTIVATION_REQUEST,
    ACTIVATION_SUCCESS,
    ACTIVATION_ERROR,
} from './constants';
import messages from './messages';
import { getDeviceWithPushToken } from '../../device';

/*
 * ACTION CREATORS
 */

function activationRequest() {
    return {
        type: ACTIVATION_REQUEST,
    };
}

function activationSuccess(result) {
    return {
        type: ACTIVATION_SUCCESS,
        result,
    };
}

function activationError(error) {
    return {
        type: ACTIVATION_ERROR,
        error,
    };
}

/*
 * DISPATCHERS
 */

export function activate(areaCode, phoneNumber, activationCode) {
    return async dispatch => {
        dispatch(activationRequest());

        const device = await getDeviceWithPushToken();
        const phoneNumberWithAreaCode = `${areaCode}${sanitizePhoneNumber(phoneNumber)}`;
        const url = new URL('/api/public/customer/register', API_URI).toString();
        const payload = {
            phoneNumber: phoneNumberWithAreaCode,
            activationCode,
            device,
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(activationSuccess());
                dispatch(clearRegistration());
                dispatch(showSnackbar(messages.activationSucess));
                dispatch(setLogin(json.token));

                // navigate to login page, which does the redirect back to origin page
                dispatch(push('/login'));
            })
            .catch(error => {
                dispatch(activationError(error));
                dispatch(showSnackbar(messages.activationError));
            });
    };
}
