import { defineMessages } from 'react-intl';

const messages = defineMessages({
    restaurants: {
        id: 'App.restaurants',
        defaultMessage: 'Restaurants',
    },
    errorLoading: {
        id: 'App.errorLoading',
        defaultMessage: 'Error loading data',
    },
    errorCancel: {
        id: 'App.errorCancel',
        defaultMessage: 'The order cannot be canceled.',
    },
    login: {
        id: 'App.login',
        defaultMessage: 'Login',
    },
    orders: {
        id: 'App.orders',
        defaultMessage: 'Orders',
    },
    confirm: {
        id: 'App.confirm',
        defaultMessage: 'Confirm',
    },
    monday: {
        id: 'App.monday',
        defaultMessage: 'Monday',
    },
    tuesday: {
        id: 'App.tuesday',
        defaultMessage: 'Tuesday',
    },
    wednesday: {
        id: 'App.wednesday',
        defaultMessage: 'Wednesday',
    },
    thursday: {
        id: 'App.thursday',
        defaultMessage: 'Thursday',
    },
    friday: {
        id: 'App.friday',
        defaultMessage: 'Friday',
    },
    saturday: {
        id: 'App.saturday',
        defaultMessage: 'Saturday',
    },
    sunday: {
        id: 'App.sunday',
        defaultMessage: 'Sunday',
    },
    closed: {
        id: 'App.closed',
        defaultMessage: 'Closed',
    },
    closedToday: {
        id: 'App.closedToday',
        defaultMessage: 'Today closed',
    },
    closedTemporarily: {
        id: 'App.closedTemporarily',
        defaultMessage: 'Temporarily closed',
    },
    closedCurrently: {
        id: 'App.closedCurrently',
        defaultMessage: 'Currently closed',
    },
    opensAt: {
        id: 'App.opensAt',
        defaultMessage: 'Opens at',
    },
    openNow: {
        id: 'App.openNow',
        defaultMessage: 'Currently open',
    },
    nextInLine: {
        id: 'App.nextInLine',
        defaultMessage: 'You are next in line!',
    },
    inPreparation: {
        id: 'App.inPreparation',
        defaultMessage: 'Your order is in preparation',
    },
    prepared: {
        id: 'App.prepared',
        defaultMessage: 'Your order is ready to be picked up',
    },
    pickedUp: {
        id: 'App.pickedUp',
        defaultMessage: 'Your order has been picked up.',
    },
    pickedUpWithTime: {
        id: 'App.pickedUpWithTime',
        defaultMessage: 'Your order has been picked up at {date} {time}.',
    },
    canceled: {
        id: 'App.canceled',
        defaultMessage: 'Your order has been canceled.',
    },
    canceledWithTime: {
        id: 'App.canceledWithTime',
        defaultMessage: 'Your order has been canceled at {date} {time}.',
    },
    ordersQueue: {
        id: 'App.ordersQueue',
        defaultMessage: 'Orders ahead of you',
    },
    tooManyProducts: {
        id: 'App.tooManyProducts',
        defaultMessage: 'You can only order a maximum of {max} products.',
    },
    about: {
        id: 'App.about',
        defaultMessage: 'About',
    },
    invalidToken: {
        id: 'App.invalidToken',
        defaultMessage: 'You have been logged out.',
    },
    pickUpType: {
        id: 'App.pickUpType',
        defaultMessage: 'Pick up',
    },
    asap: {
        id: 'App.asap',
        defaultMessage: 'As soon as possible',
    },
    ownPackaging: {
        id: 'App.ownPackaging',
        defaultMessage: 'Own packaging',
    },
    bringOwnPackaging: {
        id: 'App.bringOwnPackaging',
        defaultMessage: 'I will bring my own packaging',
    },
    preOrder: {
        id: 'App.preOrder',
        defaultMessage: 'Pre-order',
    },
    paymentType: {
        id: 'App.paymentType',
        defaultMessage: 'Payment type',
    },
    onSite: {
        id: 'App.onSite',
        defaultMessage: 'On Site',
    },
    online: {
        id: 'App.online',
        defaultMessage: 'Online',
    },
    stripe: {
        id: 'App.stripe',
        defaultMessage: 'Online',
    },
    paymentMethods: {
        id: 'App.paymentMethods',
        defaultMessage: 'Payment methods',
    },
    totalAmount: {
        id: 'App.totalAmount',
        defaultMessage: 'Total',
    },
    comments: {
        id: 'App.comments',
        defaultMessage: 'Special requests and comments',
    },
    profileUpdateSuccess: {
        id: 'App.profileUpdateSuccess',
        defaultMessage: 'Profile successfully updated.',
    },
    profileUpdateError: {
        id: 'App.profileUpdateError',
        defaultMessage: 'Profile cannot be updated. Please refresh the page.',
    },
    promoCodesUpdateError: {
        id: 'App.promoCodesUpdateError',
        defaultMessage: 'Promo Code cannot be updated. Please refresh the page.',
    },
    promoCodesUpdateErrorInvalid: {
        id: 'App.promoCodesUpdateErrorInvalid',
        defaultMessage: 'Promo Code is invalid',
    },
});

export default messages;
