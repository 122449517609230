import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';

import CartContentWrapper from './CartContentWrapper';

class HoverCart extends React.Component { // eslint-disable-line react/prefer-stateless-function

    render() {
        const {
            hover,
            products,
            location,
            locale,
        } = this.props;
        let size = 0;
        let value = 0;
        Object.keys(products).forEach(id => {
            size += products[id].quantity;
            value += (products[id].quantity *
                (
                    products[id].product.price +
                    (products[id].product.options
                        ? products[id].product.options.reduce((a, b) => a + b.price, 0)
                        : 0)
                )
            );
        });

        return (
            <Snackbar
                className="hover-cart-wrapper"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={hover}
                onClose={this.handleClose}
            >
                <CartContentWrapper
                    classes={{
                        root: 'hover-cart',
                    }}
                    size={size}
                    value={value}
                    from={location.pathname}
                    locale={locale}
                />
            </Snackbar>
        );
    }

}

HoverCart.propTypes = {
    location: PropTypes.object.isRequired,
    hover: PropTypes.bool,
    products: PropTypes.object,
    locale: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,
        products: state.shoppingCart.products,
        hover: state.shoppingCart.hover,
        locale: state.languageProviderReducer.locale,
    };
}

export default connect(mapStateToProps)(HoverCart);
