
/*
 * ACTIONS
 */

export const REGISTRATION_REQUEST = 'Registration/REGISTRATION_REQUEST';
export const REGISTRATION_SUCCESS = 'Registration/REGISTRATION_SUCCESS';
export const REGISTRATION_ERROR = 'Registration/REGISTRATION_ERROR';

export const CLEAR_REGISTRATION = 'Registration/CLEAR_REGISTRATION';
