import { push } from 'connected-react-router';

import Storage from 'services/Storage';
import {
    setTermsOfServiceCompatible,
    loadMerchantQueue,
} from 'containers/App/actions';
import { showSnackbar } from 'containers/Snackbar/actions';
import { loadMerchantDetails } from 'containers/Products/actions';

import {
    SET_PRODUCT,
    CLEAR_CART,
    SET_COMMENT,
    SET_PICK_UP,
    SET_PAYMENT_TYPE,
    SUBMIT_ORDER_REQUEST,
    SUBMIT_ORDER_SUCCESS,
    SUBMIT_ORDER_ERROR,
    SET_PRODUCT_VALIDITY,
} from './constants';
import messages from './messages';
import { API_URI, STRIPE_API_PUBLIC_KEY } from '../../config';

export function setProduct({
    merchantId,
    product,
    quantity,
    max,
    replaceId,
}) {
    return {
        type: SET_PRODUCT,
        merchantId,
        product,
        quantity,
        max,
        replaceId,
    };
}

export function setProductValidity(productId, isValid) {
    return {
        type: SET_PRODUCT_VALIDITY,
        productId,
        isValid,
    };
}

export function clearCart() {
    return {
        type: CLEAR_CART,
    };
}

export function setComment(text) {
    return {
        type: SET_COMMENT,
        text,
    };
}

export function setPickUp(pickUpType, pickUpCustomerAt) {
    return {
        type: SET_PICK_UP,
        pickUpType,
        pickUpCustomerAt,
    };
}

export function setPaymentType(paymentType) {
    return {
        type: SET_PAYMENT_TYPE,
        paymentType,
    };
}

export function submitOrderRequest() {
    return {
        type: SUBMIT_ORDER_REQUEST,
    };
}

export function submitOrderSuccess(response) {
    return {
        type: SUBMIT_ORDER_SUCCESS,
        response,
    };
}

export function submitOrderError(error) {
    return {
        type: SUBMIT_ORDER_ERROR,
        error,
    };
}

export function submitOrder({
    merchantId,
    merchantStripeId,
    products,
    note,
    pickUpType,
    pickUpCustomerAt,
    ownPackagingEnabled,
    ownPackaging,
    paymentType,
}) {
    return dispatch => {
        dispatch(submitOrderRequest());

        const url = new URL('/api/customer/order', API_URI).toString();

        const body = {
            merchantId,
            products,
            note,
            pickUpType,
            paymentType,
            platform: (window.cordova?.platformId ?? '').toUpperCase(),
        };

        if (ownPackagingEnabled) {
            body.ownPackaging = ownPackaging;
        }

        if (pickUpCustomerAt) {
            body.pickUpCustomerAt = pickUpCustomerAt;
        }

        fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${Storage.getToken()}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                if (paymentType === 'STRIPE' && json.stripeSessionId) {
                    // eslint-disable-next-line no-undef
                    const stripe = Stripe(STRIPE_API_PUBLIC_KEY, {
                        stripeAccount: merchantStripeId,
                    });

                    stripe.redirectToCheckout({
                        sessionId: json.stripeSessionId,
                    }).then(result => {
                        // Android and iOS will always report an error for a failed redirect
                        // because the redirect is not working in the WebView
                        if (window.cordova.platformId === 'browser') {
                            dispatch(showSnackbar(messages.orderErrorStripeCheckoutRedirect));
                            dispatch(submitOrderError(result.error.message));
                            console.log('Stripe payment failed:', result.error.message);
                        } else {
                            // clear shopping cart for iOS and Android
                            dispatch(submitOrderSuccess(json));
                            dispatch(push(`/orders/${json._id}`));
                        }
                    });

                } else {
                    dispatch(submitOrderSuccess(json));
                    dispatch(loadMerchantQueue(true));

                    // open order details
                    dispatch(push(`/orders/${json._id}`));
                }
            })
            .catch(error => {
                if (error.message.toLowerCase() === 'merchant closed') {
                    // merchant is closed
                    dispatch(showSnackbar(messages.orderErrorMerchantClosed));
                } else if (error.message.toLowerCase() === 'merchant invalid') {
                    // merchant invalid
                    dispatch(showSnackbar(messages.orderErrorMerchantInvalid));
                } else if (error.message.toLowerCase() === 'customer invalid') {
                    // customer invalid
                    dispatch(showSnackbar(messages.orderErrorCustomerInvalid));
                } else if (error.message.toLowerCase() === 'customer blocked') {
                    // customer blocked
                    dispatch(showSnackbar(messages.orderErrorCustomerBlocked));
                } else if (error.message.toLowerCase() === 'product options invalid') {
                    // product options invalid, e.g. option has been deleted while still selected.
                    dispatch(showSnackbar(messages.orderErrorProductOptionsInvalid));
                } else if (error.message.toLowerCase() === 'products invalid') {
                    dispatch(loadMerchantDetails(merchantId));
                    if (error.data.unavailable.length > 0) {
                        // product(s) currently not available
                        dispatch(showSnackbar(messages.orderErrorProductNotAvailable));

                        error.data.unavailable.forEach(unavailable => {
                            let id = `${unavailable._id}`;
                            if (unavailable.options) {
                                id = `${id}/${unavailable.options.map(option => option).join('/')}`;
                            }
                            dispatch(setProductValidity(id, false));
                        });
                    }
                    if (error.data.disabled.length > 0) {
                        // product(s) updated
                        dispatch(showSnackbar(messages.orderErrorProductNotAvailable));

                        error.data.disabled.forEach(disabled => {
                            let id = `${disabled._id}`;
                            if (disabled.options) {
                                id = `${id}/${disabled.options.map(option => option).join('/')}`;
                            }
                            dispatch(setProductValidity(id, false));
                        });
                    }
                    if (error.data.conflict.length > 0) {
                        // product(s) updated
                        dispatch(showSnackbar(messages.orderErrorProductConflict));

                        error.data.conflict.forEach(conflict => {
                            let id = `${conflict._id}`;
                            if (conflict.options) {
                                id = `${id}/${conflict.options.map(option => option).join('/')}`;
                            }
                            dispatch(setProductValidity(id, false));
                        });
                    }
                } else if (error.message.toLowerCase() === 'product options invalid') {
                    dispatch(loadMerchantDetails(merchantId));
                    if (error.data.disabled.length > 0) {
                        // product option(s) updated
                        dispatch(showSnackbar(messages.orderErrorProductNotAvailable));
                    }
                    if (error.data.conflict.length > 0) {
                        // product option(s) updated
                        dispatch(showSnackbar(messages.orderErrorProductConflict));
                    }
                } else if (error.message.toLowerCase() === 'max orders per day reached') {
                    // customer reached maximum orders per day
                    dispatch(showSnackbar(messages.orderErrorMaxOrdersPerDay));
                } else if (error.message.toLowerCase() === 'max shopping cart product exceeded') {
                    // order exceeds max shopping cart product count
                    dispatch(showSnackbar(messages.orderErrorMaxShoppingCartProducts));
                } else if (error.message.toLowerCase() === 'customer terms of service out of date') {
                    dispatch(showSnackbar(messages.orderErrorTermsOfServiceOutdated));
                    dispatch(setTermsOfServiceCompatible(false));
                } else if (error.message.toLowerCase() === 'orders with pending payment found') {
                    dispatch(showSnackbar(messages.orderErrorPendingOrders));
                } else if (error.message.toLowerCase() === 'total amount too small') {
                    dispatch(showSnackbar(messages.orderErrorTotalAmountTooSmall));
                } else {
                    // other error
                    dispatch(showSnackbar(messages.orderError));
                }

                dispatch(submitOrderError(error));
            });
    };
}
