import React from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

function formatPhoneNumber(value) {
    try {
        // for example "+43 664 1234567"
        return `+${value.substr(0, 2)} ${value.substr(2, 3)} ${value.substr(5)}`;
    } catch (e) {
        return value;
    }
}

function FormattedPhoneNumber(props) {
    return (
        <span className="phone-number">{formatPhoneNumber(props.value)}</span>
    );
}

FormattedPhoneNumber.propTypes = {
    value: PropTypes.string.isRequired,
};

export default FormattedPhoneNumber;
