import { defineMessages } from 'react-intl';

const messages = defineMessages({
    password: {
        id: 'InputPassword.password',
        defaultMessage: 'Enter password',
    },
    passwordRequired: {
        id: 'InputPassword.passwordRequired',
        defaultMessage: 'Password required',
    },
    passwordInvalid: {
        id: 'InputPassword.passwordInvalid',
        defaultMessage: 'Password must have at least 8 characters',
    },
});

export default messages;
