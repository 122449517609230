/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Badge,
} from '@material-ui/core';
import {
    AccountCircle as AccountCircleIcon,
    Settings as SettingsIcon,
    Restaurant as RestaurantIcon,
    ExitToApp as ExitToAppIcon,
    History as HistoryIcon,
    ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';

import FormattedPhoneNumber from 'components/FormattedPhoneNumber';
import ConfirmDialog from 'components/ConfirmDialog';
import globalMessages from 'containers/App/messages';

import { logout as logoutAction } from 'containers/Login/actions';
import { loadMerchants as loadMerchantsAction } from 'containers/App/actions';
import { resetAllContainerPositions as resetAllContainerPositionsAction } from 'containers/Scroll/actions';

import {
    openMenuDrawer as openMenuDrawerAction,
    closeMenuDrawer as closeMenuDrawerAction,
} from './action';

import messages from './messages';

/*
 * webpack.DefinePlugin will inject the variable '__BUILD_TARGET__'.
 * To change it, the app needs to be recompiled.
 */
const buildTarget = __BUILD_TARGET__; // eslint-disable-line no-undef

class MenuDrawer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            openConfirmLogout: false,
        };
    }

    openDrawer(open) {
        if (open) {
            this.props.openMenuDrawer();
        } else {
            this.props.closeMenuDrawer();
        }
    }

    handleLogoutClick() {
        this.props.logout();
    }

    calcOpenOrderQueueLength() {
        const { orderQueue } = this.props;

        if (!orderQueue) {
            return 0;
        }

        return Object.keys(orderQueue).filter(idx => (
            orderQueue[idx].state === 'PENDING' ||
            orderQueue[idx].state === 'OPEN' ||
            orderQueue[idx].state === 'IN_PREPARATION' ||
            orderQueue[idx].state === 'PREPARED'
        )).length;
    }

    render() {
        const {
            loggedIn,
            phoneNumber,
            firstName,
            lastName,
            drawerOpen,
            resetAllContainerPositions,
        } = this.props;
        const { openConfirmLogout } = this.state;

        const orderQueueLength = this.calcOpenOrderQueueLength();
        let orderListItemText = <FormattedMessage {...globalMessages.orders} />;

        if (loggedIn && orderQueueLength) {
            // show badge if we are logged in and have open orders
            orderListItemText = (
                <Badge className="order-list-item-badge" color="primary" badgeContent={orderQueueLength}>
                    <FormattedMessage {...globalMessages.orders} />
                </Badge>
            );
        }

        return (
            <div>
                <ConfirmDialog
                    onClose={() => this.setState({ openConfirmLogout: false })}
                    open={openConfirmLogout}
                    titleMessage={messages.confirmLogoutTitle}
                    contentMessage={messages.confirmLogoutContent}
                    confirmMessage={messages.logout}
                    onCancelClick={() => this.setState({ openConfirmLogout: false })}
                    onConfirmClick={() => this.handleLogoutClick()}
                />

                <Drawer open={drawerOpen} onClose={() => this.openDrawer(false)} className="drawer">
                    <div
                        tabIndex={0}
                        role="button"
                        className="drawer-content"
                        onClick={() => this.openDrawer(false)}
                        onKeyPress={() => this.openDrawer(false)}
                    >
                        <div className="list">
                            <List>
                                {!loggedIn &&
                                    <ListItem button component={Link} to="/login">
                                        <ListItemIcon>
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<FormattedMessage {...globalMessages.login} />} />
                                    </ListItem>
                                }
                                {loggedIn &&
                                    <ListItem button component={Link} to="/profile">
                                        <ListItemIcon>
                                            <AccountCircleIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${firstName} ${lastName}`}
                                            secondary={<FormattedPhoneNumber value={phoneNumber} />}
                                        />
                                    </ListItem>
                                }
                            </List>
                            <Divider />
                            <List>
                                <ListItem
                                    button
                                    component={Link}
                                    to="/merchants"
                                    onClick={resetAllContainerPositions}
                                >
                                    <ListItemIcon>
                                        <RestaurantIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<FormattedMessage {...globalMessages.restaurants} />} />
                                </ListItem>
                                <ListItem
                                    button
                                    disabled={!loggedIn}
                                    component={Link}
                                    to="/orders"
                                    onClick={resetAllContainerPositions}
                                >
                                    <ListItemIcon>
                                        <HistoryIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={orderListItemText} />
                                </ListItem>
                                {buildTarget === 'debug' &&
                                    <ListItem button component={Link} to="/settings">
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<FormattedMessage {...messages.settings} />} />
                                    </ListItem>
                                }
                                <ListItem button component={Link} to="/about">
                                    <ListItemIcon>
                                        <ErrorOutlineIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={<FormattedMessage {...globalMessages.about} />} />
                                </ListItem>
                            </List>
                            {loggedIn &&
                                <Divider />
                            }
                            {loggedIn &&
                                <List>
                                    <ListItem button onClick={() => this.setState({ openConfirmLogout: true })}>
                                        <ListItemIcon>
                                            <ExitToAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={<FormattedMessage {...messages.logout} />} />
                                    </ListItem>
                                </List>
                            }
                        </div>
                    </div>
                </Drawer>
            </div>
        );
    }

}

MenuDrawer.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    drawerOpen: PropTypes.bool.isRequired,
    phoneNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    orderQueue: PropTypes.object,
    logout: PropTypes.func.isRequired,
    loadMerchants: PropTypes.func,
    openMenuDrawer: PropTypes.func.isRequired,
    closeMenuDrawer: PropTypes.func.isRequired,
    resetAllContainerPositions: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        loggedIn: state.loginReducer.loggedIn,
        firstName: (state.global.customer && state.global.customer.firstName) || '',
        lastName: (state.global.customer && state.global.customer.lastName) || '',
        phoneNumber: (state.loginReducer.result && state.loginReducer.result.phoneNumber) || '',
        drawerOpen: state.menuDrawerReducer.drawerOpen,
        orderQueue: state.global.orderQueue,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(logoutAction()),
        loadMerchants: () => dispatch(loadMerchantsAction(false)),
        openMenuDrawer: () => dispatch(openMenuDrawerAction()),
        closeMenuDrawer: () => dispatch(closeMenuDrawerAction()),
        resetAllContainerPositions: () => dispatch(resetAllContainerPositionsAction()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuDrawer);
