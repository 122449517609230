import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGIN_REDIRECT,
    LOGOUT_SUCCESS,
} from './constants';

const initialState = {
    loading: false,
    loggedIn: false,
    result: null,
    error: null,
    // the path from where the user was redirected to the login page
    path: '/merchants',
};

/*
 * REDUCERS
 */

function loginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                loggedIn: false,
                result: null,
                error: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: true,
                result: action.result,
                error: null,
            };
        case LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                result: null,
                error: action.error,
            };
        case LOGIN_REDIRECT:
            return {
                ...state,
                path: action.path,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedIn: false,
                result: null,
                error: null,
            };
        default:
            return state;
    }
}

export default loginReducer;
