import {
    LOAD_ORDERS_REQUEST,
    LOAD_ORDERS_SUCCESS,
    LOAD_ORDERS_ERROR,
} from './constants';

const initialState = {
    loading: false,
    orders: null,
    error: null,
};

function ordersReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOAD_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: action.result,
                error: null,
            };
        case LOAD_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                orders: null,
                error: action.error,
            };
        default:
            return state;
    }
}

export default ordersReducer;
