import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import ErrorScreen from 'components/ErrorScreen';

import messages from './messages';

class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        console.info(info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorScreen
                    classProp="error-screen"
                    header={() => <FormattedMessage {...messages.header} />}
                    body={() => <FormattedMessage {...messages.body} />}
                    render={() => (
                        <Button
                            variant="outlined"
                            onClick={() => {
                                this.props.history.push('/');
                                window.location.reload(true);
                            }}
                        >
                            <FormattedMessage {...messages.reload} />
                        </Button>
                    )}
                />
            );
        } else {
            return this.props.children;
        }
    }

}

ErrorBoundary.propTypes = {
    children: PropTypes.any.isRequired,
    history: PropTypes.any.isRequired,
};

export default withRouter(ErrorBoundary);
