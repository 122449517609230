import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function Title(props) {
    return (
        <Typography variant="h6" color="inherit" className="title">
            {props.text}
        </Typography>
    );
}

Title.propTypes = {
    text: PropTypes.string,
};

export default Title;
