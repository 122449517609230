import Storage from 'services/Storage';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    openLoadingOverlay,
    closeLoadingOverlay,
} from 'containers/LoadingOverlay/actions';
import messages from 'containers/App/messages';

import {
    LOAD_ORDERS_REQUEST,
    LOAD_ORDERS_SUCCESS,
    LOAD_ORDERS_ERROR,
} from './constants';
import { API_URI } from '../../config';

export function loadOrdersRequest() {
    return {
        type: LOAD_ORDERS_REQUEST,
    };
}

export function loadOrdersSuccess(result) {
    return {
        type: LOAD_ORDERS_SUCCESS,
        result,
    };
}

export function loadOrdersError(error) {
    return {
        type: LOAD_ORDERS_ERROR,
        error,
    };
}

export function loadOrders() {
    return dispatch => {
        dispatch(loadOrdersRequest());
        dispatch(openLoadingOverlay());

        const url = new URL('/api/customer/order', API_URI).toString();

        fetch(url, {
            method: 'GET',
            headers: { Authorization: `Bearer ${Storage.getToken()}` },
        })
            .then(response => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json()
                        .then(json => Promise.reject(json));
                }
            })
            .then(json => {
                dispatch(loadOrdersSuccess(json));
                dispatch(closeLoadingOverlay());
            })
            .catch(error => {
                dispatch(loadOrdersError(error));
                dispatch(showSnackbar(messages.errorLoading));
                dispatch(closeLoadingOverlay());
            });
    };
}
