import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, SnackbarContent } from '@material-ui/core';
import { ShoppingCart as ShoppingCartIcon } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

import BoxLink from 'components/BoxLink';
import FormattedAmount from 'components/FormattedAmount';
import messages from './messages';

function CartContentWrapper({
    size,
    value,
    from,
    locale,
    ...other
}) {
    return (
        <SnackbarContent
            className="hover-cart-message"
            message={
                <BoxLink
                    className="hover-cart-content"
                    style={{ textDecoration: 'none' }}
                    to={{ pathname: '/cart', state: { from } }}
                >
                    <Grid container spacing={0} alignItems="center">
                        <Grid item xs={1} className="hover-cart-icon">
                            <ShoppingCartIcon />
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" className="light-text">
                                        <FormattedMessage {...messages.yourShoppingCart} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2" className="light-text">
                                        {size} {size === 1
                                            ? <FormattedMessage {...messages.product} />
                                            : <FormattedMessage {...messages.products} />
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography variant="subtitle1" align="right" className="light-text">
                                <FormattedAmount
                                    value={value}
                                    locale={locale}
                                />&nbsp;€
                            </Typography>
                        </Grid>
                    </Grid>
                </BoxLink>
            }
            {...other}
        />
    );
}

CartContentWrapper.propTypes = {
    size: PropTypes.number,
    value: PropTypes.number,
    from: PropTypes.string,
    locale: PropTypes.string.isRequired,
};

export default CartContentWrapper;
