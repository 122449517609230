import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import messages from 'containers/App/messages';

import './styles.scss';
import { API_URI } from '../../config';

function MerchantLogo({ id, isOpen, hoursCurrent }) {
    const url = new URL(`/api/public/merchant/${id}/logo`, API_URI).toString();

    // onDragStart: disable draggable links on iPad, see #89
    return (
        <div className={`merchant-logo ${isOpen ? 'open' : 'closed'}`}>
            <img src={url} alt="Logo" draggable="false" />
            <div className="closed-text">
                <Typography variant="subtitle1">
                    {
                        hoursCurrent
                            ? <FormattedMessage {...messages.closedTemporarily} />
                            : <FormattedMessage {...messages.closed} />
                    }
                </Typography>
            </div>
        </div>
    );
}

MerchantLogo.propTypes = {
    id: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    hoursCurrent: PropTypes.object,
};

export default MerchantLogo;
