import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
    loadOrderQueue as loadOrderQueueAction,
    clearPushNotifications as clearPushNotificationsAction,
} from 'containers/App/actions';
import { loadOrders as loadOrdersAction } from 'containers/Orders/actions';
import { loadOrderDetails as loadOrderDetailsAction } from 'containers/OrderDetails/actions';
import { showSnackbar as showSnackbarAction } from 'containers/Snackbar/actions';
import messages from './messages';

const NOTIFICATION_MESSAGES = {
    NEXT: messages.next,
    IN_PREPARATION: messages.inPreperation,
    PREPARED: messages.prepared,
    CANCELED: messages.canceled,
};

const WHITELISTED_PUSH_TYPES = [
    'ORDER_NEXT',
    'ORDER_IN_PREPARATION',
    'ORDER_PREPARED',
    'ORDER_CANCELED',
];

class PushReceiver extends React.Component {

    handleNotification(pushNotification) {
        const {
            location,
            clearPushNotifications,
            loadOrderQueue,
            loadOrders,
            loadOrderDetails,
        } = this.props;

        const { holsAppPushType } = pushNotification;

        if (WHITELISTED_PUSH_TYPES.indexOf(holsAppPushType) !== -1) {
            // tap:true the user has tapped on the push message
            const {
                holsAppOrderId: orderId,
                holsAppOrderState: orderState,
                tap,
            } = pushNotification;

            // show snackbar message
            if (orderState in NOTIFICATION_MESSAGES) {
                this.props.showSnackbar(NOTIFICATION_MESSAGES[orderState]);
            }

            if (tap === true) {
                // the application was not in foreground (background or coldstart), so open the order details page
                this.props.dispatch(push(`/orders/${orderId}`));
            } else {
                loadOrderQueue();
                if (location.pathname === '/orders') {
                    loadOrders();
                } else if (location.pathname.startsWith('/orders/')) {
                    loadOrderDetails(orderId);
                }
            }
        }

        // once handled, clear push notification from Redux store
        clearPushNotifications();
    }

    render() {
        const { loggedIn, pushNotification } = this.props;
        if (loggedIn && pushNotification) {
            this.handleNotification(pushNotification);
        }

        return null;
    }

}

PushReceiver.propTypes = {
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    pushNotification: PropTypes.object,
    showSnackbar: PropTypes.func,
    clearPushNotifications: PropTypes.func,
    loadOrderQueue: PropTypes.func,
    loadOrders: PropTypes.func,
    loadOrderDetails: PropTypes.func,
    dispatch: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        location: state.router.location,
        loggedIn: state.loginReducer.loggedIn,
        pushNotification: state.global.pushNotification,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        showSnackbar: title => dispatch(showSnackbarAction(title, false)),
        clearPushNotifications: () => dispatch(clearPushNotificationsAction()),
        loadOrderQueue: () => dispatch(loadOrderQueueAction()),
        loadOrders: () => dispatch(loadOrdersAction()),
        loadOrderDetails: id => dispatch(loadOrderDetailsAction(id)),
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PushReceiver);
