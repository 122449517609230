import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'Registration.title',
        defaultMessage: 'Registration',
    },
    acceptTermsOfService: {
        id: 'Registration.acceptTermsOfService',
        defaultMessage: 'I accept the {termsOfService}',
    },
    termsOfServiceRequired: {
        id: 'Registration.termsOfServiceRequired',
        defaultMessage: 'You must accept the general terms of service and privacy policy',
    },
    register: {
        id: 'Registration.register',
        defaultMessage: 'Register',
        description: 'Label for the register button',
    },
    registerError: {
        id: 'Registration.registerError',
        defaultMessage: 'Error during registration',
    },
    registerPhoneNumberInUse: {
        id: 'Registration.registerPhoneNumberInUse',
        defaultMessage: 'Phone number is already registered',
    },
});

export default messages;
