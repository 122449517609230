import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import messages from './messages';

function FormDialog({
    onClose,
    onCancelClick,
    onConfirmClick,
    titleMessage,
    contentMessage,
    contentForm,
    confirmMessage,
    open,
}) {
    const platformId = window.cordova ? window.cordova.platformId : '';

    let contentText = <div />;
    if (contentMessage) {
        contentText = (
            <DialogContentText>
                <FormattedMessage {...contentMessage} />
            </DialogContentText>
        );
    }

    return (
        <Dialog open={open} onClose={onClose} className={platformId} fullWidth>
            <DialogTitle>
                <FormattedMessage {...titleMessage} />
            </DialogTitle>
            <DialogContent>
                {contentText}
                {contentForm}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancelClick} color="default" data-testid="form-dialog-cancel">
                    <FormattedMessage {...messages.cancel} />
                </Button>
                <Button onClick={onConfirmClick} color="primary" data-testid="form-dialog-confirm">
                    <FormattedMessage {...confirmMessage} />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

FormDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCancelClick: PropTypes.func.isRequired,
    onConfirmClick: PropTypes.func.isRequired,
    titleMessage: PropTypes.object.isRequired,
    contentMessage: PropTypes.object,
    contentForm: PropTypes.object.isRequired,
    confirmMessage: PropTypes.object.isRequired,
    open: PropTypes.bool,
};

export default FormDialog;
