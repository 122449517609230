import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';

import './styles.scss';

function OrderQueue({ orderQueue, id }) {
    let position = null;
    let displayedPosition = null;

    if (orderQueue && orderQueue[id] && orderQueue[id].position) {
        ({ position } = orderQueue[id]);
        displayedPosition = orderQueue[id].position - 1;
    }

    return (
        <div className={`order-queue
            ${position !== null && position === 1 ? 'nextInLine' : ''}`}
        >
            <Typography variant="body2" className="light-text">
                {displayedPosition !== null ? displayedPosition : '-'}
            </Typography>
        </div>
    );
}

OrderQueue.propTypes = {
    orderQueue: PropTypes.object,
    id: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
    return {
        orderQueue: state.global.orderQueue,
    };
}

export default connect(mapStateToProps)(OrderQueue);
