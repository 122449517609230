import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import _ from 'lodash';

import Geolocation from 'services/Geolocation';

import HoverCart from 'components/HoverCart';
import BoxLink from 'components/BoxLink';
import MerchantBox from 'components/MerchantBox';

import MenuBar from 'containers/MenuBar';
import Scroll from 'containers/Scroll';

import { loadMerchants as loadMerchantsAction } from 'containers/App/actions';
import messages from 'containers/App/messages';
import { isOpen } from '../../utils';

import './styles.scss';

class Merchants extends React.Component { // eslint-disable-line

    componentDidMount() {
        const {
            merchants,
            merchantsLoading,
            merchantsCount,
        } = this.props;

        // If you start the app on the products or merchant details page
        // only the current merchant is fetched.
        if ((!merchants || Object.keys(merchants).length <= 1 || Object.keys(merchants).length !== merchantsCount)
            && !merchantsLoading) {
            this.loadData();
        }

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentDidUpdate(prevProps) {
        const {
            userCoordinatesUpdated,
        } = this.props;
        const {
            userCoordinatesUpdated: prevUserCoordinatesUpdated,
        } = prevProps;

        if (userCoordinatesUpdated !== prevUserCoordinatesUpdated) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        const {
            loadMerchants,
            loggedIn,
            userCoordinates,
        } = this.props;

        loadMerchants(
            loggedIn,
            userCoordinates,
        );
    }

    handleRefresh(e) {
        Geolocation.getCurrentPosition(this.props.dispatch);
        e.preventDefault();
    }

    renderMerchantBoxes() {
        const {
            merchants,
            userCoordinates,
        } = this.props;

        const [openMerchants, closedMerchants] = _.partition(Object.values(merchants), merchant => isOpen(merchant));

        return [
            ...openMerchants.map(merchant => this.renderMerchantBox(merchant, userCoordinates)),
            (
                closedMerchants.length > 0 &&
                <Typography
                    variant="subtitle1"
                    className="closed-seperator"
                    key="closed-seperator"
                >
                    <FormattedMessage {...messages.closedCurrently} />
                </Typography>
            ),
            ...closedMerchants.map(merchant => this.renderMerchantBox(merchant, userCoordinates)),
        ];
    }

    renderMerchantBox(merchant, userCoordinates) {
        return (
            merchant && merchant._id &&
            // "will-change" required for Safari (#281)
            <Fade in key={merchant._id} style={{ willChange: 'opacity' }}>
                <div>
                    <BoxLink to={`/products/${merchant._id}`}>
                        <MerchantBox
                            key={merchant._id}
                            merchant={merchant}
                            userCoordinates={userCoordinates}
                        />
                    </BoxLink>
                </div>
            </Fade>
        );
    }

    render() {
        const {
            merchants,
            hasShoppingCart,
        } = this.props;

        let content;

        if (!merchants) {
            content = <div />;
        } else {
            content = this.renderMerchantBoxes();
        }

        return (
            <div className={`merchants-page ${hasShoppingCart ? 'has-shopping-cart' : ''}`}>
                <MenuBar
                    titleMessage={messages.restaurants}
                    onRefreshClick={e => this.handleRefresh(e)}
                />
                <Scroll sid="merchants" className="page-content">
                    {content}
                </Scroll>
                <HoverCart />
            </div>
        );
    }

}

Merchants.propTypes = {
    dispatch: PropTypes.func.isRequired,
    merchants: PropTypes.object,
    merchantsLoading: PropTypes.bool.isRequired,
    merchantsCount: PropTypes.number,
    customer: PropTypes.object,
    loadMerchants: PropTypes.func, // eslint-disable-line
    hasShoppingCart: PropTypes.bool,
    loggedIn: PropTypes.bool, // eslint-disable-line
    userCoordinates: PropTypes.object,
    userCoordinatesUpdated: PropTypes.instanceOf(Date),
};

function mapStateToProps(state) {
    return {
        userCoordinates: state.global.coordinates,
        userCoordinatesUpdated: state.global.coordinatesUpdated,
        merchants: state.global.merchants,
        merchantsLoading: state.global.merchantsLoading,
        merchantsCount: state.global.merchantsCount,
        customer: state.global.customer,
        hasShoppingCart: state.shoppingCart.hover,
        loggedIn: state.loginReducer.loggedIn,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatch,
        loadMerchants: (loggedIn, coords) => dispatch(loadMerchantsAction(loggedIn, coords)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Merchants);
