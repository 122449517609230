import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@material-ui/core';

import HoverCart from 'components/HoverCart';
import { showSnackbar } from 'containers/Snackbar/actions';
import {
    setProduct,
    clearCart, setPaymentType as setPaymentTypeAction,
} from 'containers/ShoppingCart/actions';
import MenuBar from 'containers/MenuBar';

import MerchantLogo from 'components/MerchantLogo';
import MerchantQueue from 'containers/MerchantQueue';
import CategoryTabContainer from 'components/CategoryTabContainer';
import ConfirmDialog from 'components/ConfirmDialog';

import globalMessages from 'containers/App/messages';
import Storage from '../../services/Storage';

import {
    isOpen,
    getCurrentOpeningHoursForToday,
    getNextOpeningHoursForToday,
} from '../../utils';
import { PAYMENT_TYPE } from '../ShoppingCart/constants';
import { loadMerchantDetails as loadMerchantDetailsAction } from './actions';
import messages from './messages';
import './styles.scss';

class Products extends React.Component { // eslint-disable-line

    constructor(props) {
        super(props);

        const tab = (props.location.state && props.location.state.tab) || 0;
        this.state = {
            selectedTab: tab,
            confirmSwitchCart: false,
            pendingProduct: null,
        };

        this.handleChangeTab = this.handleChangeTab.bind(this);

        this.resume = this.loadData.bind(this);
        document.addEventListener('resume', this.resume);
    }

    componentDidMount() {
        const from = this.props.location.state && this.props.location.state.from;

        if (!from || !from.pathname.includes('/merchants/') || !from.pathname.includes('/product-options/')) {
            this.loadData();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('resume', this.resume);
    }

    loadData() {
        this.props.loadMerchantDetails(this.props.match.params.id);
    }

    hasOptions(product, productOptions) {
        return product.optionGroups
            && product.optionGroups.length > 0
            && productOptions
            && productOptions.findIndex(
                po => product.optionGroups.find(optionGroupId => optionGroupId === po.optionGroup)
            ) !== -1;
    }

    openProductOptions(product) {
        const { match } = this.props;
        const { id: merchantId } = match.params;

        const location = {
            pathname: `/product-options/${merchantId}/${product._id}`,
            state: {
                from: {
                    ...this.props.location,
                    state: { tab: this.state.selectedTab },
                },
            },
        };
        this.props.dispatch(push(location));
    }

    addProductToCart(product, first = false) {
        const {
            match,
            merchants,
            totalCount,
            productOptions,
        } = this.props;
        const { id: merchantId } = match.params;
        const merchant = merchants[merchantId];
        const max = merchant.maxShoppingCartProducts || Infinity;

        // first product added, set payment type to Stripe if merchant has enabled it
        // and user has preferred online payment
        if (first) {
            if (merchant.paymentTypesEnabled.length === 1) {
                this.props.setPaymentType(merchant.paymentTypesEnabled[0]);
            } else {
                const paymentTypeStripeEnabled = merchant.paymentTypesEnabled.includes(PAYMENT_TYPE.stripe);
                const paymentType = paymentTypeStripeEnabled && Storage.getPreferPaymentTypeStripe()
                    ? PAYMENT_TYPE.stripe
                    : PAYMENT_TYPE.onSite;
                this.props.setPaymentType(paymentType);
            }
        }

        if (!(totalCount >= max)) {
            if (this.hasOptions(product, productOptions)) {
                this.openProductOptions(product);
            } else {
                this.props.setProduct({
                    merchantId,
                    product,
                    quantity: 1,
                    max,
                });
            }
        } else {
            this.props.showSnackbar({
                ...globalMessages.tooManyProducts,
                values: { max },
            });
        }
    }

    handleBackClick() {
        this.props.dispatch(push('/merchants'));
    }

    handleInfoClick(merchantId) {
        const location = {
            pathname: `/merchants/${merchantId}`,
            state: {
                from: {
                    ...this.props.location,
                    state: { tab: this.state.selectedTab },
                },
            },
        };
        this.props.dispatch(push(location));
    }

    handleClickAddProduct(product) {
        const { match, cartMerchantId } = this.props;
        const { id: merchantId, totalCount = 0 } = match.params;

        if (cartMerchantId && cartMerchantId !== merchantId) {
            this.setState({
                confirmSwitchCart: true,
                pendingProduct: product,
            });
            return;
        }

        this.addProductToCart(product, !totalCount);
    }

    handleChangeTab(tabId) {
        this.setState({ selectedTab: tabId });
    }

    handleSwitchCartClick() {
        this.props.dispatch(clearCart());
        this.addProductToCart(this.state.pendingProduct, true);
        this.setState({
            confirmSwitchCart: false,
            pendingProduct: null,
        });
    }

    render() {
        const {
            match,
            categories,
            products,
            productOptions,
            merchants,
            hasShoppingCart,
            locale,
        } = this.props;
        const { confirmSwitchCart } = this.state;
        const { id: merchantId } = match.params;

        let content;
        let merchantName = '';

        if (!merchants || !products || !categories) {
            content = <div />;
        } else {
            const merchant = merchants[merchantId];
            const hoursCurrent = getCurrentOpeningHoursForToday(merchant.openingHours);
            const hoursNext = getNextOpeningHoursForToday(merchant.openingHours);
            const open = isOpen(merchant);
            merchantName = merchant.name;

            content = (
                <div>
                    {/* merchant is open */}
                    {open &&
                        <div className="merchant-queue-text">
                            <div>
                                <Typography variant="body2">
                                    <FormattedMessage {...globalMessages.ordersQueue} />:
                                </Typography>
                            </div>
                            <div className="merchant-queue-text-addon">
                                <MerchantQueue id={merchantId} />
                            </div>
                        </div>
                    }
                    {/* merchant has been closed manually */}
                    {!open && !hoursCurrent && hoursNext &&
                        <div className="merchant-queue-text">
                            <div>
                                <Typography variant="body2">
                                    <FormattedMessage {...globalMessages.closedTemporarily} />
                                </Typography>
                            </div>
                            <div className="merchant-queue-text-addon">
                                <Typography>
                                    <FormattedMessage {...globalMessages.opensAt} /> {hoursNext.from}
                                </Typography>
                            </div>
                        </div>
                    }
                    {/* merchant is closed and has no opening hours today */}
                    {!open && !hoursCurrent && !hoursNext &&
                        <div className="merchant-queue-text">
                            <Typography variant="body2">
                                <FormattedMessage {...globalMessages.closedToday} />
                            </Typography>
                        </div>
                    }
                    <MerchantLogo id={merchant._id} isOpen={open} hoursCurrent={hoursCurrent} />
                    <div>
                        <CategoryTabContainer
                            className="category-tab-container"
                            categories={categories}
                            products={products}
                            open={open}
                            onClick={product => this.handleClickAddProduct(product)}
                            selectedTab={this.state.selectedTab}
                            onChangeTab={this.handleChangeTab}
                            locale={locale}
                            hasOptions={product => this.hasOptions(product, productOptions)}
                        />
                    </div>
                </div>
            );
        }

        return (
            <div className={`products-page ${hasShoppingCart ? 'has-shopping-cart' : ''}`}>
                <MenuBar
                    title={merchantName}
                    onBackClick={() => this.handleBackClick()}
                    onInfoClick={() => this.handleInfoClick(merchantId)}
                />

                <ConfirmDialog
                    onClose={() => this.setState({ confirmSwitchCart: false, pendingProduct: null })}
                    open={confirmSwitchCart}
                    titleMessage={messages.confirmSwitchCartTitle}
                    contentMessage={messages.confirmSwitchCartContent}
                    confirmMessage={messages.switchCart}
                    onCancelClick={() => this.setState({ confirmSwitchCart: false, pendingProduct: null })}
                    onConfirmClick={() => this.handleSwitchCartClick()}
                />

                <div className="page-content">
                    {content}
                </div>
                <HoverCart />
            </div>
        );
    }

}

Products.propTypes = {
    location: PropTypes.object.isRequired,
    categories: PropTypes.array,
    products: PropTypes.array,
    merchants: PropTypes.object,
    productOptions: PropTypes.array,
    loadMerchantDetails: PropTypes.func,
    showSnackbar: PropTypes.func,
    match: PropTypes.object,
    setProduct: PropTypes.func,
    cartMerchantId: PropTypes.string,
    hasShoppingCart: PropTypes.bool,
    dispatch: PropTypes.func,
    totalCount: PropTypes.number,
    locale: PropTypes.string.isRequired,
    setPaymentType: PropTypes.func,
};

function mapStateToProps(state) {
    return {
        categories: state.productsReducer.categories,
        products: state.productsReducer.products,
        productOptionGroups: state.productsReducer.productOptionGroups,
        productOptions: state.productsReducer.productOptions,
        cartMerchantId: state.shoppingCart.merchantId,
        merchants: state.global.merchants,
        hasShoppingCart: state.shoppingCart.hover,
        totalCount: state.shoppingCart.totalCount,
        locale: state.languageProviderReducer.locale,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadMerchantDetails: id => dispatch(loadMerchantDetailsAction(id)),
        showSnackbar: msg => dispatch(showSnackbar(msg)),
        setProduct: ({
            merchantId,
            product,
            quantity,
            max,
        }) => dispatch(setProduct({
            merchantId,
            product,
            quantity,
            max,
        })),
        setPaymentType: type => dispatch(setPaymentTypeAction(type)),
        dispatch,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
