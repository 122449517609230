/**
 * @author RISE Ges.m.b.H.
 *  ____   ___  ____   _____
 * |  _ \  | | / ___| | ____|
 * | |_) | | | \___ \ |  _|
 * |  _ <  | |  ___) || |___
 * |_| \_\ |_| |____/ |_____|
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import InputFirstName from '../InputFirstName';
import InputLastName from '../InputLastName';
import InputPhoneNumber from '../InputPhoneNumber';
import InputPassword from '../InputPassword';

class RegistrationForm extends React.PureComponent {

    validate() {
        const firstNameValid = this.inputFirstName.validate();
        const lastNameValid = this.inputLastName.validate();
        const phoneNumberValid = this.inputPhoneNumber.validate();
        const passwordValid = this.inputPassword.validate();
        return firstNameValid && lastNameValid && phoneNumberValid && passwordValid;
    }

    render() {
        return (
            <form noValidate autoComplete="off">
                <InputFirstName
                    ref={input => { this.inputFirstName = input; }}
                    firstName={this.props.firstName}
                    onChange={val => this.props.onChangeFirstName(val)}
                />
                <InputLastName
                    ref={input => { this.inputLastName = input; }}
                    lastName={this.props.lastName}
                    onChange={val => this.props.onChangeLastName(val)}
                />
                <InputPhoneNumber
                    ref={input => { this.inputPhoneNumber = input; }}
                    phoneNumber={this.props.phoneNumber}
                    onChange={val => this.props.onChangePhoneNumber(val)}
                />
                <InputPassword
                    ref={input => { this.inputPassword = input; }}
                    autoComplete="new-password"
                    onChange={val => this.props.onChangePassword(val)}
                />
            </form>
        );
    }

}

RegistrationForm.propTypes = {
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    // initial phone number value
    phoneNumber: PropTypes.string.isRequired,
    // triggered on change
    onChangeFirstName: PropTypes.func.isRequired,
    onChangeLastName: PropTypes.func.isRequired,
    onChangePhoneNumber: PropTypes.func.isRequired,
    onChangePassword: PropTypes.func.isRequired,
};

export default RegistrationForm;
