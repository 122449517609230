/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

function OwnPackagingIcon({
    size = '24px',
    className,
    style,
    ...rest
}) {
    return (
        <svg
            className={`app-icon ${className}`}
            style={{
                stroke: 'currentColor',
                fontSize: size,
                ...style,
            }}
            {...rest}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 54.34 45.339"
            enableBackground="new 0 0 54.34 45.339"
            xmlSpace="preserve"
        >
            <g>
                <path
                    fill="none"
                    strokeMiterlimit="10"
                    d="M2.987,14.513c-0.575,1.438-0.353,2.817-0.353,2.817
                    s0.908,9.695,1.083,11.934c0.175,2.24,0.294,4.763,1.878,5.488l18.403,7.768c2.168,1.062,4.043,1.122,6.332,0l18.403-7.768
                    c1.584-0.726,1.703-3.248,1.878-5.488c0.176-2.238,1.084-11.934,1.084-11.934c0.047-0.606,0.104-1.995-0.095-2.76"
                />
                <path
                    fill="none"
                    strokeWidth="4"
                    d="M2.987,14.513c-0.575,1.438-0.353,2.817-0.353,2.817
                    s0.908,9.695,1.083,11.934c0.175,2.24,0.294,4.763,1.878,5.488l18.403,7.768c2.168,1.062,4.043,1.122,6.332,0l18.403-7.768
                    c1.584-0.726,1.703-3.248,1.878-5.488c0.176-2.238,1.084-11.934,1.084-11.934c0.047-0.606,0.104-1.995-0.095-2.76"
                />
                <path
                    fill="none"
                    strokeMiterlimit="10"
                    d="M52.108,8.687c-0.267-0.075-21.433-6.022-21.433-6.022
                    c-0.959-0.312-2.229-0.606-3.529-0.652l0.046-0.013C27.153,2,27.117,2.006,27.078,2.008C27.039,2.006,27.002,2,26.963,1.999
                    l0.045,0.013c-1.301,0.046-2.569,0.34-3.528,0.652c0,0-21.054,5.965-21.284,6.034C1.964,8.77,1.935,8.994,2.126,9.069
                    c0.071,0.027,21.357,6.499,21.357,6.499c0.958,0.339,2.226,0.657,3.524,0.707l-0.045,0.013c0.039-0.001,0.076-0.007,0.115-0.008
                    c0.037,0.001,0.075,0.007,0.114,0.008l-0.046-0.013c1.299-0.05,2.566-0.368,3.525-0.707c0,0,2.149-0.68,4.357-1.356
                    c-0.023,1.629-0.055,5.424-0.056,5.611c-0.002,0.294,0.306,0.562,0.668,0.442c0.31-0.102,8.019-3.033,8.401-3.17
                    c0.383-0.138,0.561-0.452,0.574-0.725c0.01-0.162,0.465-3.712,0.533-5.241c3.871-1.187,7.021-2.15,7.085-2.164
                    C52.401,8.928,52.375,8.762,52.108,8.687"
                />
                <path
                    fill="none"
                    strokeWidth="4"
                    strokeLinecap="round"
                    d="M52.108,8.687
                    c-0.267-0.075-21.433-6.022-21.433-6.022c-0.959-0.312-2.229-0.606-3.529-0.652l0.046-0.013C27.153,2,27.117,2.006,27.078,2.008
                    C27.039,2.006,27.002,2,26.963,1.999l0.045,0.013c-1.301,0.046-2.569,0.34-3.528,0.652c0,0-21.054,5.965-21.284,6.034
                    C1.964,8.77,1.935,8.994,2.126,9.069c0.071,0.027,21.357,6.499,21.357,6.499c0.958,0.339,2.226,0.657,3.524,0.707l-0.045,0.013
                    c0.039-0.001,0.076-0.007,0.115-0.008c0.037,0.001,0.075,0.007,0.114,0.008l-0.046-0.013c1.299-0.05,2.566-0.368,3.525-0.707
                    c0,0,2.149-0.68,4.357-1.356c-0.023,1.629-0.055,5.424-0.056,5.611c-0.002,0.294,0.306,0.562,0.668,0.442
                    c0.31-0.102,8.019-3.033,8.401-3.17c0.383-0.138,0.561-0.452,0.574-0.725c0.01-0.162,0.465-3.712,0.533-5.241
                    c3.871-1.187,7.021-2.15,7.085-2.164C52.401,8.928,52.375,8.762,52.108,8.687z"
                />
            </g>
            <line
                fill="none"
                strokeWidth="2"
                strokeLinecap="square"
                x1="27.004"
                y1="21.995"
                x2="27.004"
                y2="37.272"
            />
        </svg>
    );
}

OwnPackagingIcon.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default OwnPackagingIcon;
