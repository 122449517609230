import { defineMessages } from 'react-intl';

const messages = defineMessages({
    title: {
        id: 'Profile.title',
        defaultMessage: 'Profile',
    },
    save: {
        id: 'Profile.save',
        defaultMessage: 'Save',
    },
    promoCodes: {
        id: 'Profile.promoCodes',
        defaultMessage: 'Promo Codes',
    },
});

export default messages;
