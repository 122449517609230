import {
    requestLocationInfo,
    updateLocationInfo,
    unsetLocationInfo,
} from 'containers/App/actions';

const OPTIONS = {
    maximumAge: 60, // 1 minute

    // time to wait until an error is thrown
    timeout: __GEOLOCATION_TIMEOUT__, // eslint-disable-line no-undef

    // Android needs high accuracy
    enableHighAccuracy: true,
};

class Geolocation {

    constructor() {
        this.promise = new Promise((resolve => {
            this.resolve = resolve;
        }));
    }

    static getPlugin() {
        const { cordova } = window;
        if (cordova && navigator.geolocation) {
            return navigator.geolocation;
        } else {
            throw Error('Geolocation: Plugin not available');
        }
    }

    init() {
        document.addEventListener('deviceready', () => {
            this.resolve();
            console.log('Geolocation: Service initialized');
        });
    }

    getCurrentPosition(dispatch) {
        return this.promise.then(() => new Promise((resolve, reject) => {
            /**
             * MS Edge sometimes doesn't call the success nor the error callback.
             * We register a custom timeout function, and reject the promise if Edge is used.
             */
            const timeoutId = setTimeout(() => {
                if (navigator.userAgent.indexOf('Edge') !== -1) {
                    console.log('Geolocation.getCurrentPosition timed out');
                    reject(new Error('Geolocation.getCurrentPosition timed out'));
                }
            }, OPTIONS.timeout + 1);

            dispatch(requestLocationInfo());

            Geolocation.getPlugin().getCurrentPosition(
                position => {
                    clearTimeout(timeoutId);
                    dispatch(updateLocationInfo({
                        lng: position.coords.longitude,
                        lat: position.coords.latitude,
                    }));
                    resolve(position);
                },
                error => {
                    clearTimeout(timeoutId);
                    dispatch(unsetLocationInfo());
                    reject(error);
                },
                OPTIONS,
            );
        }));
    }

}

export default new Geolocation();
